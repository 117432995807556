import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import pipe from 'lodash/fp/pipe'

import sellerServices from '../../../data/services/sellerServices'
import userApiServices from '../../../data/services/userApiServices'
import normalizeSeller from './normalizeSeller'

const fechSellerDetail = createAsyncThunk(
  'sellers/detail',
  async ({ slug }, { signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const data = await sellerServices.sellerDetail({
      slug,
      signal,
    })
    const users = await userApiServices.getBySlugSeller({
      slugSeller: slug,
    })
    data['users'] = users

    const sellerNormaized = pipe(normalizeSeller)(data)

    return sellerNormaized
  }
)
export default fechSellerDetail
