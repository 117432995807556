import React from 'react'

import ListItemText from '@material-ui/core/ListItemText'
import MuiSelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/InputBase'

import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'

import styled from 'styled-components'
import { AdormentLeft } from '../Adorment'
import Text from '../Typography/Text'
import TextSecondary from '../Typography/TextSecondary'

export const SelectStyled = styled(MuiSelect)`
  height: 36px;
  border-top-right-radius: ${({ isallfilter }) =>
    isallfilter === 'true' ? '0px' : '5px'};
  border-bottom-right-radius: ${({ isallfilter }) =>
    isallfilter === 'true' ? '0px' : '5px'};
  border: 1px solid #b7b7b7;
  box-sizing: border-box;

  @media (max-width: 700px) {
    max-width: 100%;
  }
  .Mui-disabled {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  }
`

const InputSeller = styled(Input)``

const CheckboxStyled = styled(Checkbox)`
  width: 18px;
  height: 18px;
  padding-right: 20px;
  padding-top: 11px;
`

const SelectText = styled(Text)`
  font-weight: normal;
`

const SelectOld = ({
  label,
  list,
  value,
  isCheckbox,
  handleDelete,
  handleDeleteAll,
  isallfilter = true,
  ...props
}) => {
  return (
    <FormControl variant="outlined">
      <SelectStyled
        isallfilter={`${isallfilter}`}
        labelId="simple-select"
        id="simple-select"
        value={value}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
        input={
          <InputSeller
            id="standard-adornment-select"
            startAdornment={
              <AdormentLeft position="start">
                <SelectText>{label}</SelectText>
              </AdormentLeft>
            }
          />
        }
        {...props}
      >
        {list?.map(name => (
          <MenuItem
            key={name}
            value={name}
            onClick={() => handleDelete(name)}
          >
            {isCheckbox ? (
              <CheckboxStyled checked={value?.includes(name)} />
            ) : null}
            <ListItemText primary={name} />
          </MenuItem>
        ))}
        {isCheckbox ? (
          <MenuItem key="borrar" onClick={() => handleDeleteAll(value)}>
            <TextSecondary>Borrar selección</TextSecondary>
          </MenuItem>
        ) : null}
      </SelectStyled>
    </FormControl>
  )
}

export default SelectOld
