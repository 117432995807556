import styled from 'styled-components'

const CenterView = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export default CenterView
