import { Button } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

const Container = styled('div')`
  max-height: ${props => (props.expanded ? 'auto' : props.height)};
  width: 100%;
  overflow: hidden;
`
const StyledButton = styled(Button)`
  color: #2469ce;
`
const Footer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Spoiler = ({ initialHeight, children, showButton }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <Container height={initialHeight} expanded={expanded}>
        {children}
      </Container>
      {showButton && (
        <Footer>
          <StyledButton onClick={toggleExpand}>
            {expanded ? '- Ver menos' : '+ Ver más'}
          </StyledButton>
        </Footer>
      )}
    </>
  )
}

export default Spoiler
