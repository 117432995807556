import { addSellers, removeAllSeller } from '../sellers/getSellers'
import { removeAllAccountingStatement } from './accountingStatementSlice'
import { removeAllStatus } from './dispatchedSlice'
import { resetRangeOfDays } from './rangeOfDaysSlice'

const resetFilters = () => dispatch => {
  dispatch(addSellers([]))
  dispatch(removeAllSeller())
  dispatch(removeAllAccountingStatement())
  dispatch(removeAllStatus())
  dispatch(resetRangeOfDays())
}
export default resetFilters
