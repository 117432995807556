import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import styled from 'styled-components'
import ClipboardCheckIcon from '@material-ui/icons/AssignmentTurnedIn' // Icono de guardado en el portapapeles

const StyledSnackbar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    display: inline-flex;
    align-items: center;
    padding: 10px;
    text-align: center;
  }
`

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.secondary};
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.palette.warning.light || '#65B8D6'};
      color: ${({ theme }) => theme.palette.warning.light || '#65B8D6'};
    }
  }
`

/**
 * ButtonCopy component to copy text to clipboard and show a snackbar on success.
 * @param {Object} props - The component props.
 * @param {Function} props.getText - Function that returns the text to be copied.
 * @param {string} props.title - Tooltip title.
 * @param {string} props.message - Snackbar message on copy success.
 * @returns {JSX.Element} The rendered ButtonCopy component.
 */
const ButtonCopy = ({
  getText = x => String(x),
  title = 'Copiar',
  message = 'Copiado!',
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleCopy = () => {
    setOpenSnackbar(true)
  }

  const handleCloseSnackbar = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  return (
    <>
      <CopyToClipboard text={getText()} onCopy={handleCopy}>
        <Tooltip title={title}>
          <StyledIconButton>
            <FileCopyOutlinedIcon />
          </StyledIconButton>
        </Tooltip>
      </CopyToClipboard>
      <StyledSnackbar
        open={openSnackbar}
        autoHideDuration={1500}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <SnackbarContent
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <ClipboardCheckIcon style={{ marginRight: '8px' }} />
              {message || 'Copiado!'}
            </span>
          }
        />
      </StyledSnackbar>
    </>
  )
}

ButtonCopy.propTypes = {
  getText: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
}

export default ButtonCopy
