import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import fetchUpdateProductStatus from '../../../../domain/features/productSearchApi/fetchUpdateProductStatus'
import fetchDeleteProduct from '../../../../domain/features/productSearchApi/fetchProductDelete'

const IconMenuButton = styled(IconButton)`
  padding: 5px;
  margin: 0px;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`
const MenuStatus = styled(MenuItem)`
  .Mui-disabled && {
    cursor: not-allowed;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

export default function ActionButton({ productId, status, role }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(null)
  }

  const handleChangeStatus = React.useCallback(
    event => {
      event.stopPropagation()
      event.preventDefault()
      dispatch(
        fetchUpdateProductStatus({
          id: productId,
          status: new Map([
            ['ACTIVE', 'DRAFT'],
            ['DRAFT', 'ACTIVE'],
            ['ARCHIVE', 'DRAFT'],
          ]).get(status),
        })
      )
      setAnchorEl(null)
    },
    [dispatch, setAnchorEl, productId, status]
  )

  const handleDeleteProduct = React.useCallback(
    event => {
      event.stopPropagation()
      event.preventDefault()
      dispatch(
        fetchDeleteProduct({
          id: productId,
        })
      )
      setAnchorEl(null)
    },
    [dispatch, setAnchorEl, productId]
  )

  const open = Boolean(anchorEl)
  const options = React.useMemo(
    () =>
      new Map([
        [
          'ACTIVE',
          <MenuItem
            key="ACTIVE"
            onClick={handleChangeStatus}
            disabled={role === 'viewer'}
          >
            Pasar a borrador
          </MenuItem>,
        ],
        [
          'DRAFT',
          <MenuStatus
            key="DRAFT"
            onClick={handleChangeStatus}
            disabled={role === 'viewer' || role === 'operations'}
          >
            Aprobar
          </MenuStatus>,
        ],
        [
          'ARCHIVE',
          <MenuItem
            key="ARCHIVE"
            onClick={handleChangeStatus}
            disabled={role === 'viewer'}
          >
            Pasar a borrador
          </MenuItem>,
        ],
      ]).get(status),
    [handleChangeStatus, role, status]
  )

  return (
    <>
      <IconMenuButton
        aria-haspopup="true"
        aria-label="more"
        aria-controls="long-menu"
        variant="contained"
        onClick={handleClick}
        disabled={role === 'viewer'}
      >
        <MoreHorizIcon disabled={role === 'viewer'} />
      </IconMenuButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        disabled={role === 'viewer'}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <>
          {options || null}
          <MenuStatus
            key="delete"
            onClick={handleDeleteProduct}
            disabled={role === 'viewer'}
          >
            Eliminar
          </MenuStatus>
        </>
      </Menu>
    </>
  )
}

ActionButton.propTypes = {
  productId: PropTypes.string,
  status: PropTypes.string,
  role: PropTypes.string,
}
ActionButton.defaultProps = {
  role: 'viewer',
}
