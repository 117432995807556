import {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react'
import isEqual from 'lodash/isEqual'
import { auth } from './firebaseConfig'
import AuthValid from '../../../auth/AuthValid'
import { normalizeAuth } from '../../../../data/firebase/normalizeUser'
import persistentStore from '../../../../data/persistentStore'

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const updateCurrentUser = useCallback(
    newUser => {
      const userNewAuth = normalizeAuth(newUser)

      if (newUser && !isEqual(userNewAuth, currentUser)) {
        persistentStore.setUser(userNewAuth)
        setCurrentUser(userNewAuth)
      }
    },
    [currentUser]
  )

  useEffect(() => {
    const storedUser = persistentStore.getUser()
    if (storedUser) {
      updateCurrentUser(storedUser)
    }

    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        const tokenResult = await user.getIdTokenResult()

        persistentStore.setToken(tokenResult?.token)

        updateCurrentUser({
          uid: user.uid,
          photoURL: user.photoURL,
          ...tokenResult.claims,
        })
      }

      setLoading(false)
    })

    return unsubscribe
  }, [updateCurrentUser])

  const memoizedValue = useMemo(
    () => ({
      currentUser,
    }),
    [currentUser]
  )

  return (
    <AuthContext.Provider value={memoizedValue}>
      {!loading ? children : <AuthValid />}
    </AuthContext.Provider>
  )
}
