import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const accountingStatementAdapter = createEntityAdapter({
  selectId: accountingStatement => accountingStatement,
  sortComparer: (a, b) => String(a).localeCompare(String(b)),
})

export const accountingStatementSlice = createSlice({
  name: 'accountingStatement',
  initialState: accountingStatementAdapter.getInitialState(),
  reducers: {
    addAllAccountingStatement: accountingStatementAdapter.setAll,
    addOneAccountingStatement(state, { payload }) {
      if (payload?.includes(undefined)) {
        return
      }
      return accountingStatementAdapter.addMany(state, payload)
    },
    addAccountingStatement: accountingStatementAdapter.addOne,
    removeOneAccountingStatement: accountingStatementAdapter.removeOne,
    removeAllAccountingStatement: accountingStatementAdapter.removeAll,
  },
})

export const {
  addAllAccountingStatement,
  addOneAccountingStatement,
  removeOneAccountingStatement,
  addAccountingStatement,
  removeAllAccountingStatement,
} = accountingStatementSlice.actions

const getStateaccountingStatement = state => state.ui.accountingStatement

const accountingStatementelectors =
  accountingStatementAdapter.getSelectors(getStateaccountingStatement)
export const allAccountingStatement = accountingStatementelectors.selectAll

const reducer = accountingStatementSlice.reducer
export default reducer
