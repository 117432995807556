import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductUpdate from './fetchProductUpdate'
import fetchProductUpdateMedia from './fetchProductUpdateMedia'

export const productUpdateAdpater = createEntityAdapter()

export const productUpdateSlice = createSlice({
  name: 'product/update',
  initialState: productUpdateAdpater.getInitialState({
    status: 'idle',
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProductUpdate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchProductUpdate.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchProductUpdate.fulfilled, state => {
      state.status = 'finished'
      state.mediaStatus = 'idle'
    })
    builder.addCase(fetchProductUpdateMedia.pending, state => {
      state.mediaStatus = 'loading'
    })
    builder.addCase(fetchProductUpdateMedia.rejected, state => {
      state.mediaStatus = 'error'
    })
    builder.addCase(fetchProductUpdateMedia.fulfilled, state => {
      state.mediaStatus = 'finished'
    })
  },
})

const reducer = productUpdateSlice.reducer
export default reducer
