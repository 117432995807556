import fileDownload from 'js-file-download'
import request from '../../../data/client/request'

const downloadExcel = (endpoint, fileName) =>
  request.get(endpoint, { responseType: 'arraybuffer' }).then(response => {
    const excel = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    fileDownload(excel, `${fileName}.xlsx`)
  })

export default downloadExcel
