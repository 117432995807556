import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchCampaigns from './fetchCampaigns'

export const campaignsAdapter = createEntityAdapter({
  selectId: campaign => campaign,
})

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: campaignsAdapter.getInitialState({
    allCampaigns: [],
    pagination: {
      currentPagination: 0,
      perPagination: 10,
      paginationTotalCount: 0,
    },
    status: 'idle',
    ids: [],
    entities: {},
  }),
  extraReducers: builder => {
    builder.addCase(fetchCampaigns.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchCampaigns.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchCampaigns.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.allCampaigns = payload
    })
  },
})

const reducer = campaignSlice.reducer
export default reducer
