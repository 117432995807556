import React from 'react'
import PropTypes from 'prop-types'
import ModalMUi from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSpring, animated } from 'react-spring' // web.cjs is required for IE 11 support
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const ModalStyled = styled(ModalMUi)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseButton = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: -50px;
`

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

Fade.propTypes = {
  children: PropTypes.node,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

const Modal = React.forwardRef(
  ({ children, open, onClose, ...props }, ref) => {
    return (
      <ModalStyled
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        {...props}
      >
        <Fade in={open} ref={ref}>
          <CloseButton>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon style={{ color: '#5C5F62', fontSize: 25 }} />
            </IconButton>
          </CloseButton>
          {children}
        </Fade>
      </ModalStyled>
    )
  }
)

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Modal
