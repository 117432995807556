import dayjs from 'dayjs'

const tableDateFormat = date => {
  const days = dayjs(date).isValid()
    ? dayjs(date).format('DD/MM/YYYY - HH:mm')
    : 'No Aplica'
  return days
}

export default tableDateFormat
