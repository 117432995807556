import { useMemo } from 'react'
import { useEffect, useState } from 'react'

export const usePagination = (data, pageSize, currentPage) => {
  const numberOfPages = useMemo(
    () => (data ? Math.ceil(data.length / pageSize) : 0),
    [data, pageSize]
  )
  const [dataToDisplay, setDataToDisplay] = useState([])

  useEffect(() => {
    if (!data || data.error) {
      return
    }
    if (currentPage === 1) {
      setDataToDisplay(data.slice(0, pageSize))
    } else if (currentPage === numberOfPages) {
      setDataToDisplay(
        data.slice((currentPage - 1) * pageSize, data.length)
      )
    } else {
      setDataToDisplay(
        data.slice(pageSize * (currentPage - 1), pageSize * currentPage)
      )
    }
  }, [currentPage, data, numberOfPages, pageSize])

  return [dataToDisplay, numberOfPages]
}
