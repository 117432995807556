import sellerServices from '../../../../data/services/sellerServices'
import { createAsyncThunk } from '@reduxjs/toolkit'
import pipe from 'lodash/fp/pipe'

const fetchSellersUpdate = createAsyncThunk(
  'sellers/update',
  async (data, { rejectWithValue }) => {
    try {
      const sellers = await sellerServices.sellerUpdate(
        pipe(withOutSpace, transformCommissions, transformBrands)(data)
      )
      return sellers
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
const transformBrands = data => {
  if (data?.brands) {
    const newBrands = data?.brands.map(brand => {
      return { brandName: brand }
    })
    data.brands = newBrands
  }
  return data
}
const transformCommissions = data => {
  if (data.commissionSeller) {
    data.commissionSeller = data.commissionSeller / 100
  }
  if (data.commissionCategories) {
    data.commissionCategories = data.commissionCategories.map(
      commission => ({
        ...commission,
        commissionProductType: commission.commissionProductType / 100,
      })
    )
  }
  return data
}
const trimStartAndEnd = string => String(string).trimStart().trimEnd()
const withOutSpace = data => ({
  ...data,
  nameSeller: data?.nameSeller
    ? trimStartAndEnd(data?.nameSeller)
    : undefined,
  bussinesName: data?.bussinesName
    ? trimStartAndEnd(data?.bussinesName)
    : undefined,
  giro: data?.giro ? trimStartAndEnd(data?.giro) : undefined,
})
export default fetchSellersUpdate
