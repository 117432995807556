import PropTypes from 'prop-types'
import FieldCheckboxController from '../../molecules/FieldCheckboxController/FieldCheckboxController'
import styled from 'styled-components'

const WithdrawBox = styled('div')`
  font-size: 14px;
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  padding-top: 1rem;
`
const WithdrawItem = ({ control, name, defaultValue }) => {
  return (
    <>
      <WithdrawBox>Retiro de productos</WithdrawBox>
      <FieldCheckboxController
        name={name}
        label="¿Se debe realizar retiro de productos en domicilio del cliente?"
        control={control}
        defaultValue={defaultValue}
      />
    </>
  )
}

WithdrawItem.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.bool,
}

export default WithdrawItem
