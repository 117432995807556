import CellBase from './CellBase'
import styled from 'styled-components'

const CellHeader = styled(CellBase)`
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#313234' : '#ffffff'};
  font-weight: bold;
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#F6F6F7' : '#202223'};
`
CellHeader.displayName = 'CellHeader'
export default CellHeader
