import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import upload from '../../../assets/icon/upload.svg'

const Label = styled('label')`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  &:hover {
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    border-bottom-color: #212121;
  }
`

const StyleButtonFile = styled(Button)`
  justify-content: flex-start;
  height: 35px;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: right;
  .MuiButtonBase-root {
    padding-left: 0;
  }
`

const ButtonFile = ({ title, onChange, name, register, ...props }) => {
  const file = register(name, { required: false, value: null })
  return (
    <Label>
      <input hidden type="file" {...props} onChange={onChange} {...file} />
      <StyleButtonFile
        variant="text"
        component="span"
        startIcon={
          <img src={upload} alt="upload" width="14px" height="14px" />
        }
      >
        {title}
      </StyleButtonFile>
    </Label>
  )
}
ButtonFile.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  register: PropTypes.any,
}

export default ButtonFile
