import React, { useMemo } from 'react'

import {
  createTheme,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Provider as ProviderRedux } from 'react-redux'

import store from './domain/store'
import {
  ProviderDarkMode,
  useStoreTheme,
} from './presentation/styleSheet/DarkMode'
import Routers from './presentation/routers'
import getTheme from './presentation/styleSheet/theme'
import { ProvideWorker } from './presentation/hooks/useWorker'
import useServiceWorker from './presentation/hooks/useServiceWorker'
import { AuthProvider } from './presentation/components/pages/auth/useAuth'

import { auth } from './presentation/components/pages/auth/firebaseConfig'
import persistentStore from './data/persistentStore'

const App = () => {
  React.useEffect(() => {
    const unsubcribe = auth.onIdTokenChanged(
      token => token && persistentStore.setToken(token.accessToken)
    )
    return unsubcribe
  }, [])

  const worker = useServiceWorker()
  const [modeTheme, setModeTheme] = useStoreTheme()
  const value = useMemo(
    () => [modeTheme, setModeTheme],
    [modeTheme, setModeTheme]
  )

  const theme = useMemo(
    () => createTheme(getTheme(modeTheme)),
    [modeTheme]
  )

  return (
    <ProvideWorker value={worker}>
      <ProviderRedux store={store}>
        <ProviderDarkMode value={value}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                  <Routers />
                </AuthProvider>
              </StyledThemeProvider>
            </ThemeProvider>
          </StylesProvider>
        </ProviderDarkMode>
      </ProviderRedux>
    </ProvideWorker>
  )
}

export default App
