import persistentStore from '../persistentStore'

const orderService = (baseUrl, endpoint) => ({
  orderBilling: async ({ signal, id = undefined }) => {
    try {
      const response = await fetch(
        `${baseUrl}/${endpoint}/shopify/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME,
            'api-key': process.env.REACT_APP_ORDER_API_KEY,
          },
          signal,
        }
      )

      const data = await response.text()
      return data
    } catch (error) {
      return error
    }
  },
})

export default orderService(process.env.REACT_APP_API_ORDER_PATH, 'orders')
