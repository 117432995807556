import { createAsyncThunk } from '@reduxjs/toolkit'
import brandServices from '../../../data/services/brandServices'

const fetchMultipleBrands = createAsyncThunk(
  'brands/list',
  async (refetch, { rejectWithValue }) => {
    try {
      const brandSlugSellers = await brandServices.getAllBrands()

      return brandSlugSellers
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    condition: (refetch, { getState }) => {
      const state = getState().brands
      if (refetch?.refetch) {
        return true
      }
      if (state?.status === 'finished') {
        return false
      }
    },
  }
)
export default fetchMultipleBrands
