import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const reasonAdapter = createEntityAdapter({
  selectId: reason => reason,
  sortComparer: (a, b) => String(a).localeCompare(String(b)),
})

export const reasonSlice = createSlice({
  name: 'reason',
  initialState: reasonAdapter.getInitialState([
    'Sin Stock',
    'Anular Orden / Arrepentimiento de Compra',
    'Retracto / Arrepentimiento de Compra',
    'Producto Defectuoso/Fallado',
    'SKU Erróneo',
    'Pedido Incompleto',
    'Despacho Atrasado',
    'Producto Dañado (por despacho)',
    'Desconocimiento de Entrega',
  ]),
  reducers: {
    addAllreason: reasonAdapter.setAll,
    addOnereason(state, { payload }) {
      if (payload?.includes(undefined)) {
        return
      }
      return reasonAdapter.addMany(state, payload)
    },
    addreason: reasonAdapter.addOne,
    removeOnereason: reasonAdapter.removeOne,
    removeAllreason: reasonAdapter.removeAll,
  },
})

export const {
  addAllreason,
  addOnereason,
  removeOnereason,
  addreason,
  removeAllreason,
} = reasonSlice.actions

const getStateReason = state => state.ui.reason

const reasonSelectors = reasonAdapter.getSelectors(getStateReason)
export const allReason = reasonSelectors.selectAll

const reducer = reasonSlice.reducer
export default reducer
