import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import ModalMUI from '@material-ui/core/Modal'
import styled from 'styled-components'
import { useFieldArray, useWatch } from 'react-hook-form'

import round from '../../../../domain/utils/round'
import Button from '@material-ui/core/IconButton'

import Title from '../../atomics/Typography/Title'
import download from '../../../assets/icon/download.svg'
import trash from '../../../assets/icon/trash.svg'
import close from '../../../assets/icon/x.svg'
import Tooltip from '../../atomics/Tooltip/Tooltip'

const Center = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled(ModalMUI)`
  z-index: 1500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
  margin-right: 20%;
  overflow: auto;
  @media (max-width: 1200px) {
    margin: 1%;
    height: 100%;
    width: 100%;
  }
`
const Label = styled('label')`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.2px;
`

const ModalTitle = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.2px;
`

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ImageInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

const TooltipFront = styled(Tooltip)`
  z-index: 9999 !important;
`

const ModalContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 0px 3.12996px 1.04332px rgba(0, 0, 0, 0.25);
  border-radius: 8.26805px;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 2%;
  padding-top: 50px;
  position: relative;
`

const Image = styled('img')`
  width: 719px;
  height: 719px;
  margin-top: 2%;
  @media (max-width: 1600px) {
    width: 519px;
    height: 519px;
  }
  @media (max-width: 1500px) {
    width: 419px;
    height: 419px;
  }
  @media (max-width: 1300px) {
    width: 319px;
    height: 319px;
  }
  @media (max-width: 600px) {
    margin: 1%;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 600px) {
    margin: 1%;
    height: 100%;
    width: 100%;
  }
`

const DownloadImageBtn = styled(Button)``

const DeleteImageBtn = styled(Button)``

const CloseBtn = styled(Button)`
  position: absolute;
  top: 2%;
  right: 10px;
`

const ButtonContainer = styled('div')`
  display: flex;
`

const ModalImage = ({
  open,
  onClose,
  title,
  subtitle,
  control,
  src,
  index,
  setMediaList,
  appendToDelete,
  media,
}) => {
  const [size, setSize] = useState(0)

  const getSize = useCallback(async () => {
    const blob = await fetch(src).then(r => r.blob())
    setSize(round(blob.size / 1024))
  }, [src, setSize])

  useEffect(getSize, [getSize])

  const productName = useWatch({ control, name: 'productName' })

  const downloadImage = () => {
    fetch(src, {
      method: 'GET',
      headers: {},
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'image.png') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const { remove } = useFieldArray({
    control,
    name: 'media',
  })

  const handleDeleteImage = () => {
    remove([index])
    appendToDelete(media)
    setMediaList(prev =>
      [...prev].filter((val, idx) => val && idx !== index)
    )
    onClose()
  }

  return (
    <Center>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={onClose}
        aria-labelledby={title}
        aria-describedby={subtitle}
      >
        <ModalContainer>
          <CloseBtn onClick={onClose}>
            <img src={close} alt="close" height={15} />
          </CloseBtn>
          <Container>
            <ImageInfoContainer>
              <ModalTitle>{`${productName} ${title}`}</ModalTitle>
              <Label>{`${media?.preview?.image?.width} px x ${media?.preview?.image?.height} px`}</Label>
              <Label>{`${size || media?.file?.size} kb`}</Label>
            </ImageInfoContainer>
            <ButtonContainer>
              <TooltipFront title="Descargar" placement="bottom">
                <DownloadImageBtn onClick={downloadImage}>
                  <img src={download} alt="download" height={25} />
                </DownloadImageBtn>
              </TooltipFront>
              <TooltipFront title="Eliminar" placement="bottom">
                <DeleteImageBtn onClick={handleDeleteImage}>
                  <img src={trash} alt="delete" height={25} />
                </DeleteImageBtn>
              </TooltipFront>
            </ButtonContainer>
          </Container>

          <Image src={src} />
        </ModalContainer>
      </Modal>
    </Center>
  )
}
ModalImage.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  control: PropTypes.any,
  index: PropTypes.number,
  media: PropTypes.any,
  errors: PropTypes.any,
  src: PropTypes.any,
  setMediaList: PropTypes.func,
  appendToDelete: PropTypes.any,
}
ModalImage.defaultProps = {
  open: false,
  media: [],
}
export default ModalImage
