import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import goBack from '../../../assets/goBack.svg'
import ProductExtraOptionsForm from '../../organims/ProductExtraOptionsForm/ProductExtraOptionsForm'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MenuItem } from '@material-ui/core'
import DeleteProductButton from '../../molecules/DeleteProductButton/DeleteProductButton'
import InputSelect from '../../atomics/InputSelect/InputSelect'
import ProductDetailsForm from '../../organims/ProductDetailsForm/ProductDetailsForm'
import ProductInfoForm from '../../organims/ProductInfoForm/ProductInfoForm'
import ProductPriceInventoryForm from '../../organims/ProductPriceInventoryForm/ProductPriceInventoryForm'
import ProductVariantForm from '../../organims/ProductVariantForm/ProductVariantForm'
import ButtonPreviewProduct from '../../atomics/ButtonPreviewProduct/ButtonPreviewProduct'

const Spinner = styled(CircularProgress)`
  color: #f6f6f7;
`

const Form = styled('form')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${({ ismargin }) => (ismargin === 'true' ? '2%' : '1%')};
  @media (max-width: 700px) {
    margin-top: 2%;
  }
`

const ButtonStyled = styled(Button)`
  font-weight: 400;
  font-size: 30px;
  line-height: 32px;
  text-transform: none;
  text-align: left;
  @media (max-width: 1100px) {
    margin-left: 0px;
  }
`

const ButtonSendStyled = styled(Button)`
  height: 49px;
  width: 213.93275451660156px;
  border-radius: 5px;
  margin-left: 1%;
  text-transform: none;
  background: ${({ variant }) =>
    variant === 'contained' ? '#008060' : '#ffffff'};
  @media (max-width: 1100px) {
    margin-left: 0%;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  margin-bottom: 10%;
  .MuiButtonBase-root:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`

const Head = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ ismargin }) => (ismargin === 'true' ? '5%' : '0px')};
  margin-bottom: 5%;
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`
const SellerContainer = styled('div')`
  margin-top: 2%;
  margin-left: 13%;
  align-self: flex-start;
  @media (max-width: 1100px) {
    margin-left: 6%;
  }
`
const Img = styled('img')``

const InputSelectStyled = styled(InputSelect)`
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '' : '#FFFFFF'};
`
const Left = styled('div')`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`
const Right = styled('div')`
  display: flex;
  justify-content: baseline;
  align-items: center;
`
const SellerName = styled('h1')`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
`

const ProductsTemplate = ({
  errors,
  isSubmitting,
  control,
  statusOnChange,
  allFiles,
  setAllFiles,
  productName,
  isDetailsView = false,
  productStatus,
  option,
  mediaList,
  setMediaList,
  variants,
  productId,
  onBack,
  onSubmit,
  sellerName,
  role,
  setValue,
  clearErrors,
  register,
  setError,
  viewOnlineStorePreviewUrl,
}) => {
  return (
    <Form onSubmit={onSubmit} ismargin={String(!isDetailsView)}>
      {sellerName && (
        <SellerContainer>
          <SellerName>{sellerName}</SellerName>
        </SellerContainer>
      )}
      <Head>
        <Left>
          <ButtonStyled
            variant="text"
            onClick={onBack}
            startIcon={<Img src={goBack} height="29" alt="back" />}
          >
            {productName || 'Agregar producto'}
          </ButtonStyled>
        </Left>
        {isDetailsView ? (
          <Right>
            {productName && viewOnlineStorePreviewUrl && (
              <ButtonPreviewProduct
                to={viewOnlineStorePreviewUrl}
                titleTooltip="Vista previa del producto"
              />
            )}
            <InputSelectStyled
              value={
                String(productStatus) === 'Deshabilitar'
                  ? 'Deshabilitado'
                  : productStatus
              }
              errors={{}}
              name="status"
              onChange={statusOnChange}
              disabled={
                (role === 'operations' && productStatus !== 'Aprobado') ||
                role === 'viewer'
              }
            >
              <MenuItem key={'statusproduct'} value={option}>
                {option}
              </MenuItem>
            </InputSelectStyled>
            <DeleteProductButton
              id={productId}
              productName={productName}
            />
          </Right>
        ) : null}
      </Head>
      <ProductInfoForm
        sellerName={sellerName}
        setError={setError}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
        control={control}
        mediaList={mediaList}
        setMediaList={setMediaList}
        isDetailsView={isDetailsView}
        productId={productId}
      />
      <ProductPriceInventoryForm control={control} />
      <ProductDetailsForm control={control} />
      <ProductVariantForm
        isDetailsView={isDetailsView}
        control={control}
        errors={errors}
        variants={variants}
      />
      <ProductExtraOptionsForm
        control={control}
        errors={errors}
        allFiles={allFiles}
        setAllFiles={setAllFiles}
        setValue={setValue}
        clearErrors={clearErrors}
        register={register}
      />
      {isDetailsView ? null : (
        <ButtonContainer isloading={String(isSubmitting)}>
          <ButtonSendStyled
            onClick={onBack}
            variant="outlined"
            disabled={isSubmitting}
          >
            Cancelar
          </ButtonSendStyled>
          <ButtonSendStyled
            onClick={onSubmit}
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            endIcon={isSubmitting ? <Spinner size="1.2rem" /> : null}
          >
            Agregar producto
          </ButtonSendStyled>
        </ButtonContainer>
      )}
    </Form>
  )
}

ProductsTemplate.propTypes = {
  errors: PropTypes.any,
  isSubmitting: PropTypes.bool,
  control: PropTypes.any,
  categoryList: PropTypes.array,
  brandList: PropTypes.array,
  setAllFiles: PropTypes.func,
  allFiles: PropTypes.object,
  productName: PropTypes.string,
  isDetailsView: PropTypes.bool,
  productStatus: PropTypes.string,
  option: PropTypes.string,
  mediaList: PropTypes.array,
  setMediaList: PropTypes.func,
  statusOnChange: PropTypes.func,
  variants: PropTypes.array,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  handleUploadImage: PropTypes.func,
  sellerName: PropTypes.string,
  role: PropTypes.string,
  register: PropTypes.any,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  viewOnlineStorePreviewUrl: PropTypes.string,
}
ProductsTemplate.defaultProps = {
  isSubmitting: false,
  isDetailsView: false,
  categoryList: [],
  brandList: [],
  mediaList: [],
  variants: [],
  allFiles: {},
  productName: '',
  onBack: undefined,
  onSubmit: undefined,
  sellerName: '',
  role: 'viewer',
}
export default ProductsTemplate
