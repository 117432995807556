import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Navigate from '../../atomics/Navigate'

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 179px;
  height: 37px;
  text-transform: none;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const AddProductButton = ({ ...props }) => {
  return (
    <Navigate to="/productos/nuevo">
      <StyledButton
        {...props}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
      >
        Agregar producto
      </StyledButton>
    </Navigate>
  )
}
AddProductButton.propTypes = {}

export default AddProductButton
