import { createSelector } from '@reduxjs/toolkit'
import { sellerAdapter, sellerSlice } from './sellerSlice'

export const {
  addSellers,
  addOneSeller,
  removeOneSeller,
  removeAllSeller,
} = sellerSlice.actions

const getStateSeller = state => state.sellers.list

export const getSellers = createSelector(
  [getStateSeller],
  sellers => sellers
)

const sellerSelectors = sellerAdapter.getSelectors(getStateSeller)
export const allSellers = sellerSelectors.selectAll
