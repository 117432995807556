import axios from 'axios'
import pipe from 'lodash/fp/pipe'
import { createAsyncThunk } from '@reduxjs/toolkit'

import subOrderService from '../../../data/services/subOrderService'
import normalizeOrder from '../orders/normalizeOrder'
import fechOrderDetail from '../orderDetail/fetchOrderDetail'

const fetchOrderUpdateStatus = createAsyncThunk(
  'order/update/status',
  async ({ id }, { signal, dispatch }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const data = await subOrderService.orderUpdateStatus({
      id,
      cancelToken: source.token,
    })

    const orderDetailWithAfterSale = pipe(normalizeOrder)(data?.suborder)
    dispatch(fechOrderDetail({ parentId: id }))
    return orderDetailWithAfterSale
  }
)
export default fetchOrderUpdateStatus
