import { useEffect } from 'react'
import fetchSellers from '../../domain/features/sellers/fetchSellers'
import fetchMultipleBrands from '../../domain/features/brandsSlugSeller/fetchMultipleBrands'
import fetchCategory from '../../domain/features/category/fetchCategory'
import { useDispatch, useSelector } from 'react-redux'
import { getSellers } from '../../domain/features/sellers/getSellers'
import { getBrands } from '../../domain/features/brandsSlugSeller/getBrands'
import { getCategory } from '../../domain/features/category/getCategory'

function useInitDataLoad() {
  const dispatch = useDispatch()
  const { status: statusSeller } = useSelector(getSellers)
  const { status: statusMultipleBrands } = useSelector(getBrands)
  const { status: statusCategory } = useSelector(getCategory)

  useEffect(() => {
    const loadSellers = async () => {
      try {
        if (statusSeller === 'idle') {
          await dispatch(fetchSellers()).unwrap()
        }
      } catch (error) {}
    }

    loadSellers()
  }, [dispatch, statusSeller])

  useEffect(() => {
    const loadMultipleBrands = async () => {
      try {
        if (statusMultipleBrands === 'idle') {
          await dispatch(fetchMultipleBrands()).unwrap()
        }
      } catch (error) {}
    }

    loadMultipleBrands()
  }, [dispatch, statusMultipleBrands])

  useEffect(() => {
    const loadCategory = async () => {
      try {
        if (statusCategory === 'idle') {
          await dispatch(fetchCategory()).unwrap()
        }
      } catch (error) {}
    }

    loadCategory()
  }, [dispatch, statusCategory])
}

export default useInitDataLoad
