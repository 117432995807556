import styled from 'styled-components'
import Text from '../Typography/Text'

const Tag = styled(Text)`
  background-color: ${({ status, theme }) =>
    status || theme.palette.warning.main};
  border-radius: 15px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  margin-left: 0.5rem;
  color: #212121;
  font-weight: 400;
  font-size: 11px;
  width: 100px;
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  @media (max-width: 1100px) {
    margin-top: 0.5rem;
  }
`
export default Tag
