import { createAsyncThunk } from '@reduxjs/toolkit'
import campaignServices from '../../../data/services/campaignServices'

const fetchCampaigns = createAsyncThunk(
  'campaigns/list',
  async (_, { signal }) => {
    const campaigns = await campaignServices.campaignList({
      cancelToken: signal,
    })
    return campaigns
  },
  {
    condition: (_, { getState }) => {
      const state = getState().campaigns.list
      if (state?.status === 'finished') {
        return false
      }
    },
  }
)
export default fetchCampaigns
