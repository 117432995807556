import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchSellerUpdate from './fetchSellersUpdate'

export const sellerUpdateAdpater = createEntityAdapter()

export const sellerUpdateSlice = createSlice({
  name: 'seller/update',
  initialState: sellerUpdateAdpater.getInitialState({
    status: 'idle',
    seller: undefined,
  }),
  reducers: {
    resetUpdateSlice(state) {
      state.status = 'idle'
      state.payload = {}
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSellerUpdate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchSellerUpdate.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchSellerUpdate.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.seller = payload
    })
  },
})
export const { resetUpdateSlice } = sellerUpdateSlice.actions
const reducer = sellerUpdateSlice.reducer
export default reducer
