import React from 'react'
import PropTypes from 'prop-types'

import TooltipMUI from '@material-ui/core/Tooltip'

const Tooltip = ({ children, followCursor, ...props }) => {
  return (
    <TooltipMUI
      arrow={true}
      followCursor={true || followCursor}
      {...props}
    >
      {children}
    </TooltipMUI>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  followCursor: PropTypes.bool,
}
Tooltip.defaultProps = {
  followCursor: true,
}
export default Tooltip
