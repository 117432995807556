import React from 'react'
import { node } from 'prop-types'

import DarkModeContext from './DarkModeContext'

const ProviderDarkMode = ({ children, ...props }) => {
  return (
    <DarkModeContext.Provider {...props}>
      {children}
    </DarkModeContext.Provider>
  )
}

ProviderDarkMode.propTypes = { children: node.isRequired }

export default ProviderDarkMode
