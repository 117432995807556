import React, { useMemo, useState, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from '../../../domain/schemas/sellerCreateSchema'
import SellerGeneralInformationForm from '../organims/SellerGeneralInformationForm'
import Center from '../atomics/Center'
import BrandsCard from '../organims/BrandsCard/BrandsCard'
import SubmitButton from '../atomics/Button/SubmitButton/SubmitButton'
import fetchSellersCreate from '../../../domain/features/sellers/sellerCreate/fetchSellersCreate'
import { useNavigate } from 'react-router-dom'
import { getSellerCreate } from '../../../domain/features/sellers/sellerCreate/getSellersCreate'
import CancelButton from '../atomics/Button/CancelButton'
import styled from 'styled-components'
import CommissionsCard from '../organims/CommissionsCard/CommissionsCard'
import Alert, { typeAlert } from '../atomics/Alert/Alert'
import ShippingManagementCard from '../organims/ShippingManagementCard/ShippingManagementCard'
import Navigate from '../atomics/Navigate'
import { Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Title from '../atomics/Typography/Title'
import CardWithTitle from '../molecules/CardWithTitle/CardWithTitle'
import { useAuth } from '../pages/auth/useAuth'
import DeliveryCard from '../organims/DeliveryCard/DeliveryCard'

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 5%;
  margin-right: 4%;
`

const ButtonCancel = styled(CancelButton)`
  background: #ffffff;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
  color: #6b6b6b;
  box-shadow: none;
`

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const HeaderTitle = styled(Header)`
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`
const ContainerMainData = styled('div')`
  margin-left: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1100px) {
    margin-left: 5px;
  }
`
const ButtonBack = styled(Button)`
  border: 1px solid #c1c1c1;
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 1rem;
  min-width: 15px;
  @media (max-width: 1100px) {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
`
const TitleSeller = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 0.2px;
  @media (max-width: 1100px) {
    margin-left: 0rem;
  }
`

const StyledCenter = styled(Center)`
  width: 90%;
  margin: auto;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const ErrorText = styled('p')`
  color: #e72020;
`

const stateCreate = x =>
  new Map([
    ['finished', `Seller creado`],
    ['loading', `Creando seller ${x} `],
    ['error', `Error al crear ${x}`],
  ])

const SellerCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { status } = useSelector(getSellerCreate)
  const { currentUser: user } = useAuth()
  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      brands: [''],
    },
    criteriaMode: 'firstError',
    shouldFocusError: true,
  })

  const onSubmit = handleSubmit(
    React.useCallback(
      data => {
        dispatch(fetchSellersCreate(data))
      },
      [dispatch]
    )
  )
  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const nameSellerWatch = useWatch({ name: 'nameSeller', control })

  useEffect(
    () => (status !== 'idle' ? setOpenAlert(true) : null),
    [status]
  )

  return (
    <>
      {process.env.NODE_ENV === 'development' ? (
        <DevTool control={control} placement="top-left" />
      ) : null}

      <StyledCenter>
        <HeaderTitle>
          <ContainerMainData>
            <Navigate to={'/sellers'}>
              <ButtonBack color="inherit">
                <ArrowBackIcon />
              </ButtonBack>
            </Navigate>
            <div>
              <TitleSeller>Agregar Seller</TitleSeller>
            </div>
          </ContainerMainData>
        </HeaderTitle>

        <CardWithTitle title="Datos empresa">
          <SellerGeneralInformationForm control={control} />
        </CardWithTitle>
        <CardWithTitle title="Marcas">
          <BrandsCard control={control} />
        </CardWithTitle>
        <CardWithTitle>
          <CommissionsCard
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
          />
          {errors.commissionCategories?.message && (
            <ErrorText>{errors.commissionCategories?.message}</ErrorText>
          )}
        </CardWithTitle>
        <CardWithTitle title="Gestión de Envíos">
          <DeliveryCard control={control} />
        </CardWithTitle>
        <div style={{ display: 'none' }}>
          <ShippingManagementCard
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </div>
        <ButtonContainer>
          <ButtonCancel onClick={() => navigate('/sellers')}>
            Cancelar
          </ButtonCancel>
          <SubmitButton
            color="#7CC7E2"
            onClick={onSubmit}
            disabled={role === 'viewer'}
            isLoading={isSubmitting || status === 'loading'}
          >
            Agregar seller
          </SubmitButton>
        </ButtonContainer>
        <Alert
          open={openAlert}
          onClose={handleCloseAlert}
          title={stateCreate(nameSellerWatch).get(status)}
          isLoading={status === 'loading'}
          severity={typeAlert.get(status)}
          isError={status === 'error'}
        />
      </StyledCenter>
    </>
  )
}

export default SellerCreate
