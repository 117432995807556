import ExcelJS from 'exceljs'

const getReportExcel = async ({
  e,
  fileName,
  format,
  rows,
  columns,
  sheet,
}) => {
  e.preventDefault()

  const workbook = new ExcelJS.Workbook()
  workbook.addWorksheet(sheet || 'sheet1')
  const worksheet = workbook.getWorksheet(sheet || 'sheet1')

  worksheet.columns = columns

  worksheet.addRows(rows)

  const uint8Array =
    format === 'xlsx'
      ? await workbook.xlsx.writeBuffer()
      : await workbook.csv.writeBuffer()

  const blob = new Blob([uint8Array], { type: 'application/octet-binary' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${fileName}.${format}`
  a.click()
  a.remove()
}

export default getReportExcel
