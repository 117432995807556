import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'

import fetchUpdateAfterSale from '../../../../domain/features/afterSale/fetchUpdateAfterSale'
import { afterSaleSelector } from '../../../../domain/features/afterSale/afterSaleSlice'
import { statusColorMapper } from '../../../../domain/adpaters/map/statusColorMapper'
import { useAuth } from '../../pages/auth/useAuth'
import Tooltip from '../../atomics/Tooltip'
import Text from '../../atomics/Typography/Text'
import Select from '../../atomics/Select/SelectOld'
import Accordion from '../../molecules/Accordion'
import Alert from '../../atomics/Alert/Alert'
import Navigate from '../../atomics/Navigate'
import Image from '../../atomics/Image'
import Tag from '../../atomics/Tag'
import image from '../../../assets/image/placeholderImage.png'
import getFullDate from '../../../../domain/adpaters/days/getFullDate'

const SelectOrder = styled(Select)`
  margin: 0px;
  padding: 0px;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#313234' : '#ffffff'};
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4rem;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`

const HeaderRight = styled('div')`
  justify-content: flex-end;
  flex: 1;
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    padding-right: 1rem;
  }
`

const ItemCard = styled(Paper)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0.836312px 3.34525px rgba(33, 33, 33, 0.25);
  box-sizing: border-box;
  border-radius: 8px;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};

  @media (max-width: 1100px) {
    padding-left: 0rem;
  }
`

const ContentCard = styled('div')`
  padding-top: 16px;
  padding-left: 50px;
  padding-top: 2rem;
  padding-bottom: 1rem;
  @media (max-width: 1100px) {
    padding-left: 10px;
  }
`

const TitleStatus = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`

const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 1100px) {
    padding-right: 1rem;
  }
`

const Column = styled('div')`
  margin-left: 5%;
  @media (max-width: 1100px) {
    margin-left: 15px;
  }
`

const ContainerColumn = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const ItemContent = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 1100px) {
    padding-right: 1rem;
  }
`

const ContainerType = styled('div')`
  padding-left: 3.8rem;
  margin-right: 2rem;
  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
`

const TitleProduct = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  @media (max-width: 1100px) {
    font-size: 15px;
    line-height: 30px;
  }
`

const SubtitleDate = styled(Text)`
  font-size: 14px;
  line-height: 25px;

  font-weight: 300;
`

const Divider = styled('div')`
  border-bottom: 0.84px solid #b7b7b7;
  margin-bottom: 1.2rem;
`

const ItemContainerRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`

const AmountText = styled(TitleProduct)`
  font-size: 13px;
`

const AccordingContainer = styled(ItemContent)`
  margin-right: 0rem;
  width: 100%;
`
const ImageDefault = styled(Image)`
  object-fit: contain;
  width: 90px;
  height: 90px;
  margin-right: 20px;
  @media (max-width: 1100px) {
    width: 40px;
    height: 40px;
  }
`
const TextSku = styled(Text)`
  font-weight: normal;
  font-size: 14px;
`
const TextBrand = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
`
const StyledTag = styled(Tag)`
  width: 150px;
  height: 30px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding-top: 0px;
`

const typeText = (x, y) =>
  new Map([
    [
      'finished',
      `Cambio de estado a ${x} realizado exitosamente post venta ${y}`,
    ],
    ['error', `Error al cambiar estado a ${x} post venta ${y}`],
    ['loading', `Cambiando estado a ${x} post venta ${y}`],
  ])

const typeAlert = new Map([
  ['finished', 'success'],
  ['error', 'error'],
  ['loading', 'info'],
])

const returnsList = ['Ingresado', 'Rechazado', 'Devuelto']
const cancelList = ['Cancelado', 'Rechazado']
const forwardingList = [
  'No Despachado',
  'Rechazado',
  'Despachado',
  'Facturado',
]

const typeMap = new Map([
  ['Devolución', returnsList],
  ['Cancelación', cancelList],
  ['Reenvío', forwardingList],
])

const SelectStatus = ({ type, ...props }) => {
  const { currentUser: user } = useAuth()
  const isInvalid = String(user?.role)?.toLowerCase() === 'viewer'
  const statusList = React.useMemo(() => typeMap?.get(type), [type])

  return (
    <SelectOrder
      id="change-status"
      handleDelete={() => undefined}
      disabled={props.disabled || isInvalid}
      list={statusList || returnsList}
      label=""
      autoWidth
      {...props}
    />
  )
}

const CardAfterSale = ({
  matches,
  id,
  type,
  parentId,
  getProductBySku,
  reason,
  createdAt,
  ...props
}) => {
  const [currentStatus, setStatus] = React.useState(props?.status || '')
  const dispatch = useDispatch()

  const onChangeStatus = React.useCallback(
    value => {
      setStatus(value)
      dispatch(fetchUpdateAfterSale({ status: value, id, type, parentId }))
    },
    [dispatch, id, type, parentId]
  )

  const isDisabled = React.useMemo(
    () => props?.status === 'Rechazado' || props?.status === 'Venta',
    [props?.status]
  )

  const [openAlert, setOpenAlert] = React.useState(false)

  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }
  const { status } = useSelector(afterSaleSelector)

  React.useEffect(
    () => (status !== 'idle' ? setOpenAlert(true) : null),
    [status]
  )

  return (
    <>
      <Header>
        <ContainerType>
          <TitleStatus>{type}</TitleStatus>
          <TitleProduct>{id}</TitleProduct>

          <SubtitleDate>{getFullDate(createdAt)}</SubtitleDate>
        </ContainerType>
        <HeaderRight>
          <Tooltip title="Estado despacho">
            <StyledTag status={statusColorMapper.get(props?.status)}>
              {props?.status}
            </StyledTag>
          </Tooltip>
          <Tooltip title="Estado contable">
            <StyledTag
              status={statusColorMapper.get(props?.accountingStatement)}
            >
              {props?.accountingStatement}
            </StyledTag>
          </Tooltip>
          {!matches ? (
            <SelectStatus
              type={type}
              disabled={isDisabled}
              defaultValue={props?.status}
              value={currentStatus}
              onChange={e => {
                onChangeStatus(e.target.value)
              }}
            />
          ) : null}
        </HeaderRight>
      </Header>

      {matches ? (
        <SelectStatus
          type={type}
          disabled={isDisabled}
          defaultValue={props?.status}
          value={currentStatus}
          onChange={e => {
            onChangeStatus(e.target.value)
          }}
        />
      ) : null}
      <ItemCard disabled={isDisabled}>
        {props?.itemList?.map(product => {
          const priceTotal = product?.price * product?.quantity
          const productBySku = product?.sku
            ? getProductBySku(product?.sku)
            : {}

          return (
            <React.Fragment key={`${product?.name}-${product?.sku}`}>
              <ContentCard>
                <ItemContainer>
                  {productBySku?.to ? (
                    <Navigate to={productBySku?.to} replace>
                      <ImageDefault
                        src={product.image || productBySku?.image}
                        alt={product.name || productBySku?.alt}
                      />
                    </Navigate>
                  ) : (
                    <ImageDefault src={image} />
                  )}
                  <div>
                    {productBySku?.to ? (
                      <Navigate to={productBySku?.to}>
                        <TitleProduct>{product?.name}</TitleProduct>
                      </Navigate>
                    ) : (
                      <TitleProduct>{product?.name}</TitleProduct>
                    )}
                    {product?.sku && (
                      <TextSku>
                        SKU: {product?.sku}
                        {product?.ownSku || productBySku?.ownSku
                          ? ` | SKU Propio ${
                              product?.ownSku || productBySku?.ownSku
                            }`
                          : null}
                      </TextSku>
                    )}
                    <TextBrand>Marca: {product?.brand}</TextBrand>
                    <TextBrand>
                      Motivo {type.toLowerCase()}: <b>{reason}</b>
                    </TextBrand>
                  </div>
                  <ContainerColumn>
                    <Column>
                      <TitleProduct>
                        (CLP {product?.price?.toLocaleString('es-CL')} x{' '}
                        {product?.quantity})
                      </TitleProduct>
                    </Column>
                    <Column>
                      <TitleProduct>
                        CLP {priceTotal?.toLocaleString('es-CL')}
                      </TitleProduct>
                    </Column>
                  </ContainerColumn>
                </ItemContainer>
              </ContentCard>
              <Divider />
            </React.Fragment>
          )
        })}
        <ContentCard>
          <ItemContent>
            <ItemContainerRow>
              <TitleProduct>Venta</TitleProduct>
              <TitleProduct>
                CLP {props?.totalSale?.toLocaleString('es-CL')}
              </TitleProduct>
            </ItemContainerRow>
            <ItemContainerRow>
              <TitleProduct>Venta despachada</TitleProduct>
              <TitleProduct>
                CLP {props?.dispatchedSale?.toLocaleString('es-CL')}
              </TitleProduct>
            </ItemContainerRow>
            <ItemContainerRow>
              <TitleProduct>Comisión</TitleProduct>
              <TitleProduct>
                CLP {props?.commission?.toLocaleString('es-CL')}
              </TitleProduct>
            </ItemContainerRow>
            <div style={{ width: '100%' }}>
              <Accordion
                title={() => (
                  <ItemContainerRow>
                    <TitleProduct>Otros descuentos</TitleProduct>
                    <div>
                      <TitleProduct>
                        CLP {props?.otherDiscount?.toLocaleString('es-CL')}
                      </TitleProduct>
                    </div>
                  </ItemContainerRow>
                )}
              >
                <AccordingContainer>
                  <ItemContainerRow>
                    <AmountText color="textSecondary">
                      Transporte
                    </AmountText>
                    <AmountText color="textSecondary">
                      CLP {props?.transport?.toLocaleString('es-CL')}
                    </AmountText>
                  </ItemContainerRow>
                  <ItemContainerRow>
                    <AmountText color="textSecondary">Multa</AmountText>
                    <AmountText color="textSecondary">
                      CLP {props?.penalty?.toLocaleString('es-CL')}
                    </AmountText>
                  </ItemContainerRow>
                </AccordingContainer>
              </Accordion>
            </div>
            <ItemContainerRow>
              <TitleProduct>Payout</TitleProduct>
              <TitleProduct>
                CLP {props?.payout?.toLocaleString('es-CL')}
              </TitleProduct>
            </ItemContainerRow>
          </ItemContent>
        </ContentCard>
      </ItemCard>
      <Alert
        open={openAlert}
        onClose={handleCloseAlert}
        title={typeText(currentStatus, id).get(status)}
        isLoading={status === 'loading'}
        severity={typeAlert.get(status)}
        isError={status === 'error'}
      />
    </>
  )
}

export default CardAfterSale
