import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import ButtonDownload from '../../molecules/ButtonDownload/ButtonDownload'
import { Tooltip } from '@material-ui/core'
import { useAuth } from '../../pages/auth/useAuth'

const DownloadContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;

  margin-left: 0.5rem;
  @media (max-width: 700px) {
    margin-left: 0rem;
  }
`

const LayoutFilter = styled(Box)`
  @media (max-width: 700px) {
    margin-left: 10px;
    width: 100%;
  }
`

const FilterContainer = ({ children, onDownloadExcel }) => {
  const { currentUser } = useAuth()

  const isInvalid = String(currentUser?.role)?.toLowerCase() === 'viewer'
  return (
    <LayoutFilter
      pt="25px"
      ml="20px"
      mr="20px"
      flexDirection="row"
      display="flex"
      alignItems="center"
    >
      <Box
        flexDirection="row"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        {children}
      </Box>
      {onDownloadExcel && (
        <Tooltip title="Descargar">
          <DownloadContainer>
            <ButtonDownload
              onClick={onDownloadExcel}
              disabled={isInvalid}
            />
          </DownloadContainer>
        </Tooltip>
      )}
    </LayoutFilter>
  )
}

export default FilterContainer
