import { createAsyncThunk } from '@reduxjs/toolkit'
import brandServices from '../../../data/services/brandServices'

const fetchBrands = createAsyncThunk(
  'brands',
  async ({ slug_seller, token }, { rejectWithValue }) => {
    try {
      const brandSlugSeller = await brandServices.getSlugSellerBrands({
        slug_seller,
        token,
      })

      return brandSlugSeller
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchBrands
