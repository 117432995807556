import { createSelector } from '@reduxjs/toolkit'
import { sellerCreateAdpater } from './sellerCreateSlice'

const getStateSellerCreate = state => state.sellers.create

export const getSellerCreate = createSelector(
  [getStateSellerCreate],
  seller => seller
)

const sellerCreateSelector = sellerCreateAdpater.getSelectors(
  getStateSellerCreate
)
export const resultsSellerCreate = sellerCreateSelector.selectAll
