import React from 'react'
import Select from '../../atomics/Select'

const SelectWithChips = ({
  label,
  list,
  setList,
  handleDeleteChip,
  handleAddChip,
  isallfilter,
  ...props
}) => {
  return (
    <Select
      isallfilter={isallfilter}
      labelId="select-mutiplkeye-chip-label"
      id="select-mutiple-chip"
      multiple
      label={label}
      list={list}
      handleDelete={handleDeleteChip}
      handleAdd={handleAddChip}
      {...props}
    />
  )
}

export default SelectWithChips
