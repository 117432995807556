import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const TitleProduct = styled(Typography)`
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-bottom: 47px;
  @media (max-width: 1100px) {
    margin-bottom: 5%;
  }
`
export default TitleProduct
