import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const typeAdapter = createEntityAdapter({
  selectId: type => type,
  sortComparer: (a, b) => String(a).localeCompare(String(b)),
})

export const typeSlice = createSlice({
  name: 'type',
  initialState: typeAdapter.getInitialState(),
  reducers: {
    addAllType: typeAdapter.setAll,
    addOneType(state, { payload }) {
      if (payload?.includes(undefined)) {
        return
      }
      return typeAdapter.addMany(state, payload)
    },
    addType: typeAdapter.addOne,
    removeOneType: typeAdapter.removeOne,
    removeAllType: typeAdapter.removeAll,
  },
})

export const {
  addAllType,
  addOneType,
  removeOneType,
  addType,
  removeAllType,
} = typeSlice.actions

const getStateType = state => state.ui.type

const typeSelectors = typeAdapter.getSelectors(getStateType)
export const allTypes = typeSelectors.selectAll

const reducer = typeSlice.reducer
export default reducer
