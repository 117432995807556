import isArray from 'lodash/isArray'

const average = (array = [], iteratee = '') =>
  isArray(array)
    ? array?.reduce((sum, row) => {
        return row[iteratee] + sum
      }, 0)
    : 0

export default average
