import styled from 'styled-components'
import CardWithTitleAndButton from '../../../molecules/CardWithTitleAndButton/CardWithTitleAndButton'
import SkeletonText from '../../../atomics/SkeletonText'
import { useCallback, useEffect, useRef, useState } from 'react'
import Modal from '../../../atomics/Modal/Modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TooltipHelp from '../../../molecules/TooltipHelp/TooltipHelp'
import CommissionsCard from '../../CommissionsCard/CommissionsCard'
import { schemaComission } from '../../../../../domain/schemas/sellerUpdateSchema'
import { useDispatch, useSelector } from 'react-redux'
import { getSellerUpdate } from '../../../../../domain/features/sellers/sellerUpdate/getSellersUpdate'
import fetchSellersUpdate from '../../../../../domain/features/sellers/sellerUpdate/fetchSellersUpdate'
import CancelButton from '../../../atomics/Button/CancelButton'
import SubmitButton from '../../../atomics/Button/SubmitButton/SubmitButton'
import CardWithTitle from '../../../molecules/CardWithTitle/CardWithTitle'
import { CardButton } from '../seller.components'
import ButtonCopy from '../../../atomics/ButtonCopy/ButtonCopy'

const List = styled('ul')`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  width: 100%;
  display: inline-block;
`
const ListItem = styled('li')`
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 15px;
`
const TitleItem = styled('label')``
const BodyItem = styled('label')`
  font-weight: bold;
`
const CardTitleTooltip = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Divider = styled('div')`
  margin: 20px 0px 30px 0px;
  border-bottom: 1px solid #b7b7b7;
  width: 100%;
`
const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  margin-top: 4px;
  margin-right: 4%;
`

const ButtonCancel = styled(CancelButton)`
  background: #ffffff;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
  color: #6b6b6b;
  box-shadow: none;
`
const CategoryTitle = styled('div')`
  display: flex;
  gap: 7px;
  align-items: center;
  font-size: 15.5px;
  font-weight: 700;
  margin-bottom: 15px;
`

const ErrorText = styled('p')`
  color: #e72020;
`
const SellerComission = ({
  seller,
  slugSeller,
  onUpdate,
  statusSeller,
  role,
}) => {
  const prevStatus = useRef(null)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schemaComission),
    mode: 'all',
  })
  const { status } = useSelector(getSellerUpdate)

  const [visibleModal, setVisibleModal] = useState(false)

  const openModal = () => {
    reset({
      commissionSeller: (seller.commissionSeller * 100).toFixed(1),
      commissionCategories: seller.commissionCategories.map(c => ({
        ...c,
        commissionProductType: (c?.commissionProductType * 100).toFixed(1),
      })),
    })
    setVisibleModal(true)
  }
  const closeModal = () => {
    setVisibleModal(false)
  }
  const onSubmit = handleSubmit(
    useCallback(
      data => {
        dispatch(
          fetchSellersUpdate({
            nameSeller: seller?.nameSeller,
            bussinesName: seller?.bussinesName,
            giro: seller?.giro,
            ...data,
            slugSeller,
          })
        )
      },
      [dispatch, slugSeller, seller]
    )
  )

  useEffect(() => {
    if (status === 'finished' && prevStatus.current !== 'finished') {
      onUpdate()
      setVisibleModal(false)
    }
    prevStatus.current = status
  }, [status, onUpdate])

  const getCopyText = () => {
    let copyText = `Comisiones ${seller?.nameSeller || ''}\n`
    copyText += `Rut empresa: ${seller?.rut || ''}\n`

    copyText += `Comisión global: ${
      seller?.commissionSeller
        ? `${(seller?.commissionSeller * 100).toFixed(1)} %`
        : '-'
    }\n`

    if (seller?.activeCampaign) {
      copyText += `Campaña activa: ${seller.activeCampaign.nameCampaign}\n`
    }

    if ((seller?.commissionCategories || []).length > 0) {
      copyText += `\nComisiones por categoría:\n`
      seller?.commissionCategories.forEach(comission => {
        copyText += `${comission?.nameProductType}: ${(
          comission?.commissionProductType * 100
        ).toFixed(1)} %\n`
      })
    }

    return copyText
  }

  return (
    <>
      <Modal open={visibleModal} onClose={closeModal}>
        <CardWithTitle style={{ minWidth: '800px' }}>
          <CommissionsCard
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            initialChecked={seller?.commissionCategories?.length > 0}
          />
          {errors.commissionCategories?.message && (
            <ErrorText>{errors.commissionCategories?.message}</ErrorText>
          )}

          <ButtonContainer>
            <ButtonCancel onClick={closeModal}>Cancelar</ButtonCancel>
            <SubmitButton
              color="#65B8D6"
              onClick={onSubmit}
              disabled={role === 'viewer'}
              isLoading={isSubmitting || status === 'loading'}
            >
              Guardar cambios
            </SubmitButton>
          </ButtonContainer>
        </CardWithTitle>
      </Modal>
      <CardWithTitleAndButton
        title={
          <CardTitleTooltip>
            Comisiones
            <TooltipHelp text="La comisión que se aplica por defecto es la comisión global del seller. Es posible activar comisiones por categoría." />{' '}
          </CardTitleTooltip>
        }
        render={
          statusSeller === 'finished' &&
          ['admin', 'operations'].includes(role)
            ? () => (
                <>
                  <ButtonCopy
                    title={`Copiar comisiones de ${seller?.nameSeller}`}
                    getText={getCopyText}
                    message={`${seller?.nameSeller} comisiones copiadas!`}
                  />

                  <CardButton
                    title="Editar comisiones"
                    onClick={openModal}
                  />
                </>
              )
            : null
        }
      >
        <List>
          <ListItem>
            <TitleItem>Comisión global</TitleItem>:{' '}
            <BodyItem>
              <SkeletonText
                text={
                  seller?.commissionSeller
                    ? `${(seller?.commissionSeller * 100).toFixed(1)} %`
                    : undefined
                }
              />
            </BodyItem>
          </ListItem>
          {seller?.activeCampaign && (
            <ListItem>
              <TitleItem>Campaña activa</TitleItem>:{' '}
              <BodyItem>{seller.activeCampaign.nameCampaign}</BodyItem>
            </ListItem>
          )}
        </List>
        {(seller?.commissionCategories || []).length > 0 && (
          <>
            <Divider />
            <CategoryTitle>
              Comisiones por categoría{' '}
              <TooltipHelp text="La comisión por categoría reemplaza a la comisión global." />
            </CategoryTitle>
            <List>
              {(seller?.commissionCategories || []).map(comission => {
                return (
                  <ListItem key={comission?.nameProductType}>
                    <TitleItem>{comission?.nameProductType}</TitleItem>:{' '}
                    <BodyItem>
                      {(comission?.commissionProductType * 100).toFixed(1)}{' '}
                      %
                    </BodyItem>
                  </ListItem>
                )
              })}
            </List>
          </>
        )}
      </CardWithTitleAndButton>
    </>
  )
}

export default SellerComission
