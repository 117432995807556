import { Skeleton } from '@material-ui/lab'

const SkeletonText = ({ text }) => {
  if (!text) {
    return (
      <Skeleton
        style={{ display: 'inline-block', minWidth: '120px' }}
        variant="text"
      />
    )
  }
  return <>{text}</>
}

export default SkeletonText
