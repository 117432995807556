import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Navigate = styled(props => <Link {...props} />)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  text-decoration-line: none;
`

export default Navigate
