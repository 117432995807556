import { useEffect, useState } from 'react'
import persistentStore from '../../../data/persistentStore'

const isSupportedBrowserDarkMode = () =>
  window.matchMedia('(prefers-color-scheme)').media !== 'not all'

const darkModeMediaQuery = window.matchMedia(
  '(prefers-color-scheme: dark)'
)

const currentTheme = persistentStore.getTheme()

const useStoreTheme = () => {
  const [modeTheme, setModeTheme] = useState(currentTheme || 'light')

  useEffect(() => {
    if (isSupportedBrowserDarkMode) {
      darkModeMediaQuery.addEventListener('change', event => {
        const darkModeOn = event.matches
        setModeTheme(darkModeOn ? 'dark' : 'light')
      })
    }

    if (currentTheme === modeTheme) {
      return
    }

    persistentStore.setTheme(modeTheme)
  }, [modeTheme, setModeTheme])

  return [modeTheme, setModeTheme]
}

export default useStoreTheme
