import styled from 'styled-components'
import { animated } from '@react-spring/web'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  cursor: wait;
  pointer-events: auto;
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  pointer-events: auto;
`

export const ImageStyled = styled(animated.img)`
  display: 'block';
  width: 100%;
  height: 100%;
  border: 1px solid #c1c1c1;
  background-color: ${({ theme }) =>
    theme.palette.type !== 'dark' ? '#313234' : '#FFFFFF'};
`

export const EmojiBox = styled.div`
  position: absolute;
  bottom: -10px;
  right: -5px;
  font-size: 40px;
`

export const MessageBox = styled(animated('p'))`
  font-size: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 300px;
  height: 50px;
  color: ${({ theme }) => theme.palette.text.primary};
`
