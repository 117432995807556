import { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Image from '../../atomics/Image'
import Bold from '../../atomics/Typography/Bold'
import Text from '../../atomics/Typography/Text'
import Checkbox from '../../atomics/Checkbox/Checkbox'

const ContainerVariantItem = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: solid 2px ${props => (props.hasError ? 'red' : 'transparent')};
  border-top: solid
    ${props => (props.hasError ? '2px red' : '1px #b7b7b7')};
  border-radius: 10px;
  width: 100%;
  padding: 2%;
  padding-left: 58px;
  padding-right: 58px;
  &:hover {
    background-color: rgba(196, 196, 196, 0.3);
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
  @media (max-width: 700px) {
    padding-left: 0px;
    padding-right: 1%;
  }
`
const SubContainerImage = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
`
const VariantImage = styled(Image)`
  width: 94px;
  height: 94px;
  margin-left: 4%;
  margin-right: 4%;
`
const SubContainerText = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    flex: 1;
    justify-content: baseline;
  }
`
const VariantBold = styled(Bold)`
  font-weight: 500, Medium;
  font-size: 22px;
  line-height: 25.78px;
  white-space: nowrap;
  @media (max-width: 700px) {
    overflow: hidden;
    text-transform: ellipse;
  }
`
const VarianTextSku = styled(Text)`
  font-weight: 400;
  line-height: 32px;
  font-size: 20px;
  white-space: wrap;
  @media (max-width: 700px) {
    overflow: hidden;
    text-transform: ellipse;
  }
`
const VarianText = styled(VarianTextSku)`
  text-align: right;
`
const Divider = styled(VarianTextSku)`
  padding-left: 10px;
  padding-right: 10px;
`
const VarianTextRed = styled(VarianText)`
  color: rgba(124, 124, 124, 1);
  text-decoration: line-through;

  margin-left: 9px;
`

const SubContainerPrice = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CheckboxStyled = styled(Checkbox)`
  align-self: center;
  ${props =>
    props.disabled
      ? css`
          visibility: hidden;
        `
      : null};
`
const VariantItemContainer = styled('div')`
  display: flex;
  flex-direction: row;
  display: flex;
  flex: 5;
  cursor: pointer;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`
const Row = styled('div')`
  display: flex;
  flex-direction: row;
  display: flex;
  width: 100%;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`
const money = string =>
  String(string)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const VariantItem = memo(
  ({
    index,
    checked,
    onChange,
    variantId,
    variantAlt,
    variantImage,
    variantName,
    variantSku,
    variantOwnSku,
    variantQuantity,
    onClick,
    variantPrice,
    variantCompareAtPrice,
    hasError,
  }) => {
    return (
      <ContainerVariantItem hasError={hasError}>
        <CheckboxStyled
          aria-label="checkbox-variant"
          checked={checked}
          disabled={index === 0}
          name={variantId}
          onChange={onChange}
        />
        <VariantItemContainer onClick={onClick}>
          <SubContainerImage>
            <VariantImage
              src={variantImage}
              alt={variantAlt || 'imagen'}
            />
            <SubContainerText>
              <VariantBold>{variantName}</VariantBold>
              <Row>
                {variantOwnSku ? (
                  <VarianTextSku>SKU Propio {variantOwnSku}</VarianTextSku>
                ) : null}
                {variantSku ? (
                  <>
                    <Divider> | </Divider>
                    <VarianTextSku>SKU {variantSku}</VarianTextSku>
                  </>
                ) : null}
              </Row>
            </SubContainerText>
          </SubContainerImage>
          <SubContainerPrice>
            <Row>
              <VarianText>CLP {money(variantPrice)}</VarianText>
              <VarianTextRed>
                CLP {money(variantCompareAtPrice)}
              </VarianTextRed>
            </Row>
            <VarianText>{variantQuantity} disponible (s)</VarianText>
          </SubContainerPrice>
        </VariantItemContainer>
      </ContainerVariantItem>
    )
  }
)

VariantItem.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  variantImage: PropTypes.string,
  variantName: PropTypes.string,
  variantSku: PropTypes.string,
  variantPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variantQuantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variantAlt: PropTypes.string,
  onClick: PropTypes.func,
  variantCompareAtPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  variantOwnSku: PropTypes.string,
  hasError: PropTypes.bool,
}
VariantItem.defaultProps = {
  checked: false,
  variantImage: '',
  variantName: '',
  variantSku: '',
  variantPrice: 0,
  variantQuantity: 0,
  variantAlt: 'image-variant',
  variantCompareAtPrice: 0,
  hasError: false,
}
VariantItem.displayName = 'VariantItem'
export default VariantItem
