import { createAsyncThunk } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'
import productAdminServices from '../../../data/services/productAdminServices'
import { pipeMedia } from '../../adpaters/product/normalizeProduct'

const fetchProductUpdateMedia = createAsyncThunk(
  'product/update/media',
  async ({ media }, { rejectWithValue }) => {
    try {
      if (!media) {
        return rejectWithValue('No existe media para cargar')
      }

      let formMedia = new FormData()
      media?.forEach(image => {
        if (image.file) {
          const { name, file } = image
          formMedia.append(name, file)
        }
      })

      const mediaResponse = await productAdminServices.uploadFiles(
        formMedia
      )

      if (!isArray(mediaResponse)) {
        return rejectWithValue('Error al crear imagen')
      }

      const mediaIds = []
      mediaResponse?.forEach(file => {
        if (file.image) {
          mediaIds.push({ name: file.alt, url: file.image.url })
        } else {
          mediaIds.push({ name: file.alt, url: file.url })
        }
      })

      return {
        mediaIds,
        media: pipeMedia({ media: mediaResponse }),
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchProductUpdateMedia
