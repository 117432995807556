import styled from 'styled-components'
import Adorment from './Adorment'

const AdormentRight = styled(Adorment)`
  padding-right: 14px;
  font-size: 14px;
  line-height: 14px;
`

export default AdormentRight
