import React from 'react'
import AlertMUI from '@material-ui/lab/Alert'
import Text from '../Typography/Text'
import styled from 'styled-components'

const AlertStyled = styled(AlertMUI)`
  align-items: center;
  border-radius: 3px;
  color: ${({ color }) => (color ? color : '#5c5c5c')};
  border-left: 6px solid ${({ color }) => (color ? color : '#5c5c5c')};
  background: #fbfbfb;
`

const TextAlert = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin-left: 5px;
`

const StaticAlert = ({ title, color, key, ...props }) => {
  return (
    <AlertStyled {...props} color={color} icon={<></>}>
      <TextAlert>{title}</TextAlert>
    </AlertStyled>
  )
}

export default StaticAlert
