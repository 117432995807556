import { useMemo, useState, useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import { getBrands } from '../../../domain/features/brandsSlugSeller/getBrands'
import { schema } from '../../../domain/schemas/addProductSchema'
import fetchCategory from '../../../domain/features/category/fetchCategory'
import { resultsCategory } from '../../../domain/features/category/getCategory'
import fetchProductCreate from '../../../domain/features/productCreate/fetchProductCreate'
import { getProductCreate } from '../../../domain/features/productCreate/getProductCreate'
import fetchSellers from '../../../domain/features/sellers/fetchSellers'
import fetchMultipleBrands from '../../../domain/features/brandsSlugSeller/fetchMultipleBrands'

import ProductsTemplate from '../templates/ProductsTemplate'
import Alert, { typeAlert } from '../atomics/Alert/Alert'

const stateCreate = x =>
  new Map([
    ['finished', `Producto creado`],
    ['loading', `Creando producto ${x} `],
    ['error', `Error al cargar ${x}`],
  ])

const AddProduct = () => {
  const navigate = useNavigate()
  const [allFiles, setAllFiles] = useState({})
  const [mediaList, setMediaList] = useState([])
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    register,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  })
  const sellerWatch = useWatch({ name: 'seller', control })
  const { allBrands } = useSelector(getBrands)
  const brandList = useMemo(() => {
    return sellerWatch
      ? allBrands?.filter(
          ({ seller }) =>
            String(sellerWatch) === String(seller?.nameSeller)
        )
      : allBrands
  }, [sellerWatch, allBrands])
  const dispatch = useDispatch()
  const handleAddProduct = useCallback(
    async data => {
      data.seller = sellerWatch
      dispatch(
        fetchProductCreate({ data, media: data?.media || mediaList })
      )
    },
    [dispatch, mediaList, sellerWatch]
  )

  useEffect(() => {
    dispatch(fetchSellers())
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchMultipleBrands())
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchCategory())
  }, [dispatch])

  const categoryList = useSelector(resultsCategory)

  const { status, product } = useSelector(getProductCreate)
  const productName = useWatch({ name: 'productName', control })
  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(
    () => (status !== 'idle' ? setOpenAlert(true) : null),
    [status]
  )
  return (
    <>
      {process.env.NODE_ENV === 'development' ? (
        <DevTool control={control} placement="top-left" />
      ) : null}
      {status === 'finished' && product ? (
        <Navigate to={{ pathname: product.pathname }} state={product} />
      ) : null}
      <ProductsTemplate
        onBack={() => navigate('/productos')}
        onSubmit={handleSubmit(handleAddProduct)}
        allFiles={allFiles}
        setAllFiles={setAllFiles}
        control={control}
        register={register}
        errors={errors}
        isSubmitting={isSubmitting || status === 'loading'}
        categoryList={categoryList}
        brandList={brandList}
        mediaList={mediaList}
        setMediaList={setMediaList}
        setValue={setValue}
        clearErrors={clearErrors}
        setError={setError}
      />
      <Alert
        open={openAlert}
        onClose={handleCloseAlert}
        title={stateCreate(productName).get(status)}
        isLoading={status === 'loading'}
        severity={typeAlert.get(status)}
        isError={status === 'error'}
      />
    </>
  )
}

export default AddProduct
