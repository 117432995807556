import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import {
  createEntityAdapter,
  createSlice,
  current,
} from '@reduxjs/toolkit'
import fetchProducts from './fetchProduct'
import fetchDeleteProduct from './fetchProductDelete'
import fetchUpdateProductStatus from './fetchUpdateProductStatus'
import fetchProductCreate from '../productCreate/fetchProductCreate'
import fetchProductUpdate from '../productUpdate/fetchProductUpdate'

export const productAdapter = createEntityAdapter({
  selectId: ({ id }) => String(id),
})

export const productSlice = createSlice({
  name: 'products',
  initialState: productAdapter.getInitialState({
    status: 'idle',
    isLoading: false,
    id: [],
    entities: {},
    productList: Array(25).fill({ id: '' }),
    statusList: ['Aprobado', 'Deshabilitado', 'Pendiente aprobación'],
    query: '',
    sortKey: 'UPDATED_AT',
    reverse: true,
    pagination: {
      endCursor: null,
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      skip: 0,
    },
  }),
  reducers: {
    addQuery(state, action) {
      state.query = action.payload
    },
    addProductStatusList(state, action) {
      state.statusList = action.payload
    },
    addOneProduct(state, { payload }) {
      if (payload?.includes(undefined)) {
        return
      }
      return productAdapter.addMany(state, payload)
    },
  },
  extraReducers: {
    [fetchProducts.pending]: (state, { meta }) => {
      state.status = 'loading'
      state.isLoading =
        !isEqual(meta.arg.sortBy[0], {
          id: 'updatedAt',
          desc: true,
        }) ||
        !isEmpty(meta.arg?.brands) ||
        !isEmpty(meta.arg?.sellerList) ||
        !isEmpty(meta.arg?.query) ||
        !isEmpty(meta.arg?.category) ||
        !isEmpty(meta.arg?.status) ||
        meta.arg?.limit >= 20 ||
        meta.arg?.skip >= 1
    },
    [fetchProducts.rejected]: (state, { meta }) => {
      state.status = meta.aborted ? 'loading' : 'error'
      state.isLoading = false
    },
    [fetchProducts.fulfilled]: (state, { meta, payload }) => {
      state.isLoading = false
      state.status = 'finished'
      state.pagination = payload?.pagination
      const lastProduct =
        current(state).productList.length > 0
          ? null
          : normalize(current(state), payload)
      state.productList =
        lastProduct?.length > 0 &&
        isEmpty(meta.arg?.brands) &&
        isEmpty(meta.arg?.sellerList) &&
        isEmpty(meta.arg?.query) &&
        isEmpty(meta.arg?.category) &&
        isEmpty(meta.arg?.status) &&
        isEqual(meta.arg.sortBy[0], {
          id: 'updatedAt',
          desc: true,
        })
          ? [...lastProduct, ...payload?.products]
          : payload?.products
      if (payload?.products) {
        lastProduct &&
        isEmpty(meta.arg?.brands) &&
        isEmpty(meta.arg?.sellerList) &&
        isEmpty(meta.arg?.query) &&
        isEmpty(meta.arg?.category) &&
        isEmpty(meta.arg?.status) &&
        isEqual(meta.arg.sortBy[0], {
          id: 'updatedAt',
          desc: true,
        })
          ? productAdapter.setAll(state, [
              ...lastProduct,
              ...payload?.products,
            ])
          : productAdapter.setAll(state, payload?.products)
      }
    },
    [fetchDeleteProduct.pending]: state => {
      state.status = 'loading'
    },
    [fetchDeleteProduct.rejected]: state => {
      state.status = 'error'
    },
    [fetchDeleteProduct.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      productAdapter.removeOne(state, payload)
    },
    [fetchUpdateProductStatus.pending]: state => {
      state.status = 'loading'
    },
    [fetchUpdateProductStatus.rejected]: state => {
      state.status = 'error'
    },
    [fetchUpdateProductStatus.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      productAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload, availableForSale: true },
      })
    },
    [fetchProductUpdate.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      productAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload, availableForSale: true },
      })
      state.productList = current(state).productList.map(product => {
        if (product.id === payload.id) {
          return {
            ...payload,
            to: {
              pathname: `/productos/${product.id.split('Product/')[1]}`,
              state: { ...product },
            },
          }
        }
        return product
      })
    },
    [fetchProductCreate.fulfilled]: (state, { meta, payload }) => {
      state.status = 'finished'
      productAdapter.upsertOne(state, {
        id: payload.id,
        changes: { ...payload, availableForSale: true },
      })
      state.productList = [
        {
          ...payload,
          featuredImage: {
            url: meta.arg?.media[0]?.src,
            alt: payload?.media[0]?.alt,
          },
          to: {
            pathname: `/productos/${payload?.id?.split('Product/')[1]}`,
            state: {
              ...payload,
              featuredImage: {
                url: meta.arg?.media[0]?.src,
                alt: payload?.media[0]?.alt,
              },
            },
          },
        },
        ...current(state).productList,
      ]
    },
  },
})
const getNameSellerByTag = tags =>
  tags.find(tag => tag.includes('seller_')).split('seller_')[1]

const normalize = (currentState, payload) => {
  return payload.products
    .filter(product => product.id !== '')
    .map(product => {
      return {
        ...product,
        count: product.variants.length,
        seller: getNameSellerByTag(product.tags),
        to: {
          pathname: `/productos/${product.id.split('Product/')[1]}`,
          state: {
            ...product,
            count: product.variants.length,
            seller: getNameSellerByTag(product.tags),
          },
        },
      }
    })
}
export const { addQuery, addProductStatusList } = productSlice.actions
const reducer = productSlice.reducer
export default reducer
