import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchRounded from '@material-ui/icons/SearchRounded'
import InputSearch from '../../atomics/Input/InputSearch'

const Search = ({ onClick, ...rest }) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onClick()
    }
  }
  return (
    <InputSearch
      id="input-with-icon-textfield"
      placeholder="Buscar ID pedido Shopify"
      {...rest}
      onKeyDown={handleKeyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment
            style={{ cursor: 'pointer' }}
            onClick={onClick}
            position="start"
          >
            <SearchRounded />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default Search
