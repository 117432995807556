import methods from '../client/httpMethods'
import request from '../client/request'

const liquidationService = ({ get }) => ({
  filter: async ({
    startDate = undefined,
    endDate = undefined,
    skip = undefined,
    limit = 25,
    accountingStatement = undefined,
    sellers = undefined,
    cancelToken = undefined,
  }) => {
    let queryParams = `limit=${limit}`

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (skip) {
      queryParams += `&skip=${skip}`
    }

    try {
      const response = await request(`/liquidation?${queryParams}`, {
        method: get,
        cancelToken,
      })
      return await response.data
    } catch (error) {
      throw error
    }
  },
  export: async ({
    startDate = undefined,
    endDate = undefined,
    accountingStatement = undefined,
    sellers = undefined,
    detailed,
    cancelToken,
  }) => {
    let queryParams = ``

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }
    
    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (detailed) {
      queryParams += `&detailed=true`
    }

    try {
      const response = await request(`/liquidation/download?${queryParams}`, {
        method: get,
        cancelToken,
      })
      return await response.data
    } catch (error) {
      throw error
    }
  },
})

export default liquidationService(methods)
