import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import fetchMultipleBrands from '../../../../domain/features/brandsSlugSeller/fetchMultipleBrands'

import CustomSelectStatus from '../CustomSelectStatus/CustomSelectStatus'

export default function SelectBrand({
  isallfilter,
  isfilterleft,
  onChange,
  value,
  label,
  options,
  status,
}) {
  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchMultipleBrands())
    return () => promise.abort()
  }, [dispatch])

  return (
    <CustomSelectStatus
      label={label}
      defaultStatus={options}
      selectedStatus={value}
      setSelectedStatus={onChange}
      isallfilter={isallfilter}
      isfilterleft={isfilterleft}
      disabled={status === 'loading'}
    />
  )
}

SelectBrand.propTypes = {
  isallfilter: PropTypes.bool,
  isfilterleft: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.any,
  status: PropTypes.string,
}
SelectBrand.defaultProps = {
  label: 'Marca:',
  value: [],
  onChange: x => x,
}
