import React from 'react'
import styled from 'styled-components'
import FieldInputController from '../../molecules/FieldInputController'
import { InputAdornment } from '@material-ui/core'

const Row = styled('div')`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`

const InputAdornmentCell = styled(InputAdornment)`
  border-right: solid 1px #d1d0d0;
  height: 100%;
  padding-right: 10px;
`

const SellerGeneralInformationForm = ({ control, title }) => {
  return (
    <Row>
      <div>
        <FieldInputController
          required
          name="nameSeller"
          label="Nombre empresa"
          placeholder="Nombre de la empresa"
          control={control}
          helperText="Nombre comercial de la empresa, conocido también como ”seller”."
        />
      </div>
      <div>
        <FieldInputController
          required
          name="bussinesName"
          label="Razón social"
          placeholder="Nombre legal de la empresa"
          control={control}
          tooltip="Nombre con que una entidad o sociedad mercantil está registrada legalmente."
          helperText="Nombre legal de la empresa."
        />
      </div>
      <div>
        <FieldInputController
          required
          name="rut"
          label="Rut empresa"
          placeholder="Rut de la empresa"
          control={control}
          helperText=" "
        />
      </div>
      <div>
        <FieldInputController
          required
          name="giro"
          label="Giro"
          placeholder="Giro de la empresa"
          control={control}
          tooltip="Concepto que indica el tipo de actividades productivas y económicas que tiene la empresa."
          helperText=" "
        />
      </div>
      <div>
        <FieldInputController
          required
          name="email"
          label="Email contacto"
          placeholder="Email de la empresa"
          control={control}
          type="email"
          helperText=" "
        />
      </div>
      <div>
        <FieldInputController
          name="phoneNumber"
          label="Teléfono empresa"
          placeholder="9 dígitos"
          required={false}
          control={control}
          helperText=" "
          InputProps={{
            startAdornment: (
              <InputAdornmentCell position="start">+56</InputAdornmentCell>
            ),
          }}
        />
      </div>
    </Row>
  )
}

export default SellerGeneralInformationForm
