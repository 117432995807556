import PropTypes from 'prop-types'
import styled from 'styled-components'
import ButtonFile from '../../atomics/ButtonFile/ButtonFile'
import ToolTip from '../../atomics/Tooltip'

const Column = styled('div')`
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;
`
const Container = styled(Column)`
  margin-bottom: 2%;
  justify-content: space-between;
`
const ErrorCircle = styled('div')`
  padding: 0;
  height: 25px;
  width: 25px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ErrorTooltip = styled(ToolTip)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonFileTooltip = ({ tooltipTitle, ...props }) => (
  <ToolTip title={tooltipTitle} followCursor>
    <div>
      <ButtonFile {...props} />
    </div>
  </ToolTip>
)
ButtonFileTooltip.propTypes = {
  tooltipTitle: PropTypes.string,
}

const FieldFileCustom = ({
  name,
  label,
  tooltipTitle,
  register,
  errors,
  ...props
}) => {
  return (
    <Container key={name}>
      <Column>
        <ButtonFileTooltip
          title={label}
          tooltipTitle={tooltipTitle}
          name={name}
          register={register}
          multiple={false}
          accept="application/pdf"
          {...props}
        />
        {errors && errors[name] ? (
          <ErrorTooltip
            title="Error al adjuntar documento tributario PDF."
            followCursor
          >
            <ErrorCircle>!</ErrorCircle>
          </ErrorTooltip>
        ) : null}
      </Column>
    </Container>
  )
}
FieldFileCustom.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  allFiles: PropTypes.object,
  handleFileChange: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  register: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.object,
}
FieldFileCustom.defaultProps = {
  tooltipTitle: 'Adjuntar archivo (PDF)',
  handleFileChange: () => {},
}
export default FieldFileCustom
