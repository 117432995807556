import map from 'lodash/fp/map'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchSellers from './fetchSellers'
import sortString from '../../utils/sortString'
import sessionPersistentStore from '../../../data/sessionPersistentStore'
import normalizeSeller from './normalizeSeller'
import persistentStore from '../../../data/persistentStore'

const normalizeSellerName = ({ nameSeller }) => nameSeller

const normalizeSlugSeller = ({ slugSeller }) => slugSeller

export const sellerAdapter = createEntityAdapter({
  selectId: seller => seller,
})

export const sellerSlice = createSlice({
  name: 'sellers',
  initialState: sellerAdapter.getInitialState({
    allSellers: persistentStore.isEmptySellersName()
      ? []
      : persistentStore.getSellersName(),
    allSlugSellers: persistentStore.isEmptySellersSlug()
      ? []
      : persistentStore.getSellersSlug(),
    sellers: sessionPersistentStore.isEmptySellerSelect()
      ? []
      : sessionPersistentStore.getSellerSelect(),
    pagination: {
      currentPagination: 0,
      perPagination: 10,
      paginationTotalCount: 0,
    },
    status: 'idle',
    ids: [],
    entities: {},
  }),
  reducers: {
    addSellers: sellerAdapter.setAll,
    addOneSeller(state, { payload }) {
      if (payload?.includes(undefined)) {
        return
      }
      return sellerAdapter.addMany(state, payload)
    },
    removeOneSeller: sellerAdapter.removeOne,
    removeAllSeller: sellerAdapter.removeAll,
  },
  extraReducers: builder => {
    builder.addCase(fetchSellers.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchSellers.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchSellers.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.allSellers = map(normalizeSellerName)(payload).sort(sortString)
      state.allSlugSellers = map(normalizeSlugSeller)(payload).sort(
        sortString
      )
      state.sellers = map(normalizeSeller)(payload)
      persistentStore.setSellersName(state.allSellers)
      persistentStore.setSellersSlug(state.allSlugSellers)
    })
  },
})

const reducer = sellerSlice.reducer
export default reducer
