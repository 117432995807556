import styled from 'styled-components'
import PropTypes from 'prop-types'
import InputController from '../../molecules/InputController'
import FieldWeightController from '../../molecules/FieldWeightController'
import Title from '../../atomics/Typography/Title'
import CardProduct from '../../atomics/CardProduct/CardProduct'
import Label from '../../atomics/Label'
import FieldColorAutocomplete from '../../atomics/FieldColorAutocomplete'
import FieldCountryAutoComplete from '../../molecules/FieldCountryAutoComplete/FieldCountryAutoComplete'

const TitleStyled = styled(Title)`
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 5%;
`

const SubContainer = styled('div')`
  display: flex;
  width: 100%;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`

const SubContainerColumn = styled(SubContainer)`
  flex-direction: column;
  margin-bottom: 4.5%;
`

const SubContainerDimensions = styled(SubContainer)`
  width: 70%;
  justify-content: space-between;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const InputStyled = styled(InputController)`
  width: 90%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const InputWeightStyled = styled(FieldWeightController)`
  width: 90%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const InputDimensions = styled(InputController)`
  width: 28%;
  .MuiInputBase-root {
    padding-left: 0;
  }
  .MuiFormHelperText-contained {
    font-family: Roboto;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }
`

const SubContainerColumnMargined = styled(SubContainerColumn)`
  margin-right: -5%;
`

const ProductDetailsForm = ({ control }) => {
  return (
    <CardProduct>
      <TitleStyled>Detalles del producto</TitleStyled>
      <SubContainerColumn>
        <Label>Medidas del producto </Label>
        <SubContainerDimensions>
          <InputDimensions
            type="number"
            helperText="Alto (mm)"
            control={control}
            name="productHeight"
            placeholder='Ej: "100"'
          />
          <InputDimensions
            type="number"
            helperText="Ancho (mm)"
            control={control}
            name="productWidth"
            placeholder='Ej: "100"'
          />
          <InputDimensions
            type="number"
            helperText="Fondo (mm)"
            control={control}
            name="productDepth"
            placeholder='Ej: "100"'
          />
        </SubContainerDimensions>
      </SubContainerColumn>
      <SubContainer>
        <SubContainerColumn>
          <Label required>Peso</Label>
          <InputWeightStyled
            placeholder="0.00"
            helperText="Ingresa el peso del producto indicando decimales con un punto."
            control={control}
            name="variants[0].weight"
          />
        </SubContainerColumn>
        <SubContainerColumnMargined>
          <Label required>Material</Label>
          <InputStyled
            placeholder="Ej: “Cuarzo 80% | Acrilico 20%”"
            helperText="Ingresa los distintos materiales del producto separados por el símbolo “|”."
            control={control}
            name="productMaterial"
          />
        </SubContainerColumnMargined>
      </SubContainer>
      <SubContainer>
        <SubContainerColumn>
          <Label required>Garantía</Label>
          <InputStyled
            placeholder="Ejemplos: “1 año”, “2 años”, “6 meses”, “De por vida”"
            helperText="Ingresa el período de garantía, que por políticas de Kitchen Center, debe ser mayor a 6 meses."
            control={control}
            name="productWarranty"
          />
        </SubContainerColumn>
        <SubContainerColumnMargined>
          <Label required>Color</Label>
          <FieldColorAutocomplete
            placeholder="Ingresa color"
            helperText="Agrega solo el color principal. Más adelante podrás agregar variantes. En caso que no aplique, se debe colocar 'Sin color'. "
            control={control}
            defaultValue="Sin color"
            name="variants[0].color"
          />
        </SubContainerColumnMargined>
      </SubContainer>
      <SubContainer>
        <SubContainerColumn>
          <Label required>Características especiales</Label>
          <InputStyled
            placeholder="Ej: “Apto para lavavajillas | Producto artesanal”"
            helperText="Colocar distintas características separadas por el símbolo “|”."
            control={control}
            name="specialFeatures"
          />
        </SubContainerColumn>
        <SubContainerColumnMargined>
          <Label required>País de fabricación</Label>
          <FieldCountryAutoComplete
            placeholder="Ingresa país de fabricación"
            control={control}
            name="manufacturedCountry"
          />
        </SubContainerColumnMargined>
      </SubContainer>
    </CardProduct>
  )
}

ProductDetailsForm.propTypes = {
  control: PropTypes.any,
}

export default ProductDetailsForm
