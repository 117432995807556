import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

import { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { addOneStatus } from '../../../domain/features/ui/dispatchedSlice'
import fetchPayouts from '../../../domain/features/payouts/fetchPayouts'
import {
  allPayouts,
  getPayouts,
} from '../../../domain/features/payouts/getPayout'
import {
  addSellers,
  allSellers,
} from '../../../domain/features/sellers/getSellers'
import { getCurrentDate } from '../../../domain/features/ui/rangeOfDaysSlice'
import { addAccountingStatement } from '../../../domain/features/ui/accountingStatementSlice'
import { updateOrders } from '../../../domain/features/orders/getOrder'
import meanBy from '../../../domain/utils/numeric/meanBy'
import {
  liquidateSelector,
  resetLiqudiate,
} from '../../../domain/features/liquidate/liquidateSlice'

import DataTable from '../templates/DataTable'
import ModalLiquidatePayout from '../templates/ModalLiquidatePayout/ModalLiquidatePayout'
import SelectWithChipsSellers from '../organims/SelectWithChipsSellers'

import RangeOfDays from '../organims/RangeOfDays'
import Navigate from '../atomics/Navigate/Navigate'
import CellNumber from '../atomics/Cell/CellNumber'
import fetchLiquidate from '../../../domain/features/liquidate/fetchLiquidate'
import SubmitButton from '../atomics/Button/SubmitButton/SubmitButton'
import ToolTip from '../atomics/Tooltip'
import Title from '../atomics/Typography/Title'
import Tooltip from '../atomics/Tooltip'
import ChipManager from '../organims/ChipManager/ChipManager'
import FilterLiquidationDowload from '../atomics/FilterLiquidationDowload/FilterLiquidationDowload'
import fetchPayoutDownload from '../../../domain/features/payoutListDownload/fetchPayoutsDownload'
import { getPayoutsDownload } from '../../../domain/features/payoutListDownload/getPayoutsDownload'
import Alert from '../atomics/Alert/Alert'
import { useAuth } from './auth/useAuth'

const PaperScroll = styled(Paper)`
  margin-top: 25px;
  @media (max-width: 1100px) {
    overflow-y: auto;
    margin-top: 10px;
  }
`
const stateDownload = () =>
  new Map([
    [
      'loading',
      'Descargando payouts, esto podria demorar varios minutos. ',
    ],
    ['error', 'Error al descargar payouts.'],
  ])

const ButtonLiquidate = props => {
  const auth = useAuth()
  const isInvalid =
    auth?.currentUser?.role?.toLowerCase() === 'viewer' ||
    auth?.currentUser?.role?.toLowerCase() === 'operations'

  return (
    <SubmitButton
      variant="contained"
      color="primary"
      data-testid="Footer__Button--liquidar"
      {...props}
      disabled={props.disabled || isInvalid}
    >
      {isInvalid ? (
        <Tooltip
          arrow={false}
          title={
            isInvalid
              ? `Rol ${String(
                  auth?.currentUser?.role
                ).toUpperCase()} no puede liquidar`
              : ''
          }
          placement="bottom"
        >
          <span>Liquidar</span>
        </Tooltip>
      ) : (
        'Liquidar'
      )}
    </SubmitButton>
  )
}

const Payouts = () => {
  const dispatch = useDispatch()

  const date = useSelector(getCurrentDate)
  const sellerList = useSelector(allSellers)

  const { status } = useSelector(liquidateSelector)
  const { status: statusPayoutList } = useSelector(getPayouts)

  useEffect(() => {
    const promise = dispatch(
      fetchPayouts({
        endDate: date[1],
        sellerList,
      })
    )
    return () => {
      promise.abort()
    }
  }, [date, dispatch, sellerList])

  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => setOpen(true), [setOpen])
  const handleClose = useCallback(() => {
    setOpen(false)
    dispatch(resetLiqudiate())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            <ToolTip title="Ver todos">
              <IconButton
                aria-label="expand all row"
                size="small"
                data-testid="expander-all"
              >
                {isAllRowsExpanded ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </IconButton>
            </ToolTip>
          </span>
        ),
        Cell: ({ row }) =>
          row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}>
              <ToolTip title="Ver marcas">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid={`expander-cell-${row?.id}`}
                >
                  {row.isExpanded ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </ToolTip>
            </span>
          ) : null,
      },
      {
        Header: 'Seller',
        accessor: 'sellerName',
        Footer: () => {
          return (
            <Typography variant="subtitle1" color="textPrimary">
              Totales
            </Typography>
          )
        },
      },
      {
        Header: 'Venta',
        accessor: 'totalSale',
        Cell: cell => <CellNumber number={cell.value} />,
        Footer: ({ rows }) => {
          const total =
            useMemo(() => meanBy(rows, 'totalSale'), [rows]) || 0
          return (
            <Typography
              variant="subtitle1"
              color="textPrimary"
              data-testid="total-venta"
            >
              {total.toLocaleString('es-CL')}
            </Typography>
          )
        },
      },
      {
        Header: 'Venta Despachada',
        accessor: 'dispatchedSale',
        Cell: cell => <CellNumber number={cell.value} />,
        Footer: ({ rows }) => {
          const total =
            useMemo(() => meanBy(rows, 'dispatchedSale'), [rows]) || 0
          return (
            <Typography
              variant="subtitle1"
              color="textPrimary"
              data-testid="total-venta-despachada"
            >
              {total.toLocaleString('es-CL')}
            </Typography>
          )
        },
      },
      {
        Header: 'Comisión',
        accessor: 'commission',
        Cell: cell => <CellNumber number={cell.value} />,
        Footer: ({ rows }) => {
          const total =
            useMemo(() => meanBy(rows, 'commission'), [rows]) || 0
          return (
            <Typography
              variant="subtitle1"
              color="textPrimary"
              data-testid="total-comision"
            >
              {total.toLocaleString('es-CL')}
            </Typography>
          )
        },
      },
      {
        Header: 'Otros descuentos',
        accessor: 'otherDiscount',
        Cell: cell => <CellNumber number={cell.value} />,
        Footer: ({ rows }) => {
          const total =
            useMemo(() => meanBy(rows, 'otherDiscount'), [rows]) || 0

          return (
            <Typography
              variant="subtitle1"
              color="textPrimary"
              size="large"
              data-testid="total-otherDiscount"
            >
              {total.toLocaleString('es-CL')}
            </Typography>
          )
        },
      },
      {
        Header: 'Monto a facturar',
        accessor: 'payoutAmmount',
        Cell: cell => <CellNumber number={cell.value} />,
        Footer: ({ rows }) => {
          const total =
            useMemo(() => meanBy(rows, 'payoutAmmount'), [rows]) || 0

          return (
            <Typography
              variant="subtitle1"
              color="textPrimary"
              size="large"
              data-testid="total-payout-ammount"
            >
              {total.toLocaleString('es-CL')}
            </Typography>
          )
        },
      },
      {
        Header: 'Payout',
        accessor: 'payout',
        Cell: cell => <CellNumber number={cell.value} />,
        Footer: ({ rows }) => {
          const total = useMemo(() => meanBy(rows, 'payout'), [rows]) || 0

          return (
            <Typography
              variant="subtitle1"
              color="textPrimary"
              size="large"
              data-testid="total-payout"
            >
              {total.toLocaleString('es-CL')}
            </Typography>
          )
        },
      },
      {
        Header: 'Detalle',
        accessor: 'detail',
        Cell: (cell, i) => {
          const sellerName = cell.value?.sellerName
          const allStatus = cell.value?.status
          return isEmpty(cell.value) ? null : (
            <div
              onClick={() => {
                dispatch(addSellers([]))
                dispatch(addSellers([sellerName]))
                dispatch(addOneStatus(allStatus))
                dispatch(updateOrders([...cell.value?.orders]))
                dispatch(addAccountingStatement('No Liquidado'))
              }}
            >
              <Navigate
                to={{
                  key: String(cell.row.id + i),
                  pathname: '/pedidos',
                  search: `?payoutId=payout-valod&seller=${encodeURIComponent(
                    sellerName
                  )}&status=${encodeURIComponent(
                    allStatus
                  )}&accountingStatement=No Liquidado`,
                  state: isArray(cell.value)
                    ? [...cell.value?.orders]
                    : [],
                }}
              >
                Ver pedido
              </Navigate>
            </div>
          )
        },
        Footer: ({ rows }) => {
          return (
            <>
              <ButtonLiquidate
                disabled={status === 'loading' || rows.length === 0}
                isLoading={status === 'loading'}
                onClick={handleOpen}
              />
            </>
          )
        },
      },
    ],
    [dispatch, handleOpen, status]
  )

  const data = useSelector(allPayouts)

  const rows = useMemo(
    () => (isEmpty(data) ? Array(15).fill({ sellerName: '' }) : data),
    [data]
  )

  // Download
  const handleDownload = useCallback(
    ({ detailed }) => {
      dispatch(
        fetchPayoutDownload({
          startDate: date[0],
          endDate: date[1],
          sellerList,
          detailed,
        })
      )
    },
    [date, dispatch, sellerList]
  )
  const { status: statusDownload } = useSelector(getPayoutsDownload)

  return (
    <>
      <ModalLiquidatePayout
        open={open}
        onClose={handleClose}
        onSubmit={() => {
          dispatch(
            fetchLiquidate({
              startDate: date[0],
              endDate: date[1],
              sellerList: rows.map(({ sellerName }) => sellerName),
            })
          )
        }}
        payoutsViews={rows}
      />
      <Box mt="58px" mb="35px">
        <Title>Payouts</Title>
      </Box>
      <Paper elevation={1}>
        <FilterLiquidationDowload
          disabled={statusPayoutList === 'loading'}
          enabledDownload={
            date[0] || date[1] || sellerList.length > 0 ? true : false
          }
          downloadOptions={[
            {
              label: 'Listado payouts',
              onClick: () => handleDownload({ detailed: false }),
            },
            {
              label: 'Listado payouts con detalle de pedidos',
              onClick: () => handleDownload({ detailed: true }),
            },
          ]}
        >
          <RangeOfDays />
          <SelectWithChipsSellers isallfilter={false} />
        </FilterLiquidationDowload>
        <ChipManager date={date} seller={sellerList} />
        <PaperScroll elevation={2}>
          <DataTable
            isLoading={statusPayoutList === 'loading' || isEmpty(data)}
            isError={statusPayoutList === 'error'}
            columns={columns}
            data={rows}
            expand
          />
        </PaperScroll>
      </Paper>

      <Alert
        open={statusDownload === 'loading'}
        title={stateDownload().get(statusDownload)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        isLoading={
          statusDownload === 'loading' || statusDownload === 'finished'
        }
        isMultipleLoading={
          statusDownload === 'loading' || statusDownload === 'finished'
        }
        severity={'info'}
        isError={statusDownload === 'error'}
      />
    </>
  )
}
export default Payouts
