import request from '../../../data/client/request'

const downloadBill = (endpoint, fileName) =>
  request.get(endpoint, { responseType: 'blob' }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName || 'file'}.pdf`) //or any other extension
    document.body.appendChild(link)
    link.click()
  })

export default downloadBill
