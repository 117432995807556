import { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components'
import { ListItem, ListItemText } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ControllerAccordion from '../ControllerAccordion/ControllerAccordion'
import Navigate from '../../atomics/Navigate/Navigate'

const IconExpand = styled(animated(ArrowDropDownIcon))``

const DrawerNavigate = styled(Navigate)`
  text-decoration: none;
  text-decoration-line: none;
  padding: 0;
  margin: 0;
`
const ListItemStyle = styled(props => <ListItem {...props} />)`
  width: 100%;
  height: 35px;
  text-align: left;
  padding-left: 26px;
  border: 0;
  border-radius: 0px;
  background-color: ${({ active, theme }) =>
    active === 'true'
      ? theme.palette.type === 'dark'
        ? '#202223'
        : '#F0F0F0'
      : 'transparent'};
  border-left: ${({ active, color }) =>
    active === 'true' ? `3px solid ${color}` : 0};
  .MuiSvgIcon-root {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#202223' : '#F5F5F5'};
  }
`
const ListItemChildStyle = styled(props => <ListItemStyle {...props} />)`
  background-color: ${({ active, theme }) =>
    active === 'true'
      ? theme.palette.type === 'dark'
        ? '#202223'
        : '#F5F5F5'
      : 'transparent'};
  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#202223' : '#F5F5F5'};
  }
`

const DrawerItemText = styled(props => <ListItemText {...props} />)`
  .MuiTypography-body1 {
    font-weight: bold;
    color: ${({ active, color }) => (active === 'true' ? color : 'inset')};
  }
`
const DrawerItemChildText = styled(props => <DrawerItemText {...props} />)`
  padding-left: 10px;
  .MuiTypography-body1 {
    font-weight: ${({ active }) =>
      active === 'true' ? 'bold' : 'normal'};
  }
`

const SubMenuContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const DrawerItem = ({ reset, text, route, color, children }) => {
  const { pathname } = useLocation()
  const allRoutes = (children || []).map(child => child.route)
  const [expanded, setExpanded] = useState(allRoutes.includes(pathname))

  const changeExpanded = () => {
    setExpanded(!expanded)
  }

  const animation = useSpring({
    to: {
      transform: !expanded ? 'rotate(180deg)' : 'rotate(360deg)',
    },
    native: true,
  })

  if ((children || []).length > 0) {
    return (
      <ControllerAccordion
        open={true}
        expanded={expanded}
        key={`${text}-${route}-controller`}
        changeExpanded={changeExpanded}
        title={() => (
          <ListItemStyle
            key={`${text}-${route}-item`}
            active={String(allRoutes.includes(pathname))}
            color={color}
          >
            <DrawerItemText
              primary={text}
              active={String(allRoutes.includes(pathname))}
              color={color}
            />
            <IconExpand style={animation} />
          </ListItemStyle>
        )}
      >
        <SubMenuContainer>
          {children &&
            children.map((child, index) => {
              return (
                <DrawerNavigate
                  to={{
                    pathname: child.route,
                    state: [],
                    search: '',
                  }}
                  key={`${child.text}-${index}`}
                >
                  <ListItemChildStyle
                    onClick={reset}
                    active={String(pathname === child.route)}
                  >
                    <DrawerItemChildText
                      primary={child.text}
                      active={String(pathname === child.route)}
                    />
                  </ListItemChildStyle>
                </DrawerNavigate>
              )
            })}
        </SubMenuContainer>
      </ControllerAccordion>
    )
  }

  return (
    <DrawerNavigate
      to={{
        pathname: route,
        state: [],
        search: '',
      }}
      key={text}
    >
      <ListItemStyle
        key={text}
        onClick={reset}
        active={String(pathname === route)}
        color={color}
      >
        <DrawerItemText
          primary={text}
          active={String(pathname === route)}
          color={color}
        />
      </ListItemStyle>
    </DrawerNavigate>
  )
}

export default DrawerItem
