import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'

const handleFetchProductExportAllById = async (
  { bulkId },
  { rejectWithValue }
) => {
  try {
    const response = await productAdminServices.exportAllById(bulkId)

    if (!response.ok) {
      return rejectWithValue(
        `Operación bulk ${bulkId} falló. ${response?.message}`
      )
    }

    if (response.ok) {
      const resJson = await response.json()
      const blob = resJson.file

      if (blob) {
        const newBlob = new Blob([new Uint8Array(resJson.file.data)])

        const blobUrl = window.URL.createObjectURL(newBlob)

        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', `productos-${bulkId}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(newBlob)
      }

      return resJson
    } else {
      throw new Error('file not found')
    }
  } catch (error) {
    return rejectWithValue(error)
  }
}
const fetchProductExportAllById = createAsyncThunk(
  'product/exportById',
  handleFetchProductExportAllById
)

export default fetchProductExportAllById
