import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import styled, { css } from 'styled-components'

import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import {
  allLiquidations,
  getLiquidation,
} from '../../../domain/features/liquidations/getLiquidations'
import {
  addSellers,
  allSellers,
} from '../../../domain/features/sellers/getSellers'
import { getCurrentDate } from '../../../domain/features/ui/rangeOfDaysSlice'
import fetchLiquidations from '../../../domain/features/liquidations/fetchLiquidations'
import {
  addAccountingStatement,
  allAccountingStatement,
} from '../../../domain/features/ui/accountingStatementSlice'
import { updateOrders } from '../../../domain/features/orders/getOrder'
import { removeAllStatus } from '../../../domain/features/ui/dispatchedSlice'
import downloadBill from '../../../domain/adpaters/download/downloadBill'
import downloadExcel from '../../../domain/adpaters/download/downloadExcel'
import downloadPdf from '../../../domain/adpaters/download/downloadPdf'

import DataTable from '../templates/DataTable'
import SelectWithChipsSellers from '../organims/SelectWithChipsSellers'
import Title from '../atomics/Typography/Title'
import RangeOfDays from '../organims/RangeOfDays'
import SelectAccountingStatement from '../organims/SelectAccountingStatement'
import Scroll from '../atomics/ScrollTable'
import CellNumber from '../atomics/Cell/CellNumber'
import Navigate from '../atomics/Navigate/Navigate'
import arrow from '../../assets/icon/arrowDown.png'
import IconExcel from '../atomics/Icon/IconExcel'
import IconPdf from '../atomics/Icon/IconPdf'
import Row from '../atomics/Styles/Row'
import ChipManager from '../organims/ChipManager/ChipManager'
import FilterLiquidationDowload from '../atomics/FilterLiquidationDowload/FilterLiquidationDowload'
import fetchLiquidationDownload from '../../../domain/features/liquidationListDownload/fetchLiquidationsDownload'
import { getLiquidationsDownload } from '../../../domain/features/liquidationListDownload/getLiquidationsDownload'
import Alert from '../atomics/Alert/Alert'

const ButtonDownload = styled(Navigate)`
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `};
`

const RowCenter = styled(Row)`
  align-items: center;
  justify-content: center;
`

const IconButtonStyle = styled(IconButton)`
  padding: 8px;
`
const IconButtonStyleLeft = styled(IconButton)`
  padding: 5px;
`

const stateDownload = () =>
  new Map([
    [
      'loading',
      'Descargando liquidaciones, esto podria demorar varios minutos. ',
    ],
    ['error', 'Error al descargar liquidaciones.'],
  ])

const Liquidations = () => {
  const dispatch = useDispatch()

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'date',
      },
      {
        Header: 'Seller',
        accessor: 'sellerName',
      },
      {
        Header: 'Monto a facturar',
        accessor: 'commission',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Payout',
        accessor: 'payout',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Estado contable',
        accessor: 'accountingStatement',
      },
      {
        Header: 'Comprobante liquidación',
        accessor: 'idLiquidate',
      },
      {
        Header: 'Comprobante facturación',
        accessor: 'idBill',
      },
      {
        Header: 'Factura',
        accessor: 'bill',
        Cell: ({ row }) => {
          const pdfBill = row.values?.bill
          return isNil(pdfBill) ? null : (
            <ButtonDownload
              disabled={isNil(pdfBill)}
              onClick={() =>
                downloadBill(
                  pdfBill,
                  `Factura-${row.values.idBill}-${row.values.sellerName}-${row.values.date}`
                )
              }
            >
              Descargar <img src={arrow} alt="download" />
            </ButtonDownload>
          )
        },
      },
      {
        Header: 'Comprobante pago',
        accessor: 'idPaid',
      },
      {
        Header: 'Reporte',
        accessor: 'report',
        Cell: ({ row }) => {
          const { excelReport, pdfReport } = row.values?.report
          const accountingStatement = row.values?.accountingStatement
          const whiteListAccountingStatement = [
            'Contabilizado',
            'Facturado - No Pagado',
            'Pagado',
          ]
          const isView = whiteListAccountingStatement.some(
            a => a === String(accountingStatement)
          )
          return !isView ? null : (
            <RowCenter>
              <ButtonDownload
                onClick={() =>
                  downloadPdf(
                    pdfReport,
                    `Reporte-${row.values.sellerName}-${String(
                      row.values.date
                    ).replaceAll('/', '-')}`
                  )
                }
              >
                <IconButtonStyle>
                  <IconPdf />
                </IconButtonStyle>
              </ButtonDownload>
              <ButtonDownload
                onClick={() =>
                  downloadExcel(
                    excelReport,
                    `Reporte-${row.values.sellerName}-${String(
                      row.values.date
                    ).replaceAll('/', '-')}`
                  )
                }
              >
                <IconButtonStyleLeft>
                  <IconExcel />
                </IconButtonStyleLeft>
              </ButtonDownload>
            </RowCenter>
          )
        },
      },
      {
        Header: 'Detalle',
        accessor: 'detail',
        Cell: (cell, i) => {
          const sellerName = cell.value.sellerName
          const payoutId = cell.value.payoutId
          return (
            <div
              onClick={() => {
                dispatch(addSellers([]))
                dispatch(addSellers([sellerName]))
                dispatch(addAccountingStatement(undefined))
                dispatch(removeAllStatus())
                dispatch(updateOrders([...cell.value.orders]))
              }}
            >
              <Navigate
                to={{
                  key: String(cell.row.id + i),
                  pathname: '/pedidos',
                  search: `?payoutId=${payoutId}&seller=${encodeURIComponent(
                    sellerName
                  )}`,
                  state: isArray(cell.value?.orders)
                    ? [...cell.value?.orders]
                    : [],
                }}
              >
                Ver pedidos
              </Navigate>
            </div>
          )
        },
      },
    ],
    [dispatch]
  )

  const date = useSelector(getCurrentDate)

  const accountingStatementList = useSelector(allAccountingStatement)
  const sellerList = useSelector(allSellers)

  const [pageCurrentIndex, setPage] = React.useState(0)
  const [perPage, setPerPage] = React.useState(25)

  useEffect(() => {
    const promise = dispatch(
      fetchLiquidations({
        startDate: date[0],
        endDate: date[1],
        accountingStatement: accountingStatementList,
        sellerList,
        skip: pageCurrentIndex,
        limit: perPage,
      })
    )
    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    date,
    accountingStatementList,
    sellerList,
    pageCurrentIndex,
    perPage,
  ])

  const { pagination, status } = useSelector(getLiquidation)
  const data = useSelector(allLiquidations)

  const rows = React.useMemo(
    () => (status === 'loading' ? Array(25).fill({}) : data),
    [status, data]
  )

  // Download
  const handleDownload = useCallback(
    ({ detailed }) => {
      dispatch(
        fetchLiquidationDownload({
          startDate: date[0],
          endDate: date[1],
          accountingStatement: accountingStatementList,
          sellerList,
          detailed,
        })
      )
    },
    [accountingStatementList, date, dispatch, sellerList]
  )
  const { status: statusDownload } = useSelector(getLiquidationsDownload)

  return (
    <>
      <Box mt="58px" mb="35px">
        <Title>Liquidaciones</Title>
      </Box>
      <Paper elevation={1}>
        <FilterLiquidationDowload
          enabledDownload={
            date[0] ||
            date[1] ||
            sellerList.length > 0 ||
            accountingStatementList.length > 0
              ? true
              : false
          }
          downloadOptions={[
            {
              label: 'Listado liquidaciones',
              onClick: () => handleDownload({ detailed: false }),
            },
            {
              label: 'Listado liquidaciones con detalle de pedidos',
              onClick: () => handleDownload({ detailed: true }),
            },
          ]}
        >
          <RangeOfDays />
          <SelectWithChipsSellers isallfilter={true} />
          <SelectAccountingStatement isallfilter={false} />
        </FilterLiquidationDowload>
        <ChipManager
          date={date}
          accountingStatement={accountingStatementList}
          seller={sellerList}
        />
        <Paper elevation={2}>
          <Scroll>
            <DataTable
              isLoading={status === 'loading' || isEmpty(data)}
              isError={status === 'error'}
              columns={columns}
              data={rows}
              testId="liquidations"
              expand={false}
              withPage={true}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              pageCurrentIndex={pageCurrentIndex || 0}
              totalPage={pagination?.paginationTotalCount || 0}
            />
          </Scroll>
        </Paper>
      </Paper>

      <Alert
        key="product-create"
        open={statusDownload === 'loading'}
        title={stateDownload().get(statusDownload)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        isLoading={
          statusDownload === 'loading' || statusDownload === 'finished'
        }
        isMultipleLoading={
          statusDownload === 'loading' || statusDownload === 'finished'
        }
        severity={'info'}
        isError={statusDownload === 'error'}
      />
    </>
  )
}
export default Liquidations
