import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from '@react-spring/web'
import { ImageContainer, ImageStyled, EmojiBox } from './styles'

const ImageWithEmoji = ({ webpSrc, svgSrc, alt, emoji, ...props }) => {
  const [currentSrc, setCurrentSrc] = useState(webpSrc)
  const [loading, setLoading] = useState(true)

  const handleSvgLoad = useCallback(() => {
    setCurrentSrc(svgSrc)
    setLoading(false)
  }, [svgSrc])

  const handleWebpLoad = useCallback(() => {
    setLoading(false)
  }, [])

  const springProps = useSpring({
    opacity: 1,
    transform: 'scale(1)',
    from: { opacity: 0, transform: 'scale(0.5)' },
    config: { ...config.wobbly },
  })

  const emojiAnimation = useSpring({
    from: { opacity: 0.5, transform: 'scale(0.4)' },
    to: { opacity: 1, transform: 'scale(1.2)' },

    config: {
      ...config.wobbly,
      friction: 20,
    },
    reset: true,
  })

  return (
    <ImageContainer>
      <ImageStyled
        src={currentSrc}
        alt={alt}
        style={springProps}
        onLoad={currentSrc === webpSrc ? handleWebpLoad : handleSvgLoad}
        loading={String(loading)}
        {...props}
      />
      <animated.div style={emojiAnimation}>
        <EmojiBox>{emoji}</EmojiBox>
      </animated.div>
    </ImageContainer>
  )
}

ImageWithEmoji.propTypes = {
  webpSrc: PropTypes.string,
  svgSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired,
}
ImageWithEmoji.defaultProps = {
  webpSrc: '',
  svgSrc:
    'https://www.kitchencenter.cl/cdn/shop/files/kitchencenter_logo.svg?v=1676480309&width=180',
  alt: 'alt',
  emoji: '🔒',
}
const areEqual = (prevProps, nextProps) => {
  return prevProps.emoji === nextProps.emoji
}

export default React.memo(ImageWithEmoji, areEqual)
