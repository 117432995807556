import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

import DrawerMui from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'

import Paper from '@material-ui/core/Paper'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import {
  onChangeDrawer,
  isOpenDrawer,
} from '../../../../domain/features/ui/drawerSlice'
import resetFilters from '../../../../domain/features/ui/resetFilters'

import back from '../../../assets/icon/back.svg'

import DrawerItem from '../../molecules/DrawerItem/DrawerItem'
import imageweb from '../../../assets/logoWeb.webp'

export const drawerWidth = 235
export const drawerMinWith = 60

const DrawerContainer = styled(DrawerMui)`
  .MuiDrawer-paperAnchorDockedLeft {
    overflow-x: hidden;
    transition: width 0.4s;
    width: ${({ open }) =>
      open ? `${drawerWidth}px` : `${drawerMinWith}px`};
  }
  .MuiDrawer-paper {
    overflow: hidden;
    background-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#313234' : '#FFFFFF'};
  }
`

const DrawerHeader = styled(Paper)`
  width: ${drawerMinWith}px;
  border: 0px;
  border-radius: 0px;
  background-color: #000000;
  ${({ theme }) => theme.mixins.toolbar}
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: 0px;
  border-right-width: 0px;
  border-color: #000000;
  @media (min-width: 700px) {
    min-height: 58px;
  }
`

const DrawerFooter = styled(DrawerHeader)`
  justify-content: center;
`

const DrawerList = styled(List)`
  flex: 1;
  border-radius: 0;
  border-left: ${drawerMinWith}px solid #000000;

  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#313234' : '#FFFFFF'};

  .Mui-expanded {
    margin: 0px !important;
  }
`
const Logo = styled('img')`
  position: absolute;
  top: 8px;
  left: 8px;
  border: 1px solid #c1c1c1;
  background-color: ${({ theme }) => theme.palette.info.dark};
`

const TitleAPP = styled(animated('h1'))`
  font-size: 20px;
  line-height: 24px;
  font-style: light;
  font-weight: normal;
  margin-left: 10px;
  position: fixed;
  top: 1px;
  color: ${({ isopen, theme }) =>
    isopen === 'true' ? theme.palette.info.dark : '#F6F6F7'};
`
const SubTitleAPP = styled(animated('h2'))`
  font-size: 12px;
  line-height: 16px;
  font-style: light;
  font-weight: normal;
  margin-left: 10px;
  position: fixed;
  top: 26px;
  color: ${({ isopen, theme }) =>
    isopen === 'true' ? theme.palette.info.light : '#c1c1c1'};
`

const Back = styled(animated('img'))`
  width: 25px;
  height: 25px;
`

const menuApp = [
  { text: 'Pedidos', route: '/pedidos', color: '#008060' },
  { text: 'Productos', route: '/productos', color: '#008060' },
  { text: 'Sellers', route: '/sellers', color: '#65B8D6' },
  {
    text: 'Finanzas',
    color: '#48BD9E',
    children: [
      { text: 'Liquidaciones', route: '/liquidaciones' },
      { text: 'Payouts', route: '/' },
    ],
  },
  {
    text: 'Analítica',
    color: '#008060',
    children: [
      { text: 'Post ventas', route: '/operaciones', color: '#008060' },
    ],
  },
]

const Drawer = () => {
  const dispatch = useDispatch()
  const open = useSelector(isOpenDrawer)
  const toggleDrawer = newOpen => () => {
    return dispatch(onChangeDrawer(newOpen))
  }

  const reset = useCallback(() => {
    dispatch(resetFilters())
  }, [dispatch])

  const isMobile = useMediaQuery('(max-width:1100px)')

  const animation = useSpring({
    to: {
      transform: !open ? 'rotate(180deg)' : 'rotate(360deg)',
    },
    native: true,
  })
  return (
    <>
      <DrawerContainer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <DrawerHeader elevation={0}>
          <Logo
            src={
              imageweb ||
              'https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927'
            }
            alt="logo"
            width="44px"
            height="44px"
          />
        </DrawerHeader>
        <DrawerList role="list">
          <TitleAPP isopen={String(open)}>BackOffice</TitleAPP>
          <SubTitleAPP isopen={String(open)}>Marketplace</SubTitleAPP>

          {menuApp?.map((props, index) => {
            return (
              <DrawerItem
                role="listitem"
                key={`${props.text}-${index}`}
                {...props}
                reset={reset}
              />
            )
          })}
        </DrawerList>

        <DrawerFooter elevation={0}>
          <IconButton onClick={() => dispatch(onChangeDrawer(!open))}>
            <Back src={back} style={animation} alt="open-close" />
          </IconButton>
        </DrawerFooter>
      </DrawerContainer>
    </>
  )
}

export default Drawer
