import persistentStore from '../../data/persistentStore'
import { auth } from '../components/pages/auth/firebaseConfig'

import useInterval from './useInterval'

const useRefreshToken = () => {
  const tokenDuration = process.env.REACT_APP_REFRESH_TOKEN
    ? parseInt(process.env.REACT_APP_REFRESH_TOKEN, 10)
    : 900000 // 15 minutos en milisegundos
  useInterval(async () => {
    const token = await auth.currentUser.getIdToken(true)
    persistentStore.setToken(token)
  }, tokenDuration)
}

export default useRefreshToken
