import React from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableFooteMUI from '@material-ui/core/TableFooter'

import Cell from '../../atomics/Cell'
import CellBase from '../../atomics/Cell/CellBase'
import CellHeader from '../../atomics/Cell/CellHeader'
import Navigate from '../../atomics/Navigate'
import CellStatus from '../../atomics/Cell/CellStatus'
import TableFooter from '../../organims/TableFooter/TableFooter'
import ErrorSearch from '../../molecules/SearchMessages/ErrorSearch/ErrorSearch'
import Tooltip from '../../atomics/Tooltip/Tooltip'

const Table = styled(MaUTable)`
  cursor: ${({ isloading }) => (isloading === 'true' ? 'wait' : 'auto')};
`

const TableRowStyled = styled(TableRow)`
  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#313234' : '#f4f5f4'};
  }
`
const TableNavigateStyled = styled(Navigate)`
  display: contents;
  text-decoration: none;
  text-decoration-line: none;
`
const TableNavigate = ({ to, state, children }) =>
  to ? (
    <TableNavigateStyled to={to} state={state}>
      {children}
    </TableNavigateStyled>
  ) : (
    children
  )

const TableTooltip = ({ tooltip, children }) =>
  tooltip ? (
    <Tooltip title={tooltip} followCursor>
      {children}
    </Tooltip>
  ) : (
    children
  )

const DataTable = ({
  columns,
  data,
  testId,
  expand,
  pageCurrentIndex,
  setPage,
  perPage,
  setPerPage,
  totalPage,
  renderFooter,
  onSort,
  isError = false,
  isLoading = true,
  withPage = false,
  manualSortBy = false,
}) => {
  const navigate = useNavigate()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows: rowList,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      pageCount: totalPage,
      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: pageCurrentIndex,
          }),
          [state]
        )
      },
      initialState: {
        pageIndex: pageCurrentIndex,
        pageSize: perPage,
        sortBy: [{ id: 'updatedAt', desc: true }],
      },
      manualPagination: withPage,
      manualSortBy,
    },
    useSortBy,
    useExpanded,
    usePagination
  )

  const rows = withPage ? page : rowList

  React.useEffect(() => {
    if (!onSort) {
      return
    }
    onSort(sortBy)
  }, [onSort, sortBy])

  return isError ? (
    <ErrorSearch />
  ) : (
    <>
      <Table
        {...getTableProps()}
        data-testid={testId}
        stickyHeader
        isloading={String(isLoading)}
      >
        <TableHead>
          {headerGroups.map((headerGroup, index) => (
            <TableRow
              key={`TableCell__Header--${index}-${headerGroup.id}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <CellHeader
                  data-testid={`TableCell__Header--${index}-${column.id}`}
                  padding={index === 0 && expand ? 'checkbox' : 'normal'}
                  key={`TableCell__Header--${index}-${column.id}`}
                  {...column.getHeaderProps([
                    {
                      ...(!expand
                        ? {
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          }
                        : {}),
                    },
                    column.getSortByToggleProps(),
                  ])}
                  sortDirection={column.isSortedDesc ? 'desc' : 'asc'}
                >
                  <TableSortLabel
                    hideSortIcon={index === 0}
                    active={column.isSorted && index !== 0}
                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                  >
                    {column.render('Header')}
                  </TableSortLabel>
                </CellHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows?.map((row, index) => {
            prepareRow(row)
            return (
              <TableNavigate
                key={`row--${index}-${row.id}`}
                to={row.original?.to}
                state={row.original?.to?.state}
              >
                <TableTooltip tooltip={row.original?.to?.tooltip}>
                  <TableRowStyled {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      if (
                        (cell.column.Header === 'Estado despacho' &&
                          testId === 'pedidos') ||
                        cell.column.Header === 'Estado' ||
                        (cell.column.Header === 'Estado contable' &&
                          testId === 'pedidos') ||
                        (cell.column.id === 'type' &&
                          testId === 'operation')
                      ) {
                        return (
                          <CellStatus
                            key={`sub-${index}-${cell.row.id}-${String(
                              cell.value
                            )}`}
                            {...cell.getCellProps()}
                            text={cell.value}
                          />
                        )
                      }
                      const isExpanded = String(row.id).includes('.')
                      if (
                        row.original.productDetail &&
                        cell.column.Header !== 'Acción' &&
                        cell.column.Header !== 'Vista previa'
                      ) {
                        return (
                          <Cell
                            data-testid={`TableCell__Product--${index}-${row.id}`}
                            key={`sub-${index}-${cell.row.id}`}
                            {...cell.getCellProps()}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              navigate(
                                cell.row.original.productDetail,
                                row.original
                              )
                            }
                          >
                            {cell.render('Cell')}
                          </Cell>
                        )
                      }
                      return isExpanded ? (
                        <Cell
                          data-testid={`TableCell__Expanded--${index}-${row.id}`}
                          padding="normal"
                          key={`TableCell__Body--${index}-${row.id}`}
                          {...cell.getCellProps()}
                        >
                          {isLoading ? <Skeleton /> : cell.render('Cell')}
                        </Cell>
                      ) : (
                        <CellBase
                          data-testid={`TableCell__Body--${index}-${row.id}`}
                          padding={
                            index === 0 && expand ? 'checkbox' : 'normal'
                          }
                          key={`TableCell__Body--${index}-${row.id}`}
                          {...cell.getCellProps()}
                        >
                          {isLoading ? <Skeleton /> : cell.render('Cell')}
                        </CellBase>
                      )
                    })}
                  </TableRowStyled>
                </TableTooltip>
              </TableNavigate>
            )
          })}
        </TableBody>
        {isEmpty(footerGroups) ? null : (
          <TableFooteMUI>
            {footerGroups.map((group, index) => (
              <TableRow
                {...group.getFooterGroupProps()}
                key={`${group.id}-Footer--${index}-${group.id}`}
              >
                {group.headers.map((column, index) => (
                  <CellBase
                    key={`${column.id}-Footer--${index}-${group.id}`}
                    data-testid={`TableCell__Footer--${index}-${group.id}`}
                    {...column.getFooterProps()}
                  >
                    {column.render('Footer')}
                  </CellBase>
                ))}
              </TableRow>
            ))}
          </TableFooteMUI>
        )}
      </Table>
      {withPage ? (
        <TableFooter
          pageCurrentIndex={pageIndex}
          hasNextPage={canNextPage}
          hasPreviousPage={canPreviousPage}
          setPage={setPage}
          totalPage={pageOptions?.length}
          listPerPage={[10, 25, 50, 75, 100, 'Todos']}
          perPage={pageSize}
          handleSelectPerPage={e => {
            setPageSize(Number(e.target.value))
            setPerPage(Number(e.target.value))
          }}
        />
      ) : null}
      {renderFooter && renderFooter()}
    </>
  )
}

export default DataTable
