import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import fetchSellers from '../../../../domain/features/sellers/fetchSellers'
import FieldAutoComplete from '../FieldAutoComplete'
import Label from '../../atomics/Label'
import { getSellers } from '../../../../domain/features/sellers/getSellers'

const FieldSeller = ({
  label,
  control,
  name,
  required,
  placeholder,
  defaultValue,
  ...props
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchSellers())
    return () => {
      promise.abort()
    }
  }, [dispatch])
  const { allSellers, status } = useSelector(getSellers)

  return (
    <>
      {label ? <Label required={required}>{label}</Label> : null}
      <FieldAutoComplete
        control={control}
        options={allSellers}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        loading={status === 'loading'}
        disabled={status === 'loading'}
        loadingText="Cargando sellers..."
        {...props}
      />
    </>
  )
}

FieldSeller.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
}
FieldSeller.defaultProps = {
  name: 'seller',
  label: 'Seller',
  required: true,
}
export default FieldSeller
