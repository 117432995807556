import Paper from '@material-ui/core/Paper'
import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import deburr from 'lodash/deburr'
import {
  Controller,
  useForm,
  useFieldArray,
  useWatch,
} from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import fetchAfterSale from '../../../../domain/features/afterSale/fetchAfterSale'
import normalizeAfterSaleForm from '../../../../domain/features/afterSale/normalizeAfterSale'
import {
  afterSaleSelector,
  resetAfterSale,
} from '../../../../domain/features/afterSale/afterSaleSlice'

import SelectMui from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'

import Modal from '../../atomics/Modal/Modal'
import Text from '../../atomics/Typography/Text'
import Title from '../../atomics/Typography/Title'
import Input from '../../atomics/Input/Input'
import Row from '../../atomics/Styles/Row'
import SubmitButton from '../../atomics/Button/SubmitButton/SubmitButton'
import { reasonList } from './typesAfterSale'
import ButtonDump from '../../atomics/Button/ButtonDump'
import WithdrawItem from './WithdrawItem'

const SubmitButtonAdd = styled(SubmitButton)`
  width: 111px;
  height: 36px;
`

const ButtonItem = styled(Button)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #5c5f62;
  text-transform: none;
  @media (min-width: 1100px) {
    width: 206px;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const Label = styled(InputLabel)`
  padding-left: 5px;
  padding-right: 5px;
  transform: translate(14px, 10px) scale(1);
  background-color: ${({ theme }) => theme.palette.background.default};
`

const RowCenter = styled(Row)`
  align-items: center;
  margin-right: 2rem;
`
const Select = styled(SelectMui)`
  height: 36px;
  margin-right: 1rem;
  border-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#b7b7b7'};
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#202223'};
  .MuiSelect-outlined {
    padding-right: 14px;
    border-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#FFFFFF' : '#b7b7b7'};
  }
`

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (min-width: 1100px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`

const ModalContainer = styled(Modal)`
  @media (max-width: 1100px) {
    margin: 1rem;
  }
`

const TitleModal = styled(Title)`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`

const Container = styled(Paper)`
  padding: 1.5rem;
  padding-top: 0.5rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media (min-width: 1100px) {
    width: 758px;
    padding-left: 4rem;
  }
  @media (max-width: 1100px) {
    width: auto;
  }
`

const Divider = styled('div')`
  height: 0.8rem;
  width: 2rem;
`
const Empty = styled('div')`
  height: 1rem;
  width: 1rem;
  margin-left: 1.8rem;
`

const CloseIconContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`
CloseIconContainer.displayName = 'CloseIconContainer'

const Head = styled('div')`
  display: flex;
  flex: 1;
  margin-bottom: 2rem;
`
Head.displayName = 'Head'

const Footer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 1rem;
`
Footer.displayName = 'Footer'

const Limit = styled('div')`
  border: 1px solid #b7b7b7;
  transform: rotate(90deg);
  width: 40px;
  height: 0px;
`

const FieldQuantity = styled(Input)`
  height: auto;
  .MuiInputBase-input {
    min-width: 100%;
  }
`

const FieldItem = ({
  item,
  index,
  control,
  remove,
  itemList,
  isOne,
  addMissingItem,
}) => {
  const watchFielValue = useWatch({
    name: `listItem[${index}].value`,
    control,
  })
  const currentField = itemList.filter(
    ({ value }) => value === watchFielValue
  )

  return (
    <RowCenter>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <FormControl variant="outlined" fullWidth error={!!error}>
            <Label htmlFor="item-label">Item</Label>
            <Select {...field}>
              {currentField.map(({ value, name }) => (
                <MenuItem key={String(value)} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        name={`listItem[${index}].value`}
        defaultValue={`listItem[${index}].value`}
        control={control}
        rules={{ required: 'Ingrese un item, por favor' }}
      />
      <Controller
        render={({ field, fieldState: { error } }) => (
          <FieldQuantity
            label="Cantidad"
            InputProps={{
              inputProps: {
                min: '1',
                max: `${item.quantity}`,
              },
            }}
            error={!!error}
            type="number"
            inputMode="numeric"
            {...field}
          />
        )}
        name={`listItem[${index}].quantity`}
        defaultValue={`listItem[${index}].quantity`}
        control={control}
        rules={{
          required: true,
          min: 0,
          validate: {
            positive: v => parseInt(v) > 0,
          },
          max: item.quantity,
        }}
      />
      <Divider />
      <Divider />
      <Text>${Number(item.price).toLocaleString('es-CL')}</Text>
      {isOne ? null : <Limit />}
      {isOne ? (
        <Empty />
      ) : (
        <ButtonDump
          onClick={() => {
            remove(index)
            addMissingItem(item)
          }}
        />
      )}
    </RowCenter>
  )
}

const ModalAfterSale = ({
  title,
  headerText,
  orderType,
  id,
  fieldArray = [],
  ...props
}) => {
  const { handleSubmit, control, formState, reset } = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    defaultValues: {
      listItem: fieldArray,
      transport: 0,
      penalty: 0,
      reason: undefined,
      isWithdrawable: false,
    },
  })
  const { status: statusForm, errorMessage } =
    useSelector(afterSaleSelector)

  React.useEffect(() => {
    if (statusForm !== 'loading') {
      reset({
        listItem: fieldArray,
        transport: 0,
        penalty: 0,
        reason: undefined,
        isWithdrawable: false,
      })
    }
  }, [reset, fieldArray, statusForm])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'listItem',
  })

  const watchFieldArray = useWatch({
    name: 'listItem',
    control,
  })

  const [missingFieldArray, setField] = React.useState([])

  const dispatch = useDispatch()
  const onSubmit = React.useCallback(
    data => {
      dispatch(
        fetchAfterSale(
          normalizeAfterSaleForm({
            ...data,
            id,
            status:
              'Cancelación' === orderType
                ? 'Cancelado'
                : 'Reenvío' === orderType
                ? 'No Despachado'
                : 'Ingresado',
            type: orderType,
          })
        )
      )
    },
    [dispatch, id, orderType]
  )

  React.useEffect(() => {
    if (statusForm === 'finished') {
      props.onClose()
      dispatch(resetAfterSale())
    }
  }, [dispatch, props, statusForm])

  return (
    <ModalContainer {...props} disableAutoFocus>
      <Container elevation={2}>
        <IconButton
          aria-label="close"
          onClick={props?.onClose}
        ></IconButton>
        <Head>
          <TitleModal>{title}</TitleModal>
        </Head>
        <Text>{headerText}</Text>
        <Divider />
        <Divider />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl variant="outlined" fullWidth error={!!error}>
                <Label htmlFor="label-motivo">Motivo</Label>
                <Select {...field}>
                  {reasonList
                    .filter(
                      ({ type }) => deburr(type) === deburr(orderType)
                    )
                    .map(({ id, view }, index) => (
                      <MenuItem key={String(index + id)} value={id}>
                        {view}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{error && error.message}</FormHelperText>
              </FormControl>
            )}
            name="reason"
            rules={{ required: 'Ingrese el motivo, por favor' }}
            control={control}
          />
          <Divider />
          {fields.map((item, index) => {
            return (
              <FieldItem
                key={item.id}
                index={index}
                control={control}
                remove={remove}
                itemList={fieldArray}
                item={item}
                isOne={fields.length === 1}
                missingFieldArray={missingFieldArray}
                addMissingItem={value =>
                  setField(prev => [...prev, value])
                }
              />
            )
          })}
          {watchFieldArray?.length >= fieldArray?.length ? null : (
            <ButtonItem
              onClick={() => {
                append(missingFieldArray.pop())
              }}
            >
              + Agregar otro item
            </ButtonItem>
          )}
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Input
                type="number"
                label="Transporte"
                fullWidth
                inputMode="numeric"
                InputProps={{ inputProps: { min: '0' } }}
                error={!!error}
                helperText={error && error.message}
                {...field}
              />
            )}
            name="transport"
            control={control}
            rules={{
              min: 0,
              validate: {
                lessThanTen: v =>
                  parseInt(v) >= 0 ||
                  'Ingrese un monto mayor o igual a 0, por favor',
              },
            }}
          />
          <Divider />
          {formState.errors.transport ? <Divider /> : null}
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Input
                type="number"
                inputMode="numeric"
                label="Multa"
                fullWidth
                helperText={error && error.message}
                error={!!error}
                {...field}
              />
            )}
            name="penalty"
            control={control}
          />
          <Divider />
          <Divider />
          {orderType === 'Reenvío' ? (
            <Text>
              <WithdrawItem
                control={control}
                name="isWithdrawable"
                defaultValue={false}
              />
            </Text>
          ) : null}
          <Divider />
          {statusForm === 'error' ? (
            <FormHelperText>
              {errorMessage || 'Ocurrio un error, intente más tarde.'}
            </FormHelperText>
          ) : null}
          <Footer>
            <SubmitButtonAdd
              disabled={
                formState.isSubmitting ||
                !isEmpty(formState.errors) ||
                statusForm === 'loading' ||
                !formState.isDirty
              }
              isLoading={statusForm === 'loading'}
              onClick={handleSubmit(onSubmit)}
            >
              Agregar
            </SubmitButtonAdd>
          </Footer>
        </Form>
      </Container>
    </ModalContainer>
  )
}

export default ModalAfterSale
