import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'

const Scroll = styled(Paper)`
  overflow-x: auto;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgb(239, 240, 242, 0.5);
    background-color: ${({ theme }) =>
      theme.palette?.type === 'dark'
        ? 'rgb(48, 48, 48)'
        : 'rgb(250, 250, 250);'};
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
    background-color: ${({ theme }) => theme.palette?.primary.light};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.palette?.type === 'dark'
        ? theme.palette?.primary.main
        : theme.palette?.primary.light};
    border-radius: 20rem;
  }

  &::-webkit-slider-thumb {
    background-color: ${({ theme }) => theme.palette?.primary.dark};
    background-image: -webkit-linear-gradient(
      90deg,
      rgb(239, 240, 242, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgb(239, 240, 242, 0.2) 50%,
      rgb(239, 240, 242, 0.2) 75%,
      transparent 75%,
      transparent
    );
  }

  /**Hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) =>
      theme.palette?.type === 'dark'
        ? theme.palette?.primary.main
        : theme.palette?.primary.main};
    box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.2);
  }
  /**Active */
  ::-webkit-scrollbar-thumb:active {
    background-color: ${({ theme }) =>
      theme.palette?.type === 'dark'
        ? theme.palette?.primary.main
        : theme.palette?.primary.main};
  }

  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track:active {
    background: ${({ theme }) => theme.palette?.background};
  }
`

export default Scroll
