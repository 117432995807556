import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import FieldAutoComplete from '../FieldAutoComplete'
import fetchCategory from '../../../../domain/features/category/fetchCategory'
import { getCategory } from '../../../../domain/features/category/getCategory'
import Label from '../../atomics/Label'

const FieldCategory = ({
  control,
  defaultValue,
  name,
  labelRequired = true,
  helperText,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchCategory())
    return () => {
      promise.abort()
    }
  }, [dispatch])
  const { categoryOnlyNameList, status } = useSelector(getCategory)

  return (
    <>
      {labelRequired ? <Label required>Categoría</Label> : null}
      <FieldAutoComplete
        control={control}
        options={categoryOnlyNameList}
        defaultValue={defaultValue}
        name={name ? name : 'category'}
        placeholder="Selecciona una categoría"
        disabled={status === 'loading'}
        loading={status === 'loading'}
        loadingText="Cargando Categorías..."
        helperText={helperText}
      />
    </>
  )
}

FieldCategory.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  labelRequired: PropTypes.bool,
  helperText: PropTypes.string,
}
export default FieldCategory
