import api from '../client/api'
import methods from '../client/httpMethods'
import request from '../client/request'
import persistentStore from '../persistentStore'

const subOrderService = ({ get, post }) => ({
  payouts: async () => {
    try {
      const reponse = await request(`/suborder/payouts`, {
        method: get,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  exportLiquidations: async ({
    startDate = undefined,
    endDate = undefined,
    sellers = undefined,
    detailed,
    cancelToken,
  }) => {
    let queryParams = ``

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (detailed) {
      queryParams += `&detailed=true`
    }

    try {
      const response = await request(
        `/suborder/payouts/download?${queryParams}`,
        {
          method: get,
          cancelToken,
        }
      )
      return await response.data
    } catch (error) {
      throw error
    }
  },
  payoutsByDate: async ({ endDate, sellers, cancelToken }) => {
    let queryParams = `toDate=${endDate}`

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    try {
      const reponse = await request(`/suborder/payouts?${queryParams}`, {
        method: get,
        cancelToken,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  payoutsCreate: async ({ cancelToken, ...rest }) => {
    try {
      const reponse = await request(`/suborder/payouts/create`, {
        method: post,
        data: JSON.stringify({ ...rest }),
        cancelToken,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  fileUpdate: async ({ cancelToken, document, orderId }) => {
    const form = new FormData()
    form.append('document', document)
    form.append('orderId', orderId)
    try {
      const reponse = await request(`/suborder/add-document`, {
        method: post,
        data: form,
        cancelToken,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  documentDownload: async ({ cancelToken, orderId }) => {
    try {
      const reponse = await request(
        `/suborder/get-document?orderId=${orderId}`,
        {
          method: get,
          cancelToken,
        }
      )
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  documentDelete: async ({ cancelToken, orderId }) => {
    try {
      const reponse = await request(
        `/suborder/delete-document?orderId=${orderId}`,
        {
          method: get,
          cancelToken,
        }
      )
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  orderFilter: async ({
    startDate = undefined,
    endDate = undefined,
    startDatePayout = undefined,
    endDatePayout = undefined,
    skip = undefined,
    limit = 25,
    status = undefined,
    accountingStatement = undefined,
    sellers = undefined,
    shopifyId = undefined,
    type = undefined,
    documentType = undefined,
    documentName = undefined,
    cancelToken,
  }) => {
    let queryParams = `limit=${limit}`

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }

    if (startDatePayout) {
      queryParams += `&fromDatePayout=${startDatePayout}`
    }

    if (endDatePayout) {
      queryParams += `&toDatePayout=${endDatePayout}`
    }

    if (shopifyId) {
      queryParams += `&shopifyId=${shopifyId}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (status) {
      queryParams += `&status=${encodeURIComponent(status)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (type) {
      queryParams += `&type=${encodeURIComponent(type)}`
    }
    if (documentType) {
      queryParams += `&documentType=${documentType}`
    }

    if (documentName !== undefined) {
      queryParams += `&documentName=${documentName}`
    }

    if (skip) {
      queryParams += `&skip=${skip}`
    }
    try {
      const reponse = await request(`/suborder?${queryParams}`, {
        method: get,
        cancelToken,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },

  orderAftersaleFilter: async ({
    startDate = undefined,
    endDate = undefined,
    startDatePayout = undefined,
    endDatePayout = undefined,
    skip = undefined,
    limit = 25,
    status = undefined,
    accountingStatement = undefined,
    sellers = undefined,
    shopifyId = undefined,
    type = undefined,
    reason = undefined,
    cancelToken,
  }) => {
    let queryParams = `limit=${limit}`

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }

    if (startDatePayout) {
      queryParams += `&fromDatePayout=${startDatePayout}`
    }

    if (endDatePayout) {
      queryParams += `&toDatePayout=${endDatePayout}`
    }

    if (shopifyId) {
      queryParams += `&shopifyId=${shopifyId}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (status) {
      queryParams += `&status=${encodeURIComponent(status)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (type) {
      queryParams += `&type=${encodeURIComponent(type)}`
    }

    if (reason) {
      queryParams += `&reason=${encodeURIComponent(reason)}`
    }

    if (skip) {
      queryParams += `&skip=${skip}`
    }

    try {
      const reponse = await request(
        `/operation/filter-aftersale?${queryParams}`,
        {
          method: get,
          cancelToken,
        }
      )
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  orderResumeDetail: async ({
    startDate = undefined,
    endDate = undefined,
    startDatePayout = undefined,
    endDatePayout = undefined,
    skip = undefined,
    limit = 25,
    status = undefined,
    accountingStatement = undefined,
    sellers = undefined,
    shopifyId = undefined,
    type = undefined,
    reason = undefined,
    cancelToken,
  }) => {
    let queryParams = `limit=${limit}`

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }

    if (startDatePayout) {
      queryParams += `&fromDatePayout=${startDatePayout}`
    }

    if (endDatePayout) {
      queryParams += `&toDatePayout=${endDatePayout}`
    }

    if (shopifyId) {
      queryParams += `&shopifyId=${shopifyId}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (status) {
      queryParams += `&status=${encodeURIComponent(status)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (type) {
      queryParams += `&type=${encodeURIComponent(type)}`
    }

    if (reason) {
      queryParams += `&reason=${encodeURIComponent(reason)}`
    }

    if (skip) {
      queryParams += `&skip=${skip}`
    }

    try {
      const reponse = await request(
        `/operation/aftersale-by-seller?${queryParams}`,
        {
          method: get,
          cancelToken,
        }
      )
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  exportOrderAllData: async ({
    startDate = undefined,
    endDate = undefined,
    startDatePayout = undefined,
    endDatePayout = undefined,
    status = undefined,
    accountingStatement = undefined,
    sellers = undefined,
    shopifyId = undefined,
    type = undefined,
    cancelToken,
  }) => {
    let queryParams = ``

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }
    if (startDatePayout) {
      queryParams += `&fromDatePayout=${startDatePayout}`
    }

    if (endDatePayout) {
      queryParams += `&toDatePayout=${endDatePayout}`
    }

    if (shopifyId) {
      queryParams += `&shopifyId=${shopifyId}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (status) {
      queryParams += `&status=${encodeURIComponent(status)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (type) {
      queryParams += `&type=${encodeURIComponent(type)}`
    }

    try {
      const response = await request(`/suborder/download?${queryParams}`, {
        method: get,
        cancelToken,
      })
      return await response.data
    } catch (error) {
      throw error
    }
  },
  exportOrderAftersaleAllData: async ({
    startDate = undefined,
    endDate = undefined,
    startDatePayout = undefined,
    endDatePayout = undefined,
    status = undefined,
    accountingStatement = undefined,
    sellers = undefined,
    shopifyId = undefined,
    type = undefined,
    cancelToken,
  }) => {
    let queryParams = ``

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }
    if (startDatePayout) {
      queryParams += `&fromDatePayout=${startDatePayout}`
    }

    if (endDatePayout) {
      queryParams += `&toDatePayout=${endDatePayout}`
    }

    if (shopifyId) {
      queryParams += `&shopifyId=${shopifyId}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (status) {
      queryParams += `&status=${encodeURIComponent(status)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (type) {
      queryParams += `&type=${encodeURIComponent(type)}`
    }

    try {
      const response = await request(
        `/operation/filter-aftersale-download?${queryParams}`,
        {
          method: get,
          cancelToken,
        }
      )
      return await response.data
    } catch (error) {
      throw error
    }
  },
  orderByPayout: async ({ payoutId = undefined, cancelToken }) => {
    try {
      const reponse = await request(`/suborder?payoutId=${payoutId}`, {
        method: get,
        cancelToken,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  orderUpdateStatus: async ({ id = undefined, cancelToken }) => {
    try {
      const reponse = await request(`/suborder/update-status/${id}`, {
        method: get,
        cancelToken,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  orderDetail: async ({ parentId = undefined, cancelToken }) => {
    try {
      const reponse = await request(`/suborder/by-id/${parentId}`, {
        method: get,
        cancelToken,
      })
      return await reponse.data
    } catch (error) {
      throw error
    }
  },
  orderDetailFetch: async ({ parentId = undefined, cancelToken }) => {
    try {
      const reponse = await fetch(
        `${api.settlement.BaseUrl}/suborder/by-id/${parentId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME,
          },
          cancelToken,
        }
      )
      return await reponse.json()
    } catch (error) {
      throw error
    }
  },
})

export default subOrderService(methods)
