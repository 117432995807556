import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { createAsyncThunk } from '@reduxjs/toolkit'

import subOrderService from '../../../data/services/subOrderService'
import dateApiFormat from '../../adpaters/days/dateApiFormat'

const createSeller = sellerName => ({
  seller: sellerName,
  detail: [
    { type: 'Devolución', count: 0 },
    { type: 'Cancelación', count: 0 },
    { type: 'Reenvío', count: 0 },
    { type: 'Venta', count: 0 },
  ],
  isAfterSale: false,
})

const fetchOrderResumeDetail = createAsyncThunk(
  'order/resume/details',
  async (
    {
      startDate,
      endDate,
      startDatePayout,
      endDatePayout,
      status,
      accountingStatement,
      sellerList = [],
      typeList,
      shopifyId,
      reasonList,
    },
    { signal }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const data = await subOrderService.orderResumeDetail({
      startDate:
        startDate && String(startDate).toLowerCase() !== 'invalid date'
          ? dateApiFormat(startDate)
          : undefined,
      endDate:
        endDate && String(endDate).toLowerCase() !== 'invalid date'
          ? dateApiFormat(endDate)
          : undefined,
      startDatePayout:
        startDatePayout &&
        String(startDatePayout).toLowerCase() !== 'invalid date'
          ? dateApiFormat(startDatePayout)
          : undefined,
      endDatePayout:
        endDatePayout &&
        String(endDatePayout).toLowerCase() !== 'invalid date'
          ? dateApiFormat(endDatePayout)
          : undefined,
      status: status?.join(','),
      accountingStatement: accountingStatement?.join(','),
      sellers: sellerList?.join(','),
      type: typeList?.join(','),
      shopifyId,
      cancelToken: source.token,
      reason: reasonList,
    })

    return sellerList.map(sellerName => {
      const sellerDetail = data.find(
        seller => seller.seller === sellerName
      )

      return isEmpty(sellerDetail)
        ? createSeller(sellerName)
        : {
            ...sellerDetail,
            isAfterSale: sellerDetail?.detail?.length !== 1,
          }
    })
  }
)
export default fetchOrderResumeDetail
