import TableCell from '@material-ui/core/TableCell'
import styled from 'styled-components'

const CellBase = styled(TableCell)`
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  max-width: auto;
`
CellBase.displayName = 'CellBase'
export default CellBase
