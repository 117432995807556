import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import Field from '../../atomics/Field/Field'

const FieldAutocomplete = styled(Field)`
  .MuiOutlinedInput-root {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    padding-left: 14px;
    width: 100%;
  }
`

const FieldAutoComplete = ({
  control,
  name,
  defaultValue,
  placeholder,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, ref, value, ...fields },
        fieldState: { error },
      }) => (
        <Autocomplete
          {...props}
          fullWidth
          autoComplete
          value={value}
          defaultValue={defaultValue}
          onChange={(_, data) => onChange(data)}
          noOptionsText="Ingresé una opción valida"
          inputRef={ref}
          renderInput={params => (
            <FieldAutocomplete
              {...{ ...params }}
              value={value}
              defaultValue={defaultValue}
              error={error}
              inputRef={ref}
              placeholder={placeholder}
              helperText={props?.helperText}
              {...fields}
            />
          )}
        />
      )}
    />
  )
}

FieldAutoComplete.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
}

export default FieldAutoComplete
