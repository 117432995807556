/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import max from 'lodash/max'
import isEmpty from 'lodash/isEmpty'
import tableDateFormat from '../../../domain/adpaters/days/tableDateFormat'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

import sessionPersistentStore from '../../../data/sessionPersistentStore'

import {
  getProducts,
  resultsProducts,
} from '../../../domain/features/productSearchApi/getProducts'
import fetchProducts, {
  getSortBy,
} from '../../../domain/features/productSearchApi/fetchProduct'
import { getBrands } from '../../../domain/features/brandsSlugSeller/getBrands'
import {
  allSellers,
  getSellers,
} from '../../../domain/features/sellers/getSellers'
import { getProductExportAllById } from '../../../domain/features/productExportAllById/getProductExportAllById'
import { getProductListDownload } from '../../../domain/features/productListDownload/getProductListDownload'
import fetchProductListDownload from '../../../domain/features/productListDownload/fetchProductListDownload'

import DataTable from '../templates/DataTable'
import SelectState from '../organims/SelectState/SelectState'
import SelectBrand from '../organims/SelectBrand/SelectBrand'
import TableFooter from '../organims/TableFooter/TableFooter'
import { SelectWithChipsSellersAutocomplete } from '../organims/SelectWithChipsSellers'
import SelectCategory from '../organims/SelectCategory/SelectCategory'
import SearchProduct from '../organims/SearchProduct/SearchProduct'
import AddProductButton from '../molecules/AddProductButton/AddProductButton'
import ActionButton from '../molecules/ActionButton/ActionButton'
import ButtonDownload from '../molecules/ButtonDownload/ButtonDownload'
import FilterContainer from '../atomics/FilterContainer'
import Scroll from '../atomics/ScrollTable'
import Title from '../atomics/Typography/Title'
import Row from '../atomics/Styles/Row'
import CellNumber from '../atomics/Cell/CellNumber'
import Alert from '../atomics/Alert/Alert'
import ChipManager from '../organims/ChipManager/ChipManager'
import { allchips } from '../../../domain/features/ui/chipsSlice'
import { useAuth } from '../pages/auth/useAuth'

const stateDownload = () =>
  new Map([
    [
      'loading',
      'Descargando productos, esto podria demorar varios minutos. ',
    ],
    ['error', 'Error al descargar productos.'],
  ])

const stateExport = () =>
  new Map([
    [
      'error',
      'Descargando productos, esto podria demorar varios minutos. ',
    ],
    [
      'loading',
      'Descargando productos, esto podria demorar varios minutos. ',
    ],
    ['finished', 'Descarga exitosa. '],
  ])

const Head = styled(Row)`
  justify-content: space-between;
  align-items: center;
`

const Divider = styled('div')`
  margin-right: 1rem;
`
const FilterParent = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 20px;
  padding-left: 0px;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    width: 100%;
  }
`

const Products = () => {
  const dispatch = useDispatch()
  const { currentUser: user } = useAuth()

  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )
  const isViewer = role === 'viewer'

  const [selectedStatus, setSelectedStatus] = useState(
    sessionPersistentStore.isEmptyStateFilter()
      ? []
      : sessionPersistentStore.getStateFilter()
  )

  const [brandOptions, setBrandOptions] = useState([])
  const setStatus = useCallback(
    status => {
      setSelectedStatus(status)
      sessionPersistentStore.setStateFilter(status)
    },
    [setSelectedStatus]
  )

  const { brandOnlyNameList, status: statusBrands } =
    useSelector(getBrands)

  const sellersList = useSelector(getSellers)
  const [selectedBrand, setSelectedBrand] = useState(
    sessionPersistentStore.isEmptyBrandSelect()
      ? []
      : sessionPersistentStore.getBrandSelect()
  )
  const setBrand = useCallback(
    brands => {
      setSelectedBrand(brands)
      sessionPersistentStore.setBrandSelect(brands)
    },
    [setSelectedBrand]
  )

  const [selectedCategory, setSelectedCategory] = useState(
    sessionPersistentStore.isEmptyCategorySelect()
      ? []
      : sessionPersistentStore.getCategorySelect()
  )

  const setCategory = useCallback(
    categories => {
      setSelectedCategory(categories)
      sessionPersistentStore.setCategorySelect(categories)
    },
    [setSelectedCategory]
  )

  const initSort = [{ id: 'updatedAt', desc: true }]
  const [sortBy, setSort] = useState(initSort)
  const [pageCurrentIndex, setPage] = useState(0)
  const [perPage, setPerPage] = useState(15)

  const [sortAction, setSortAction] = useState(null)
  const sellerList = useSelector(allSellers)

  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, 800)

  useEffect(() => {
    if (sellerList.length === 0) {
      setBrandOptions(brandOnlyNameList)
    } else {
      setBrandOptions([])
      // eslint-disable-next-line
      const sss = sellerList?.reduce((collect, seller) => {
        const s = sellersList?.sellers?.find(
          ss => ss.nameSeller === seller
        )

        if (s && s?.brands?.length > 0) {
          s?.brands?.map(b => collect.push(b.brandName))
        }
        return collect
      }, [])
      setBrandOptions(sss)
    }
  }, [sellerList, setBrandOptions])
  useEffect(() => {
    if (sortBy && sortBy[0] && !getSortBy.get(sortBy[0].id)) {
      setSortAction(sortBy)
    } else {
      setSortAction(null)
      if (String(statusBrands) === 'finished') {
        const promise = dispatch(
          fetchProducts({
            sortBy,
            query: debouncedQuery,
            skip: pageCurrentIndex,
            limit: perPage,
            brands: selectedBrand,
            status: selectedStatus,
            category: selectedCategory,
            sellerList,
          })
        )
        return () => {
          promise.abort()
        }
      }
    }
  }, [
    dispatch,
    sellerList,
    perPage,
    debouncedQuery,
    pageCurrentIndex,
    sortBy,
    selectedStatus,
    selectedCategory,
    selectedBrand,
    setSortAction,
    statusBrands,
  ])

  const columns = useMemo(
    () => [
      {
        Header: 'Imagen',
        accessor: 'featuredImage.url',
        Cell: cell => (
          <>
            <img
              src={cell?.row?.original?.featuredImage?.url || ''}
              width="31px"
              height="31px"
              alt={
                cell?.row?.original?.featuredImage?.altText ||
                'product-image'
              }
            />
          </>
        ),
      },
      {
        Header: 'Seller',
        accessor: 'seller',
        Cell: cell => {
          return <>{cell?.value}</>
        },
      },
      {
        Header: 'Nombre producto',
        accessor: 'title',
      },
      {
        Header: 'Categoría',
        accessor: 'productType',
      },
      {
        Header: 'Marca',
        accessor: 'vendor',
      },
      {
        Header: 'Precio normal',
        accessor: 'variants[0].compareAtPrice',
        Cell: cell => {
          const values = (cell?.row?.original?.variants || []).map(
            variant => variant.compareAtPrice
          )
          const maxValue = max(values)
          return <CellNumber number={maxValue} />
        },
      },
      {
        Header: 'Precio oferta',
        accessor: 'variants[0].price',
        Cell: cell => {
          const values = cell?.row?.original?.variants?.map(
            variant => variant?.price
          )
          const maxValue = max(values)
          return <CellNumber number={maxValue || 0} />
        },
      },
      {
        Header: 'Cantidad disponible',
        accessor: 'totalInventory',
      },
      {
        Header: 'Cantidad de variantes',
        accessor: 'count',
      },
      {
        Header: 'Fecha creación',
        accessor: 'createdAt',
        Cell: ({ cell }) => {
          return tableDateFormat(cell?.value)
        },
      },
      {
        Header: 'Última actualización',
        accessor: 'updatedAt',
        Cell: ({ cell }) => {
          return tableDateFormat(cell?.value)
        },
      },
      {
        Header: 'Fecha publicación',
        accessor: 'publishedAt',
        Cell: ({ cell }) => {
          return tableDateFormat(cell?.row?.original?.publishedAt)
        },
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ cell }) => {
          return (
            <>
              {new Map([
                ['ACTIVE', 'Aprobado'],
                ['DRAFT', 'Pendiente aprobación'],
                ['ARCHIVED', 'Deshabilitado'],
              ]).get(cell?.value)}
            </>
          )
        },
      },
      {
        Header: 'Acción',
        accessor: 'actions',
        Cell: cell => (
          <ActionButton
            productId={String(cell?.row?.original?.id)}
            status={String(cell?.row?.original?.status)}
            role={role}
          />
        ),
      },
    ],
    []
  )

  const handleClickOptionOne = useCallback(() => {
    dispatch(
      fetchProductListDownload({
        brands: selectedBrand,
        status: selectedStatus,
        category: selectedCategory,
        sellerList,
        query: debouncedQuery,
      })
    )
  }, [
    dispatch,
    selectedBrand,
    selectedStatus,
    selectedCategory,
    sellerList,
    debouncedQuery,
  ])

  const { status, pagination, isLoading } = useSelector(getProducts)
  const data = useSelector(resultsProducts(sortAction))
  const isActiveFilter = useSelector(allchips)?.length > 0
  const { status: statusExportAll } = useSelector(getProductExportAllById)
  const { status: statusDownload } = useSelector(getProductListDownload)
  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(() => {
    if (statusExportAll === 'loading' || statusDownload === 'loading') {
      setOpenAlert(true)
    }
  }, [statusExportAll, statusDownload])
  const isObjectIdMongo = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i
  return (
    <>
      <Head>
        <Box mt="58px" mb="35px">
          <Title>Productos</Title>
        </Box>
        <AddProductButton disabled={isViewer} />
      </Head>
      <Paper>
        <FilterParent>
          <FilterContainer>
            <SearchProduct query={query} setQuery={setQuery} />
            <Divider />
            <SelectState
              label="Estado"
              value={selectedStatus}
              onChange={setStatus}
              isallfilter
            />
            <SelectWithChipsSellersAutocomplete isallfilter isfilterleft />
            <SelectBrand
              label="Marca"
              options={brandOptions.filter(
                brand => !isObjectIdMongo.test(brand)
              )}
              status={statusBrands}
              value={selectedBrand}
              onChange={setBrand}
              isallfilter
              isfilterleft
            />
            <SelectCategory
              label="Categoría"
              value={selectedCategory}
              onChange={setCategory}
              isfilterleft
            />
          </FilterContainer>
          <ButtonDownload
            type="select"
            toolTipText={
              !isActiveFilter
                ? 'Seleccione un filtro'
                : 'Descargar planilla'
            }
            disabled={
              isViewer || !isActiveFilter || statusDownload === 'loading'
            }
            handleClickOptionOne={handleClickOptionOne}
            optionOneText="Descargar productos"
            handleClickOptionTwo={null}
          />
        </FilterParent>
        <ChipManager
          brand={selectedBrand}
          productStatus={selectedStatus}
          category={selectedCategory}
          seller={sellerList}
          setBrand={setSelectedBrand}
          setStatus={setSelectedStatus}
          setCategory={setSelectedCategory}
        />
        <Paper elevation={2}>
          <Scroll>
            <DataTable
              manualSortBy={true}
              onSort={setSort}
              isError={status === 'error'}
              isLoading={isEmpty(data) || isLoading}
              data={
                isEmpty(data) || isLoading
                  ? Array(25).fill({ id: '' })
                  : data
              }
              columns={columns}
              withPage={false}
              isProductsPage
              renderFooter={() => (
                <>
                  <TableFooter
                    isGraphQL
                    pageCurrentIndex={pageCurrentIndex}
                    hasNextPage={pagination.hasNextPage}
                    hasPreviousPage={pagination.hasPreviousPage}
                    setPage={setPage}
                    listPerPage={[5, 10, 15, 20]}
                    perPage={perPage}
                    handleSelectPerPage={e => {
                      setPerPage(Number(e.target.value))
                    }}
                  />
                </>
              )}
            />
          </Scroll>
        </Paper>
      </Paper>

      <Alert
        key="product-create"
        open={openAlert}
        onClose={handleCloseAlert}
        title={
          stateExport().get(statusExportAll) ||
          stateDownload().get(statusDownload)
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        isLoading={
          (statusDownload === 'loading' &&
            statusExportAll !== 'finished') ||
          (statusDownload === 'finished' && statusExportAll === 'idle')
        }
        isMultipleLoading={
          (statusDownload === 'loading' &&
            statusExportAll !== 'finished') ||
          (statusDownload === 'finished' && statusExportAll === 'idle')
        }
        severity={'info'}
        isError={statusExportAll === 'error'}
      />
    </>
  )
}

export default Products
