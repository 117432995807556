import persistentStore from '../persistentStore'
import isEmpty from 'lodash/isEmpty'
import request from '../client/request'

const statusBase = new Map([
  ['Aprobado', 'ACTIVE'],
  ['Deshabilitado', 'ARCHIVED'],
  ['Pendiente aprobación', 'DRAFT'],
])
const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index
}

const productAdminServices = (baseUrl, endpoint) => ({
  getProducts: async function ({
    brands,
    brandsAll,
    title,
    status,
    limit = 20,
    cursor = null,
    direction = 'next',
    sortKey = 'UPDATED_AT',
    reverse = true,
    category = undefined,
    signal = undefined,
  }) {
    let brandList

    if (brands.length === brandsAll.length) {
      brandList = brands
        .map(({ seller }) => `(tag:'${seller.tagSeller}')`)
        .filter(onlyUnique)
        .join(' OR ')
    } else {
      const brandsNotSelected = brandsAll
        .filter(brand =>
          brands.some(br => br.seller.tagSeller === brand.seller.tagSeller)
        )
        .filter(
          brand => !brands.some(br => br.brandName === brand.brandName)
        )
        .map(({ brandName }) => `( NOT vendor:'${brandName}')`)
        .filter(onlyUnique)
        .join(' AND ')
      brandList = `${brands
        .map(({ seller }) => `(tag:'${seller.tagSeller}')`)
        .filter(onlyUnique)
        .join(' OR ')} AND ${brandsNotSelected}`
    }
    const statusList = status
      ? status
          .map(value => `(status:${statusBase.get(value)})`)
          .join(' OR ')
      : undefined
    const categoryList = category
      ? category
          .map(
            product_type_name => `(product_type:'${product_type_name}')`
          )
          .join(' OR ')
      : undefined

    const response = await fetch(`${baseUrl}/${endpoint}/list_mkp`, {
      method: 'POST',
      body: JSON.stringify({
        query: `${brandList}${categoryList ? ` AND ${categoryList}` : ''}${
          title ? ` AND (title:*${title}* OR sku:*${title}*)` : ''
        }${status ? ` AND ${statusList}` : ''}`,
        direction,
        cursor,
        quantity: limit,
        sortKey,
        reverse,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      signal,
    })
    const data = await response.json()

    if (isEmpty(data?.products?.edges)) {
      return new Error('Sin productos')
    }

    return data
  },
  createProduct: async body => {
    const response = fetch(`${baseUrl}/${endpoint}/create`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })
    return (await response).json()
  },
  updateProduct: async (body, cancelToken) => {
    const response = await request(`${baseUrl}/${endpoint}/update`, {
      method: 'post',
      data: JSON.stringify(body),
      cancelToken,
    })

    return response.data
  },
  uploadFiles: async data => {
    const response = await fetch(`${baseUrl}/${endpoint}/upload-files`, {
      method: 'POST',
      headers: {
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: data,
    })

    return response.json()
  },
  getProductDetailById: async ({ id }) => {
    const response = fetch(`${baseUrl}/${endpoint}/detail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: JSON.stringify({
        id,
      }),
    })

    return response
  },
  deleteProductById: async ({ id }) => {
    const response = fetch(`${baseUrl}/${endpoint}/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: JSON.stringify({ id }),
    })
    return (await response).json()
  },
  getProductBySku: async ({ listSku, signal = undefined }) => {
    const response = fetch(`${baseUrl}/${endpoint}/sku`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: JSON.stringify({
        listSku,
      }),
      signal,
    })

    return response
  },
  exportProductAllData: async ({
    brands,
    brandsAll,
    status,
    category,
    title,
  }) => {
    const brandsNotSelected = brandsAll
      .filter(brand =>
        brands?.some(br => br.seller.tagSeller === brand.seller.tagSeller)
      )
      .filter(
        brand => !brands?.some(br => br.brandName === brand.brandName)
      )
      .map(({ brandName }) => `( NOT vendor:'${brandName}')`)
      .filter(onlyUnique)
      .join(' AND ')
    const brandList = `${brands
      .map(({ seller }) => `(tag:'${seller.tagSeller}')`)
      .filter(onlyUnique)
      .join(' OR ')} AND ${brandsNotSelected}`
    const statusList =
      status && status.length
        ? status
            .map(value => `(status:${statusBase.get(value)})`)
            .join(' OR ')
        : null
    const categoryList =
      category && category.length
        ? category
            .map(
              product_type_name => `(product_type:${product_type_name})`
            )
            .join(' OR ')
        : null

    const response = await fetch(`${baseUrl}/${endpoint}/export/all`, {
      method: 'POST',
      body: JSON.stringify({
        query: `${brandList}${categoryList ? ` AND ${categoryList}` : ''}${
          title ? ` AND title:*${title}*` : ''
        }${statusList ? ` AND ${statusList}` : ''}`,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })

    return response.json()
  },
  exportAllById: async bulkId => {
    const response = await fetch(
      `${baseUrl}/${endpoint}/export/all/${bulkId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME,
        },
      }
    )
    return response
  },
})

export default productAdminServices(
  process.env.REACT_APP_API_PRODUCT_PATH,
  'product'
)
