import orderBy from 'lodash/orderBy'
import { createSelector } from '@reduxjs/toolkit'
import { productAdapter, productSlice } from './productSlice'

export const { addOneProduct } = productSlice.actions

const getStateProducts = state => state.products.list

export const getProducts = createSelector(
  [getStateProducts],
  products => products
)

const productSelector = productAdapter.getSelectors(getStateProducts)
export const resultsProducts = sort => state => {
  if (sort) {
    const products = productSelector.selectAll(state)
    return sort[0]?.id.toString().includes('price')
      ? orderBy(
          products,
          c => Number.parseInt(c?.variants[0]?.price),
          sort[0].desc ? 'desc' : 'asc'
        )
      : orderBy(products, sort[0]?.id, sort[0]?.desc ? 'desc' : 'asc')
  } else {
    return productSelector.selectAll(state)
  }
}
