import PropTypes from 'prop-types'
import { useMemo, useState, useEffect } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import isArray from 'lodash/isArray'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Collapse from '@material-ui/core/Collapse'

import VariantItem from '../../molecules/VariantItem/VariantItem'
import Checkbox from '../../atomics/Checkbox/Checkbox'
import Title from '../../atomics/Typography/Title'
import ModalTemplate from '../../templates/ModalTemplate/ModalTemplate'
import CardProduct from '../../atomics/CardProduct/CardProduct'
import ErrorIcon from '@material-ui/icons/Error'

const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`
const CardProductStyled = styled(CardProduct)`
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 700px) {
    width: 100%;
  }
`
const Head = styled('div')`
  padding-top: 58px;
  padding-left: 58px;
  padding-right: 58px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .MuiButtonBase-root:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
  @media (max-width: 700px) {
    padding-top: 3%;
    padding-bottom: 4$;
    padding-left: 5%;
    padding-right: 3%;
  }
`

const TitleStyled = styled(Title)`
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 2%;
`

const Text = styled('p')`
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin: 0;
  margin-bottom: 3%;
`

const ButtonStyled = styled(Button)`
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#ffffff' : '#2469ce'};
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  text-decoration: underline;
  align-self: flex-end;
`

const SubContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 58px;
  margin-bottom: 2%;
  @media (max-width: 700px) {
    padding-right: 1%;
  }
`
const ContainerVariantText = styled('div')`
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 72px;
  @media (max-width: 700px) {
    padding-left: 1%;
  }
`

const CheckboxStyle = styled(Checkbox)`
  margin-right: 5%;
  @media (max-width: 700px) {
    margin-right: 1%;
  }
`

const VariantText = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  color: #212121;
`

const DeleteVariantListStyle = styled(Button)`
  font-size: 19px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: underline;
  text-transform: capitalize;
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#ffffff' : '#2469ce'};
  padding: 0;
`

const ErrorText = styled('p')`
  color: red;
  font-size: 18px;
  font-style: italic;
  margin: 0px;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
  gap: 5px;
`

const ProductVariantForm = ({
  isDetailsView,
  variants,
  control,
  errors,
}) => {
  const { remove, append } = useFieldArray({
    control,
    name: 'variants',
  })
  const variantsWatch = useWatch({ control, name: 'variants' })
  const mediaWatch = useWatch({ control, name: 'media' })
  const [checked, setChecked] = useState(false)
  const [newVariants, setNewVariants] = useState([])
  useEffect(() => {
    setNewVariants(variants)
  }, [variants])

  const count = useMemo(
    () => Array(...newVariants).filter(({ checked }) => checked).length,
    [newVariants]
  )

  const handleAllChecked = () => {
    setChecked(!checked)
    setNewVariants(prev =>
      [...prev]?.map((variant, index) => ({
        ...variant,
        checked: index === 0 ? false : !checked,
      }))
    )
  }

  const handleDelete = () => {
    Array(...newVariants).forEach(
      (variant, index) => variant?.checked && remove(index)
    )
    setNewVariants(prev => [...prev].filter(({ checked }) => !checked))
  }

  const handleOnChange = event => {
    const currentVariant = Array(...newVariants).find(
      variant => String(variant.id) === String(event.target.name)
    )
    const checkedVariant = {
      ...currentVariant,
      checked: event.target.checked,
    }
    setNewVariants(prev => {
      return [...prev].map(variant =>
        String(variant.id) === String(checkedVariant?.id)
          ? { ...checkedVariant }
          : { ...variant }
      )
    })
  }

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(0)
  const [isEdit, setEdit] = useState(true)
  const addNewVariant = () =>
    setNewVariants(prev => {
      return [
        ...prev,
        {
          checked: false,
          id: selected,
          ...variantsWatch[selected],
        },
      ]
    })
  const changeVariant = (id, changes) =>
    setNewVariants(prev =>
      [...prev].map(v =>
        String(v?.id) === String(id) ? { ...v, ...changes } : v
      )
    )
  const checkHasError = index => {
    if ((errors.variants || []).length >= index + 1) {
      return errors.variants[index] !== undefined
    }
    return false
  }

  const isVariant = !!variantsWatch
  const showTextError = (errors?.variants || []).length > 0
  return (
    <>
      <ModalTemplate
        index={selected}
        control={control}
        errors={errors}
        open={open}
        onClose={() => {
          if (variantsWatch.length > newVariants.length) {
            remove(-1)
          }
          setEdit(true)
          setOpen(false)
        }}
        onSubmit={() => {
          isEdit
            ? changeVariant(selected, variantsWatch[selected])
            : addNewVariant()
          setEdit(true)
          setOpen(false)
        }}
        media={mediaWatch}
        title="Agregar variante"
        subtitle="Agrega características correspondientes solamente a la variante."
      />
      <CardProductStyled>
        <Head>
          <ButtonStyled
            disabled={!isVariant}
            onClick={() => {
              append({
                color: '',
                compareAtPrice: variantsWatch[0]?.compareAtPrice,
                price: variantsWatch[0]?.price,
                weight: variantsWatch[0]?.weight,
                inventoryQuantity: 0,
                ownSku: '',
              })
              setSelected(variantsWatch.length)
              setEdit(false)
              setOpen(true)
            }}
          >
            + Agregar variante
          </ButtonStyled>
          <TitleStyled>Variantes del producto</TitleStyled>
          <Text>
            Si tu producto tiene más colores, aparte del seleccionado,
            agrega su variante aquí.
          </Text>
          {showTextError && (
            <ErrorText>
              <ErrorIcon fontSize="15px" />
              Una o más de las variantes editadas presenta errores, por
              favor revísala(s).
            </ErrorText>
          )}
        </Head>
        {isVariant ? (
          <Container>
            <Collapse in={count > 0}>
              <SubContainer>
                <ContainerVariantText>
                  <FormControlLabel
                    control={
                      <CheckboxStyle
                        checked={count > 0}
                        indeterminate={count > 0}
                        onChange={handleAllChecked}
                      />
                    }
                  />
                  <VariantText>
                    {count === 1
                      ? `${count} variante seleccionada`
                      : `${count} variantes seleccionadas`}
                  </VariantText>
                </ContainerVariantText>
                <DeleteVariantListStyle onClick={handleDelete}>
                  Eliminar
                </DeleteVariantListStyle>
              </SubContainer>
            </Collapse>
            <VariantItem
              index={0}
              variantId={0}
              hasError={checkHasError(0)}
              variantName={isVariant ? variantsWatch[0]?.color : ''}
              variantPrice={isVariant ? variantsWatch[0]?.price : 0}
              variantCompareAtPrice={
                isVariant ? variantsWatch[0]?.compareAtPrice : 0
              }
              variantAlt={isVariant ? variantsWatch[0]?.color : 'variant'}
              variantSku={isVariant ? variantsWatch[0]?.sku : ''}
              variantOwnSku={isVariant ? variantsWatch[0]?.ownSku : ''}
              variantQuantity={
                isVariant ? variantsWatch[0]?.inventoryQuantity : 0
              }
              variantImage={
                isVariant && mediaWatch
                  ? (mediaWatch &&
                      mediaWatch.filter(({ alt }) =>
                        variantsWatch
                          ? alt?.includes(variantsWatch[0].color?.trim())
                          : false
                      )[0]?.preview?.image?.url) ||
                    mediaWatch.filter(({ preview }) =>
                      variantsWatch && variantsWatch[0].mediaIds
                        ? variantsWatch[0]?.mediaIds[0]?.trim() ===
                          preview?.image?.url?.trim()
                        : false
                    )[0]?.src
                  : ''
              }
              onChange={handleOnChange}
              checked={false}
              onClick={() => {
                setSelected(0)
                setOpen(true)
              }}
            />
            {newVariants
              ?.slice(isDetailsView ? 1 : 0)
              ?.map((variant, index) => {
                const idMedaIds = isArray(variant?.mediaIds)
                  ? variant?.mediaIds[0]
                  : undefined
                const media = isArray(variant.media)
                  ? variant?.media[0]
                  : undefined

                return (
                  <VariantItem
                    key={variant?.color || String(index)}
                    variantId={variant?.id}
                    variantName={variant?.color}
                    variantPrice={variant?.price}
                    hasError={checkHasError(index + 1)}
                    variantCompareAtPrice={
                      isVariant
                        ? variantsWatch[0]?.compareAtPrice
                        : variant?.compareAtPrice
                    }
                    variantAlt={
                      media?.preview?.image?.altText
                        ? media?.preview?.image?.altText
                        : variant?.color
                    }
                    variantSku={variant?.sku}
                    variantOwnSku={variant?.ownSku}
                    variantQuantity={variant?.inventoryQuantity}
                    variantImage={
                      idMedaIds &&
                      idMedaIds.includes('https://cdn.shopify.com')
                        ? idMedaIds
                        : mediaWatch &&
                          mediaWatch.filter(
                            ({ preview }) =>
                              idMedaIds === preview?.image?.url
                          )[0]?.src
                    }
                    onChange={handleOnChange}
                    checked={variant?.checked}
                    onClick={() => {
                      setSelected(index + 1)
                      setOpen(true)
                    }}
                  />
                )
              })}
          </Container>
        ) : null}
      </CardProductStyled>
    </>
  )
}

ProductVariantForm.propTypes = {
  onDelete: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.any,
  isSubmitting: PropTypes.bool,
  control: PropTypes.any,
  handleAddProduct: PropTypes.func,
  categoryList: PropTypes.array,
  brandList: PropTypes.array,
  status: PropTypes.string,
  setAllFiles: PropTypes.func,
  allFiles: PropTypes.object,
  navigate: PropTypes.any,
  productName: PropTypes.string,
  isDetailsView: PropTypes.bool,
  images: PropTypes.array,
  productStatus: PropTypes.string,
  option: PropTypes.string,
  mediaList: PropTypes.array,
  setMediaList: PropTypes.func,
  variants: PropTypes.array,
}

ProductVariantForm.defaultProps = {
  variants: [],
  isDetailsView: false,
}
export default ProductVariantForm
