const normalizeAfterSaleForm = ({
  listItem,
  transport,
  penalty,
  ...rest
}) => ({
  ...rest,
  items: listItem?.map(({ value, quantity }) => ({ id: value, quantity })),
  transport: parseInt(transport),
  penalty: parseInt(penalty),
})

export default normalizeAfterSaleForm
