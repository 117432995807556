import { debounce, isEmpty } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import fetchProductExportAllById from '../productExportAllById/fetchProductExportAllById'

const recursiveFetch = debounce(async (dispatch, bulkId) => {
  let rejected
  const data = await dispatch(fetchProductExportAllById({ bulkId }))
  rejected = data.meta.requestStatus
  if (rejected === 'rejected') {
    return await recursiveFetch(dispatch, bulkId)
  }
  return true
}, 2000)

const fetchProductListDownload = createAsyncThunk(
  'product/export',
  async (
    { brands, status, category, sellerList, query = '' },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const brandList = getState().brands.allBrands
      const brandListWithSeller = !isEmpty(sellerList)
        ? Array(...brandList).filter(brand =>
            sellerList?.some(
              seller => String(seller) === String(brand.seller.nameSeller)
            )
          )
        : brandList
      const currentBrandList = !isEmpty(brands)
        ? brandListWithSeller.filter(({ brandName }) =>
            brands?.some(brand => String(brand) === String(brandName))
          )
        : brandListWithSeller

      const response = await productAdminServices.exportProductAllData({
        brands: currentBrandList,
        brandsAll: brandList,
        status,
        title: query,
        category,
      })
      if (response.error) {
        return rejectWithValue('Operación bulk falló')
      }
      recursiveFetch(dispatch, response.id)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductListDownload
