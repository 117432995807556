import persistentStore from '../persistentStore'

const userApiServices = baseUrl => ({
  getBySlugSeller: async ({ slugSeller }) => {
    const response = await fetch(`${baseUrl}/user/notify/${slugSeller}`, {
      headers: {
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })
    const data = response.json()
    return data
  },
})

export default userApiServices(process.env.REACT_APP_USER_API_PATH)
