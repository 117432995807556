import sellerServices from '../../../../data/services/sellerServices'

const fetchBrandCheck = async data => {
  try {
    const updated = await sellerServices.brandsCheck(data)
    return updated
  } catch (e) {
    return null
  }
}
export default fetchBrandCheck
