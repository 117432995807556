import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined'
import Text from '../../atomics/Typography/Text'

const TextButton = styled(Text)`
  font-weight: normal;
  padding-right: 6px;
`

const ButtonMoreFilter = styled(Button)`
  font-weight: normal;
  padding-right: 6px;
  margin-left: 6px;

  @media (max-width: 1400px) {
    margin-bottom: ${({ active }) => (active ? '0px' : '0px')};
  }
`

const MoreFilter = ({ text, isPlus, active, ...props }) => {
  return (
    <ButtonMoreFilter
      active={active}
      startIcon={isPlus ? <AddCircleIcon /> : <RemoveCircleOutlinedIcon />}
      {...props}
    >
      <TextButton>{text}</TextButton>
    </ButtonMoreFilter>
  )
}

export default MoreFilter
