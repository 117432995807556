import React from 'react'

import { SvgIcon } from '@material-ui/core'

const IconPencil = props => (
  <SvgIcon color={props.color || 'inherit'} {...props}>
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.94127 13.0041L5.90554 15.3196L10.4825 7.29792L6.51819 4.98238L1.94127 13.0041ZM1.45088 14.2445L2.125 17.3044L5.08758 16.353L1.45088 14.2445ZM7.17204 3.82461L11.1363 6.14015L12.3008 4.11372L8.33654 1.79818L7.17204 3.82461ZM0 13.7484L7.84615 0L14.078 3.63871L6.23181 17.3871L1.1645 19L0 13.7484ZM7.84615 19V17.6768H17V19H7.84615Z"
        fill="#5C5F62"
      />
    </svg>
  </SvgIcon>
)

export default IconPencil
