import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import deburr from 'lodash/deburr'
import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import { composeMeasurement } from '../../adpaters/measurementProduct/composeMeasurement'

import normalizeProduct, {
  checkProductCreate,
} from '../../adpaters/product/normalizeProduct'
import { createFilesArray } from '../../adpaters/product/createFiles'

const fetchProductCreate = createAsyncThunk(
  'product/create',
  async ({ data, media = null }, { rejectWithValue }) => {
    try {
      const {
        manual,
        certificate,
        efficiency,
        dataSheet,
        inpage,
        descriptionImage,
        variants,
        seller,
      } = data
      const form = new FormData()
      const files = createFilesArray(
        manual,
        certificate,
        efficiency,
        dataSheet,
        inpage,
        descriptionImage
      )

      files.forEach(file => {
        form.append(file.metafield, file.file)
      })
      const response = await productAdminServices.uploadFiles(form)
      const filesIds = {}
      response.forEach(file => {
        if (file.image) {
          filesIds[file.alt] = file.image.url
        } else {
          filesIds[file.alt] = file.url
        }
      })
      let formMedia = null
      let mediaResponse = null
      const mediaIds = []
      if (media) {
        formMedia = new FormData()
        media.forEach(image => {
          const { name, file } = image
          formMedia.append(name, file)
        })
        mediaResponse = await productAdminServices.uploadFiles(formMedia)
        mediaResponse.forEach(file => {
          if (file.image) {
            mediaIds.push({ name: file.alt, url: file.image.url })
          } else {
            mediaIds.push({ name: file.alt, url: file.url })
          }
        })
      }

      const variantList = variants?.map(
        ({
          inventoryQuantity,
          mediaIds,
          sku,
          ownSku,
          compareAtPrice,
          price,
          ...v
        }) => ({
          ...v,
          price: price ?? compareAtPrice,
          compareAtPrice,
          availableQuantity: inventoryQuantity,
          imageName: mediaIds ? mediaIds : null,
          sku: isNil(sku) || sku === 'undefined' ? '' : sku,
          own_sku: isNil(ownSku) || ownSku === 'undefined' ? '' : ownSku,
        })
      )

      const { productCreate } = await productAdminServices.createProduct({
        title: data.productName,
        status: 'DRAFT',
        productType: data.category,
        vendor: data.brand,
        descriptionHtml: data.detailedDescription,
        availableQuantity: data?.availableQuantity,
        warranty: deburr(data?.productWarranty)
          .toLowerCase()
          .includes('segun terminos y condiciones')
          ? data?.productWarranty
          : `${data?.productWarranty} según términos y condiciones`,
        materials: data?.productMaterial,
        features: data?.specialFeatures,
        fabricado_en: data?.manufacturedCountry,
        tension: data?.productTension,
        installation: data?.productInstalation,
        accesories: data?.productAccesories,
        considerations: data?.productConsiderations,
        plug: data?.plug,
        knob: data?.commands,
        power: data?.power,
        rating_energy: data?.energyClass,
        cp_useful_total: data?.totalUsableCapacity,
        measurements_enchase: data?.embeddedMeasurements,
        measurements_product: composeMeasurement(
          data.productHeight,
          data.productWidth,
          data.productDepth
        ),
        panel: data?.panel,
        type_power: data?.energyType,
        speed: data?.speedsNumber,
        light: data?.light,
        armed: data?.built,
        consumption_kwh: data?.consume,
        temperature: data?.temperature,
        level_heat_burner: data?.burnersHeatLevels,
        type_surface: data?.countertopType,
        total_burners: data?.burnersNumber,
        type_oven: data?.ovenType,
        total_programs: data?.ovenProgramsNumber,
        max_suction: data?.maxSuction,
        type_campaign: data?.bellType,
        max_noise: data?.maxNoise,
        type_grill: data?.grillType,
        diameter_tube: data?.tubeDiameter,
        technology_cooling: data?.coldTechnology,
        type_freezer: data?.freezerType,
        type_refrigerator: data?.refrigeratorType,
        cp_useful_freezer: data?.refrigeratorUsableCapacity,
        cp_useful_refrigerator: data?.freezerUsableCapacity,
        gas_refrigerant: data?.refrigerantGas,
        total_programs_washing: data?.washingProgramsNumber,
        total_programs_drying: data?.dryProgramsNumber,
        type_load: data?.loadType,
        video: data.video,
        ...filesIds,
        media: mediaIds,
        variants: variantList,
        intensity: data.intensity,
        security: data.security,
        cable_length: data.cableLength,
        type_tap: data.tapType,
        cp_useful_washing_machine: data.washingMachineUsableCapacity,
        cp_useful_washer_dryer: data.dryerUsableCapacity,
        type_function: data.functionType,
        bottles_quantity: data.bottlesQuantity,
        seller,
      })
      if (isEmpty(productCreate?.product)) {
        return rejectWithValue('Error crear producto')
      }

      return normalizeProduct(
        checkProductCreate({
          ...productCreate?.product,
          pathname: `/productos/${
            productCreate.product?.id?.split('Product/')[1]
          }`,
        })
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductCreate
