export const decomposeMeasurement = (entryString, position) => {
  const regex = /(\d+)/g
  let variableOutput = entryString
  if (entryString) {
    const entryStringArray = entryString.split(',')
    // eslint-disable-next-line no-console
    if (entryStringArray.length > position) {
      variableOutput = entryStringArray[position].match(regex)
    }
  }

  return variableOutput && variableOutput.length > 0
    ? variableOutput[0]
    : ''
}
