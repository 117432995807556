import { createSelector } from '@reduxjs/toolkit'
import {
  liquidationsSlice,
  liquidationsAdpater,
} from './liquidationsSlice'
import pipe from 'lodash/fp/pipe'
import deburr from 'lodash/deburr'
import filter from 'lodash/fp/filter'

const getStateLiquidation = state => state.tables.liquidations

export const getLiquidation = createSelector(
  [getStateLiquidation],
  liquidations => liquidations
)

const liquidationListSelector = liquidationsAdpater.getSelectors(
  getStateLiquidation
)
export const allLiquidations = liquidationListSelector.selectAll

const defaultValues = values => values
const filterSellerName =
  selectList =>
  ({ sellerName }) =>
    selectList.some(
      sellerCurrent =>
        String(sellerCurrent).toLowerCase() ===
        String(sellerName).toLowerCase()
    )

const filterAccountingStatement =
  _accountingStatement =>
  ({ accountingStatement }, i) => {
    return _accountingStatement.some(
      acs =>
        deburr(acs).toLowerCase() ===
        deburr(accountingStatement).toLowerCase()
    )
  }

export const allLiquidationsWithFilters =
  (selectList = [], _accountingStatement = undefined) =>
  state => {
    return pipe(
      allLiquidations,
      selectList ? filter(filterSellerName(selectList)) : defaultValues,
      _accountingStatement
        ? filter(filterAccountingStatement(_accountingStatement))
        : defaultValues
    )(state)
  }

export const { updateLiquidations } = liquidationsSlice.actions
