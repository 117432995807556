import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import map from 'lodash/fp/map'
import isNil from 'lodash/isNil'
import dayjs from 'dayjs'

import subOrderService from '../../../data/services/subOrderService'
import normalizePayout from './normalizePayout'

const fetchPayouts = createAsyncThunk(
  'payouts/filter',
  async ({ endDate, sellerList }, { rejectWithValue, signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const data = await subOrderService.payoutsByDate({
      endDate:
        endDate &&
        String(endDate).toLowerCase() !== 'invalid date' &&
        dayjs(endDate).isValid()
          ? dayjs(endDate).endOf('day').format()
          : dayjs(new Date()).endOf('day').format(),
      sellers: sellerList?.join(','),
      cancelToken: source.token,
    })
    const payouts = map(normalizePayout)(data)
    if (Array(...payouts).length === 0) {
      return rejectWithValue('Sin contenido')
    }
    return payouts
  },
  {
    condition: (_, { getState }) => {
      const state = getState().tables.payouts
      const stateDates = getState().ui.rangeOfDay
      if (state?.status === 'loading') {
        return false
      }
      const dates = stateDates.dates
      if (isNil(dates[1]) || typeof dates[1] === 'undefined') {
        return false
      }
    },
  }
)
export default fetchPayouts
