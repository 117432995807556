import styled from 'styled-components'
import Adorment from './Adorment'

const AdormentLeft = styled(Adorment)`
  font-size: 14px;
  line-height: 14px;
  padding-left: 14px;
`

export default AdormentLeft
