import React from 'react'
import AlertMUI from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import Text from '../Typography/Text'
import styled from 'styled-components'
import IconSuccess from '../Icon/IconSuccess'

export const typeAlert = new Map([
  ['finished', 'success'],
  ['error', 'error'],
  ['loading', 'info'],
])

const AlertStyled = styled(AlertMUI)`
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
`

const TextAlert = styled(Text)`
  color: #5c5c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin-left: 5px;
`

const Alert = ({
  isLoading,
  title,
  open,
  onClose,
  isError = false,
  anchorOrigin,
  isMultipleLoading,
  ...props
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={isLoading ? null : 6000}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }
    >
      <AlertStyled
        onClose={onClose}
        {...props}
        closeText="Cerrar"
        action={
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        icon={
          isMultipleLoading ? (
            <CircularProgress size={20} />
          ) : isLoading ? (
            <CircularProgress size={20} />
          ) : isError ? (
            <ErrorOutlineIcon />
          ) : (
            <IconSuccess />
          )
        }
      >
        <TextAlert>{title}</TextAlert>
      </AlertStyled>
    </Snackbar>
  )
}

export default Alert
