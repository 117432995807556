import { bool, func } from 'prop-types'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputSearch from '../../atomics/Input/InputSearch/InputSearch'

const height = '35px'
const Search = styled(InputSearch)`
  display: flex;
  flex: 4;
  width: 639px;
  .MuiOutlinedInput-root {
    padding: 0;
  }
  .MuiInputBase-input {
    padding-right: 12px;
    padding-left: 7px;
    padding-bottom: 0px;
    padding-top: 0px;
    height: ${height};
    color: ${({ theme }) =>
      theme.palette.type === 'dark' ? 'white' : 'black'};
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    cursor: pointer;
    font-size: 1.2em;
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-size: 10px 10px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACtSURBVHgBbZBBCsIwEEVnpl5NVETsNUS6qNB1pmuhIqV4DbuQUtzYE3ii/pgIhRDyIWFC3vz5DDf37ksiz0txqimhpu0MQLnMQG8BbdqHSUEWpO702XscPuvNjplIt/sDjcNrCiHnVlflWXnpvt5aFRHDIkoECiH/z+GoBfZ1CHlJCGYrsan6/46DeydrMcWZORU8yuzXplkK8gq2YdjdAtg8hhZVZeEMUGPG8QcpyXjHSYAGSAAAAABJRU5ErkJggg==');
  }
  @media (max-width: 700px) {
    width: 100%;
    flex: 0;
  }
`

const IconButton = styled(Button)`
  height: ${height};
  padding: 0px;
  margin: 0px;
  border-radius: 0;
  min-width: 40px;
  ${Search}:hover & {
    transition: border-left-color 10ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-left-color: #313234;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
  }
`

const SearchIconStyled = styled(SearchIcon)`
  fill: #8d9195;
  ${IconButton}:hover & {
    fill: #313234;
  }
`

const SearchInput = ({ isLoading, onClick, ...props }) => (
  <Search
    {...props}
    fullWidth
    type="search"
    id="search"
    placeholder={props?.placeholder ?? 'Buscar producto'}
    aria-label={props?.placeholder ?? 'buscar producto por su titulo'}
    InputProps={{
      startAdornment: (
        <InputAdornment position="end" style={{ margin: 0 }}>
          <IconButton
            data-testid="SearchInput__IconButton"
            onClick={onClick}
          >
            {isLoading ? (
              <CircularProgress
                size="1.2rem"
                data-testid="SearchInput__CircularProgress--loading"
              />
            ) : (
              <SearchIconStyled />
            )}
          </IconButton>
        </InputAdornment>
      ),
    }}
    onKeyDown={event => {
      if (event.key === 'Enter') {
        onClick()
      }
    }}
  />
)
SearchInput.propTypes = {
  isLoading: bool,
  onClick: func,
}

export default SearchInput
