import { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'

import ToolTip from '../../atomics/Tooltip'
import FieldInputController from '../../molecules/FieldInputController'
import Label from '../../atomics/Label'
import trash from '../../../assets/icon/trash.svg'

const StyleButtonFile = styled(Button)`
  box-sizing: border-box;
  justify-content: flex-end;
  height: 35px;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: center;
  text-decoration: underline;
  min-width: 25px;
  .MuiButtonBase-root {
    padding-left: 0;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
`

const StyledField = styled(FieldInputController)`
  width: 100%;
`

const InputContainer = styled('div')`
  display: grid;
  grid-template-columns: 49% 30px;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
`

const AddButton = styled(Button)`
  color: #2469ce;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  margin-top: 20px;
`

export function BrandsCard({ control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'brands',
  })
  useEffect(() => {
    append('')
  }, [append])

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      append('')
    }
  }

  return (
    <>
      <Label>Nombre marca</Label>
      {fields.map((field, index) => {
        return (
          <InputContainer key={field.id}>
            <StyledField
              defaultValue={field}
              name={`brands[${index}]`}
              control={control}
              placeholder="Ej: Simple Cook"
              onKeyUp={onKeyUp}
            />
            {fields.length > 1 ? (
              <ToolTip title="Eliminar">
                <StyleButtonFile
                  variant="text"
                  alt="delete"
                  onClick={() => remove(index)}
                >
                  <img src={trash} alt="delete" />
                </StyleButtonFile>
              </ToolTip>
            ) : null}
          </InputContainer>
        )
      })}
      <AddButton
        onClick={() => {
          append('')
        }}
      >
        + Agregar marca
      </AddButton>
    </>
  )
}

BrandsCard.propTypes = {
  control: PropTypes.any,
}

export default BrandsCard
