import LoadBar from '../../atomics/LoadBar'

const BarGraphic = ({ data }) => {
  return data?.map(d => (
    <div>
      <LoadBar percent={d.percent} skillname={d.name} type={d.type} />
    </div>
  ))
}
export default BarGraphic
