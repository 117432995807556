import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Label from '../../atomics/Label'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import styled from 'styled-components'
import TooltipHelp from '../TooltipHelp/TooltipHelp'

const ErrorText = styled('p')`
  font-size: 11px;
  margin: 0;
  color: #e72020;
`
const ContainerLabel = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 9px;
`
const FormLabel = styled(FormControlLabel)`
  font-size: 16px;
  margin-top: -8px;
  .MuiTypography-root {
    font-size: 16px;
  }
`

const FieldRadioController = ({
  control,
  name,
  defaultValue,
  label,
  required,
  options,
  tooltip,
  ...props
}) => {
  return (
    <>
      <ContainerLabel>
        {label && (
          <Label
            style={{ marginBottom: 0 }}
            required={required ? true : false}
          >
            {label}
          </Label>
        )}
        {tooltip && <TooltipHelp text={tooltip} />}
      </ContainerLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur, ref, ...fields },
          fieldState: { error },
        }) => {
          return (
            <RadioGroup
              {...{ name, ...props }}
              input={ref}
              onChange={onChange}
              onBlur={onBlur}
            >
              {options.map(field => (
                <FormLabel
                  key={field.value}
                  value={field.value}
                  control={<Radio />}
                  label={field.label}
                />
              ))}
              {error && <ErrorText>{error?.message}</ErrorText>}
            </RadioGroup>
          )
        }}
      />
    </>
  )
}

FieldRadioController.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
}
FieldRadioController.defaultProps = {
  required: false,
}

export default FieldRadioController
