import { createSelector } from '@reduxjs/toolkit'
import { orderResumeDetailAdapter } from './orderResumeDetailSlice'

const getStateorderResumeDetail = state => state.orderResumeDetail

export const getOrderResumeDetail = createSelector(
  [getStateorderResumeDetail],
  orderResumeDetail => orderResumeDetail
)

const orderStatusSelector = orderResumeDetailAdapter.getSelectors(
  getStateorderResumeDetail
)
export const getResumeDetail = orderStatusSelector.selectAll
