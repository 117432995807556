import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grow from '@material-ui/core/Grow'

import { allSellers } from '../../../domain/features/sellers/getSellers'
import { getCurrentDate } from '../../../domain/features/ui/rangeOfDaysSlice'
import {
  allOrders,
  getOrder,
} from '../../../domain/features/orders/getOrder'
import { allStatus } from '../../../domain/features/ui/dispatchedSlice'
import { allAccountingStatement } from '../../../domain/features/ui/accountingStatementSlice'
import fetchOrders from '../../../domain/features/orders/fetchOrders'

import DataTable from '../templates/DataTable'
import SelectWithChipsSellers from '../organims/SelectWithChipsSellers'
import RangeOfDays from '../organims/RangeOfDays'
import SelectDispatched from '../organims/SelectDispatched/SelectDispatched'
import SelectAccountingStatement from '../organims/SelectAccountingStatement'
import Search from '../organims/Search'
import MoreFilter from '../molecules/MoreFilter'
import FilterContainer from '../atomics/FilterContainer'
import Scroll from '../atomics/ScrollTable'
import Title from '../atomics/Typography/Title'
import CellNumber from '../atomics/Cell/CellNumber'
import useQueryParams from '../../hooks/useQueryParams'
import SelectType from '../organims/SelectType'
import { allTypes } from '../../../domain/features/ui/typeSlice'
import ButtonDownload from '../molecules/ButtonDownload/ButtonDownload'
import fetchOrderListDownload from '../../../domain/features/orderListDownload/fetchOrderListDownload'
import { getOrderListDownload } from '../../../domain/features/orderListDownload/getOrderListDownload'
import Alert, { typeAlert } from '../atomics/Alert/Alert'
import dayjs from 'dayjs'
import RangeOfDaysPayout from '../organims/RangeOfDaysPayout'
import { getCurrentDatePayout } from '../../../domain/features/ui/rangeOfDaysPayoutSlice'
import ChipManager from '../organims/ChipManager/ChipManager'
import styled from 'styled-components'
import FilterParent from '../atomics/FilterParent/FilterParent'
import LoadDocument from '../organims/LoadDocument'
import {
  deleteIdle,
  downloadIdle,
  getDeleteDocument,
  getDownloadDocument,
  getUpdateDocument,
} from '../../../domain/features/orders/orderSlice'
import { allDocument } from '../../../domain/features/ui/documentSlice'
import SelectDocument from '../organims/SelectDocument'
import { allchips } from '../../../domain/features/ui/chipsSlice'
import { useAuth } from '../pages/auth/useAuth'

const ILoadDocument = styled(LoadDocument)`
  padding: 5px;
  margin: 0px;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`
const ContainerSearch = styled(Box)`
  @media (max-width: 700px) {
    margin-right: 0px;
  }
`

const stateDownload = () =>
  new Map([
    ['finished', 'Descarga exitosa'],
    [
      'loading',
      'Descargando subpedidos, esto podria demorar varios minutos.',
    ],
    ['error', 'Error al descargar subpedidos'],
  ])

const Orders = () => {
  const dispatch = useDispatch()

  const auth = useAuth()

  const { status: downloadDocumentStatus } = useSelector(
    getDownloadDocument
  )
  const { status: updateDocumentStatus } = useSelector(getUpdateDocument)
  const { status: deleteDocumentStatus } = useSelector(getDeleteDocument)
  const allFilters = useSelector(allchips)
  const [showDocumentDownload, setShowDocumentDownload] = useState(false)
  const [showUpdateDocument, setShowUpdateDocument] = useState(false)

  const [showDeleteDocument, setShowDeleteDocument] = useState(false)

  const getActiveFilter = () => {
    if (allFilters.length > 0) {
      if (allFilters.length === 1) {
        const date = allFilters.find(f => f.label.indexOf('Fecha') > -1)
        if (date) {
          if (date.value.indexOf('Inicio') > -1) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }
  const isActiveFilter = getActiveFilter()
  const role = useMemo(
    () => String(auth?.currentUser?.role)?.toLowerCase(),
    [auth?.currentUser?.role]
  )
  const isViewer = role === 'viewer'

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha creación',
        accessor: 'date',
      },
      {
        Header: 'ID pedido shopify',
        accessor: 'parentId',
      },
      {
        Header: 'Id pedido seller',
        accessor: 'idOrder',
      },
      {
        Header: 'Seller',
        accessor: 'sellerName',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
      },
      {
        Header: 'Estado despacho',
        accessor: 'isDispatched',
      },
      {
        Header: 'Estado contable',
        accessor: 'accountingStatement',
      },
      {
        Header: 'Venta',
        accessor: 'totalSale',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Venta despachada',
        accessor: 'dispatchedSale',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Comisión',
        accessor: 'commission',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Otros descuentos',
        accessor: 'otherDiscount',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Payout',
        accessor: 'payout',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Fecha liquidación',
        accessor: 'payoutDate',
      },
      {
        Header: 'Documento tributario',
        accessor: 'documentName',
        Cell: cell => {
          return (
            <ILoadDocument
              value={cell.row.original.documentName}
              name={cell.row.original.idOrder}
              label={cell.row.original.documentType}
              role={role}
              disabled={downloadDocumentStatus === 'loading'}
            />
          )
        },
      },
    ],
    [role, downloadDocumentStatus]
  )

  const date = useSelector(getCurrentDate)
  const datePayout = useSelector(getCurrentDatePayout)
  const typeList = useSelector(allTypes)
  const statusList = useSelector(allStatus)
  const accountingStatementList = useSelector(allAccountingStatement)
  const documentList = useSelector(allDocument)
  const sellerList = useSelector(allSellers)
  const [query, setQuery] = React.useState(undefined)

  const queryParams = useQueryParams()
  const payoutId = useMemo(
    () => queryParams.get('payoutId'),
    [queryParams]
  )
  const sellerName = useMemo(
    () => queryParams.get('seller'),
    [queryParams]
  )

  const [pageCurrentIndex, setPage] = React.useState(0)
  const [perPage, setPerPage] = React.useState(25)

  useEffect(() => {
    /*if (payoutId && deburr(sellerList?.join(',')) === deburr(sellerName)) {
      return
    }*/

    const promise = dispatch(
      fetchOrders({
        startDate: date[0],
        endDate: date[1],
        startDatePayout: datePayout[0],
        endDatePayout: datePayout[1],
        status: statusList,
        accountingStatement: accountingStatementList,
        sellerList,
        shopifyId: query,
        skip: pageCurrentIndex || 0,
        limit: perPage,
        document: documentList,
        typeList,
      })
    )

    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    date,
    datePayout,
    statusList,
    accountingStatementList,
    documentList,
    sellerList,
    query,
    sellerName,
    payoutId,
    typeList,
    pageCurrentIndex,
    perPage,
  ])

  const data = useSelector(allOrders)
  const { pagination, status } = useSelector(getOrder)
  const [openFilter, setOpenFilter] = React.useState(false)

  const validStatusList = ['Facturado', 'Cancelado', 'Devuelto']

  const rows = React.useMemo(
    () =>
      status === 'loading'
        ? Array(perPage > 0 ? perPage : 25).fill({})
        : data,
    [status, data, perPage]
  )

  const handleClickOptionOne = useCallback(() => {
    dispatch(
      fetchOrderListDownload({
        startDate: dayjs(date[0]).isValid() ? date[0] : null,
        endDate: dayjs(date[1]).isValid() ? date[1] : null,
        startDatePayout: dayjs(datePayout[0]).isValid()
          ? datePayout[0]
          : null,
        endDatePayout: dayjs(datePayout[1]).isValid()
          ? datePayout[1]
          : null,
        status: statusList,
        accountingStatement: accountingStatementList,
        sellerList,
        shopifyId: query,
        typeList,
        document: documentList,
      })
    )
  }, [
    accountingStatementList,
    documentList,
    date,
    datePayout,
    dispatch,
    query,
    sellerList,
    statusList,
    typeList,
  ])

  const { status: statusDownload } = useSelector(getOrderListDownload)
  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(
    () => (statusDownload !== 'idle' ? setOpenAlert(true) : null),
    [statusDownload]
  )

  useEffect(() => {
    if (
      updateDocumentStatus === 'loading' ||
      updateDocumentStatus === 'error'
    ) {
      setShowUpdateDocument(true)
    } else {
      setShowUpdateDocument(false)
    }
    if (downloadDocumentStatus === 'loading') {
      setShowDocumentDownload(true)
    } else if (downloadDocumentStatus === 'finished') {
      setShowDocumentDownload(true)
      setTimeout(() => {
        dispatch(downloadIdle())
        setShowDocumentDownload(false)
      }, 5000)
    } else {
      setShowDocumentDownload(false)
    }
  }, [updateDocumentStatus, downloadDocumentStatus, dispatch])
  useEffect(() => {
    if (deleteDocumentStatus === 'loading') {
      setShowDeleteDocument(true)
    } else if (deleteDocumentStatus === 'finished') {
      setShowDeleteDocument(true)
      setTimeout(() => {
        setShowDeleteDocument(false)
        dispatch(deleteIdle())
      }, 5000)
    } else if (deleteDocumentStatus === 'error') {
      setTimeout(() => {
        setShowDeleteDocument(false)
      }, 5000)
    }
  }, [deleteDocumentStatus, dispatch])
  return (
    <>
      <Box mt="58px" mb="35px">
        <Title>Pedidos</Title>
      </Box>
      <Paper elevation={1}>
        <FilterParent>
          <FilterContainer>
            <ContainerSearch mr="25px">
              <Search
                type="number"
                value={query}
                onChange={e => {
                  setQuery(e.target.value.replace('#', ''))
                }}
              />
            </ContainerSearch>
            <RangeOfDays />
            <SelectWithChipsSellers />
            {!openFilter ? (
              <Grow
                in={!openFilter}
                style={{ transformOrigin: '0 0 0' }}
                {...(openFilter ? { timeout: 800 } : {})}
              >
                <MoreFilter
                  isPlus
                  text="Más Filtros"
                  onClick={() => setOpenFilter(true)}
                />
              </Grow>
            ) : (
              <>
                <SelectType />
                <SelectDispatched />
                <SelectAccountingStatement
                  isallfilter={true}
                  list={[
                    'No Liquidado',
                    'Facturado - No Pagado',
                    'Pagado',
                    'Contabilizado',
                  ]}
                />
                <RangeOfDaysPayout withborder={true} />
                <SelectDocument isallfilter={true} />
                <Grow
                  in={openFilter}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(openFilter ? { timeout: 800 } : {})}
                >
                  <MoreFilter
                    active={openFilter}
                    text="Menos Filtros"
                    onClick={() => setOpenFilter(false)}
                  />
                </Grow>
              </>
            )}
          </FilterContainer>
          <ButtonDownload
            type="select"
            isViewer={isViewer}
            handleClickOptionOne={handleClickOptionOne}
            toolTipText={
              !isActiveFilter
                ? 'Seleccione un filtro'
                : 'Descargar planilla'
            }
            disabled={
              isViewer ||
              !isActiveFilter ||
              downloadDocumentStatus === 'loading'
            }
            handleClickOptionTwo={null}
            optionOneText="Descargar pedidos"
          />
        </FilterParent>
        <ChipManager
          date={date}
          payoutDate={datePayout}
          type={typeList}
          status={statusList}
          accountingStatement={accountingStatementList}
          seller={sellerList}
          document={documentList}
        />
        <Paper elevation={2}>
          <Scroll>
            <DataTable
              isLoading={status === 'loading' || isEmpty(data)}
              isError={status === 'error'}
              columns={columns}
              data={rows?.map(({ otherDiscount, status, ...row }) => ({
                ...row,
                status,
                otherDiscount: validStatusList.some(
                  s => s === String(status)
                )
                  ? otherDiscount
                  : 0,
              }))}
              testId="pedidos"
              expand={false}
              withPage={true}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              pageCurrentIndex={pageCurrentIndex || 0}
              totalPage={pagination?.paginationTotalCount || 0}
            />
          </Scroll>
        </Paper>
      </Paper>
      <Alert
        key="product-create"
        open={openAlert}
        onClose={handleCloseAlert}
        title={stateDownload().get(statusDownload)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        isLoading={statusDownload === 'loading'}
        severity={typeAlert.get(statusDownload)}
        isError={statusDownload === 'error'}
      />
      <Alert
        key="upload-document"
        open={
          showUpdateDocument &&
          (updateDocumentStatus === 'error' ||
            updateDocumentStatus === 'loading')
        }
        onClose={() => {
          setShowUpdateDocument(false)
        }}
        title={
          updateDocumentStatus === 'loading'
            ? 'subiendo archivo'
            : updateDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : 'Archivo descargado exitosamente'
        }
        isLoading={updateDocumentStatus === 'loading'}
        isError={updateDocumentStatus === 'error'}
        severity={
          updateDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
      <Alert
        key="download-document"
        open={
          showDocumentDownload &&
          (downloadDocumentStatus === 'finished' ||
            downloadDocumentStatus === 'error' ||
            downloadDocumentStatus === 'loading')
        }
        onClose={() => {
          dispatch(downloadIdle())
          setShowDocumentDownload(false)
        }}
        title={
          downloadDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : downloadDocumentStatus === 'loading'
            ? 'Descargando archivo'
            : 'Archivo descargado exitosamente'
        }
        isLoading={downloadDocumentStatus === 'loading'}
        isError={downloadDocumentStatus === 'error'}
        severity={
          downloadDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
      <Alert
        key="delete-document"
        open={
          showDeleteDocument &&
          (deleteDocumentStatus === 'finished' ||
            deleteDocumentStatus === 'error' ||
            deleteDocumentStatus === 'loading')
        }
        onClose={() => {
          dispatch(deleteIdle())
          setShowDeleteDocument(false)
        }}
        title={
          deleteDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : deleteDocumentStatus === 'loading'
            ? 'Eliminando archivo'
            : deleteDocumentStatus === 'finished'
            ? 'Archivo eliminado exitosamente'
            : ''
        }
        isLoading={deleteDocumentStatus === 'loading'}
        isError={deleteDocumentStatus === 'error'}
        severity={
          deleteDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
    </>
  )
}
export default Orders
