import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductChangeImagePosition from './fetchProductChangeImagePosition'

export const productUpdateImagePositionAdapter = createEntityAdapter()

export const productChangeImagePositionSlice = createSlice({
  name: 'product/update/image/position',
  initialState: productUpdateImagePositionAdapter.getInitialState({
    status: 'idle',
    entities: {},
  }),
  reducers: {},
  extraReducers: {
    [fetchProductChangeImagePosition.pending]: state => {
      state.status = 'loading'
    },
    [fetchProductChangeImagePosition.rejected]: state => {
      state.status = 'error'
    },
    [fetchProductChangeImagePosition.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      productUpdateImagePositionAdapter.setOne(state, {
        status: payload,
      })
    },
  },
})

const reducer = productChangeImagePositionSlice.reducer
export default reducer
