import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { createAsyncThunk } from '@reduxjs/toolkit'
import subOrderService from '../../../data/services/subOrderService'
import dayjs from 'dayjs'
import { addSellers } from '../sellers/getSellers'
import { resetAverages } from '../payouts/getPayout'

const fetchLiquidate = createAsyncThunk(
  'liquidate/create',
  async (
    { startDate, endDate, sellerList },
    { rejectWithValue, dispatch, signal }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const data = await subOrderService.payoutsCreate({
        fromDate: dayjs(startDate).startOf('day').format(),
        toDate: dayjs(endDate).endOf('day').format(),
        seller: isEmpty(sellerList) ? undefined : sellerList?.join(','),
        cancelToken: source.token,
      })

      if (!isEmpty(data?.errors) || !isEmpty(data?.message)) {
        return rejectWithValue(data)
      }

      if (isEmpty(data?.errors)) {
        dispatch(addSellers([]))
        dispatch(resetAverages())
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchLiquidate
