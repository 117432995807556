import styled from 'styled-components'
import { array, string, object } from 'prop-types'
import Title from '../../atomics/Typography/Title'
import Label from '../../atomics/Label'
import InputController from '../InputController'

const GeneralInfoContainer = styled('div')`
  width: 100%;
  margin-top: 4%;
  @media (max-width: 1100px) {
    width: 100%;
    height: 100%;
    padding: 5%;
    padding-left: 1%;
  }
`

const TitleStyled = styled(Title)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 3.5%;
`

const SubContainer = styled('div')`
  display: flex;
  width: 100%;
`

const SubContainerColumn = styled(SubContainer)`
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 5%;
  margin-right: 3%;
  width: 30%;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    width: 100%;
  }
`

const SubContainerWrapped = styled(SubContainer)`
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    justify-content: flex-start;
    width: 100%;
  }
`

const InputStyled = styled(InputController)`
  .MuiFormLabel-root {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-align: left;
  }
`
const LabelStyled = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const OthersForms = ({ control, errors, items, title }) => {
  return (
    <GeneralInfoContainer>
      <TitleStyled>{title}</TitleStyled>
      <SubContainerWrapped>
        {items
          ? items.map(item => {
              return (
                <SubContainerColumn key={item.name}>
                  <LabelStyled>{item.name}</LabelStyled>
                  <InputStyled
                    placeholder={item.placeholder}
                    helperText={item.helperText}
                    errors={errors}
                    control={control}
                    name={item.nameController}
                  />
                </SubContainerColumn>
              )
            })
          : null}
      </SubContainerWrapped>
    </GeneralInfoContainer>
  )
}

OthersForms.propTypes = {
  items: array,
  title: string,
  control: object,
  errors: object,
}

export default OthersForms
