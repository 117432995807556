import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
} from '@material-ui/core'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import fetchOrderUpdateStatus from '../../../../domain/features/orderUpdateStatus/fetchOrderUpdateStatus'
import { getOrderUpdateStatus } from '../../../../domain/features/orderUpdateStatus/getOrderUpdateStatus'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Tooltip from '../../atomics/Tooltip/Tooltip'
import { statusColorMapper } from '../../../../domain/adpaters/map/statusColorMapper'
import Tag from '../../atomics/Tag'

const ButtonStyled = styled(Button)`
  background-color: ${({ status, theme }) =>
    status || theme.palette.warning.main};
  border-radius: 15px;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-left: 0.5rem;
  font-weight: 400;
  font-size: 14px;
  width: auto;
  height: 30px;
  white-space: nowrap;
  text-transform: none;
  text-align: center;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    background-color: ${({ status, theme }) =>
      status || theme.palette.warning.main};
    box-shadow: none;
  }

  @media (max-width: 1100px) {
    margin-top: 0.5rem;
  }
`

const ListItemStyle = styled(MenuItem)`
  cursor: pointer;
`
const Progress = styled(CircularProgress)`
  margin-right: 8px;
`
const StyledTag = styled(Tag)`
  width: 150px;
  height: 30px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding-top: 0px;
  @media (max-width: 700px) {
    width: 120px;
  }
`

const SelectUpdateStatus = ({ value, suborderId, isDispatched }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { status, suborder } = useSelector(getOrderUpdateStatus)
  const dispatch = useDispatch()

  const changeDispatched = useCallback(() => {
    handleClose()
    dispatch(fetchOrderUpdateStatus({ id: suborderId }))
  }, [suborderId, dispatch])

  if (
    (suborder?.status === 'Despachado' &&
      suborder?.type === 'Venta' &&
      suborder?.accountingStatement === 'No Liquidado') ||
    isDispatched
  ) {
    return (
      <Tooltip title="Estado despacho">
        <StyledTag
          status={statusColorMapper.get(suborder?.status || value)}
        >
          {suborder?.status || value}
        </StyledTag>
      </Tooltip>
    )
  }

  return (
    <>
      <ButtonStyled
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        elevation={0}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {status === 'loading' && <Progress size={'12px'} />} No Despachado
      </ButtonStyled>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ListItemStyle onClick={changeDispatched}>
          Despachado
        </ListItemStyle>
      </Menu>
    </>
  )
}

export default SelectUpdateStatus
