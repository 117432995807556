import isEmpty from 'lodash/isEmpty'
import { AES, enc } from 'crypto-js'
import Base64 from 'crypto-js/enc-base64'

/**
 * Local storage handler for SSR (Server Side Rendering).
 * Uses window.localStorage if available, otherwise provides mock functions.
 */
const localStorage =
  typeof window === 'undefined'
    ? {
        getItem: () => undefined,
        setItem: () => undefined,
        removeItem: () => undefined,
        clear: () => undefined,
      }
    : window.localStorage

/**
 * Keys used for storing data in local storage.
 * @constant {Object}
 * @property {string} MODETHEME - Key for storing theme mode.
 * @property {string} USER - Key for storing user data.
 * @property {string} TOKEN - Key for storing token.
 * @property {string} PRODUCTS - Key for storing products.
 * @property {string} CATEGORY - Key for storing category.
 * @property {string} BRAND - Key for storing brands.
 * @property {string} BRANDALL - Key for storing all brands with detail.
 * @property {string} SELLERONLYNAME - Key for storing sellers return only name's.
 * @property {string} SELLERONLYSLUG - Key for storing sellers return only slug's.
 */
const keyList = {
  MODETHEME: '@theme-light-or-dark',
  USER: '@user',
  TOKEN: '@token',
  PRODUCTS: '@products-backoffice',
  CATEGORY: '@category',
  BRAND: '@brands',
  BRANDALL: '@brands-detail',
  SELLERONLYNAME: '@sellers-name',
  SELLERONLYSLUG: '@sellers-slug',
}

/**
 * Secret key used for AES encryption and decryption.
 * @constant {string}
 */
const SECRET_KEY =
  'backoffice-)^8yV-xjU(6jXLpnkJM6;.hJ8)ME6P3W-)-~rKzhb<TZ#r%>f'

/**
 * Encrypts data using AES encryption.
 * @param {any} data - The data to be encrypted.
 * @returns {string} - The encrypted data.
 */
const encryptData = data =>
  AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()

/**
 * Decrypts data using AES decryption.
 * @param {string} data - The encrypted data to be decrypted.
 * @returns {any} - The decrypted data, or undefined if decryption fails.
 */
const decryptData = data => {
  try {
    return JSON.parse(AES.decrypt(data, SECRET_KEY).toString(enc.Utf8))
  } catch (error) {
    return undefined
  }
}

/**
 * Encodes data to base64 using crypto-js.
 * @param {any} data - The data to be encoded.
 * @returns {string} - The base64 encoded data.
 */
const encodeBase64 = data => {
  return Base64.stringify(enc.Utf8.parse(JSON.stringify(data)))
}

/**
 * Decodes base64 data using crypto-js.
 * @param {string} data - The base64 encoded data to be decoded.
 * @returns {any} - The decoded data.
 */
const decodeBase64 = data => {
  try {
    return JSON.parse(enc.Utf8.stringify(Base64.parse(data)))
  } catch (error) {
    return undefined
  }
}

/**
 * Retrieves an item from local storage and parses it.
 * @param {string} key - The key of the item to retrieve.
 * @returns {any} - The parsed item, or the original item if parsing fails.
 */
const getItem = key => {
  const item = localStorage.getItem(key)
  if (item === 'undefined') {
    return undefined
  }
  if (item === 'null') {
    return null
  }
  try {
    if (key === keyList.TOKEN) {
      return decodeBase64(item)
    }
    return decryptData(item)
  } catch (cause) {
    return item
  }
}

/**
 * Stringifies and stores an item in local storage.
 * @param {string} key - The key under which to store the item.
 * @param {any} value - The item to store.
 */
const setItem = (key, value) => {
  const item =
    key === keyList.TOKEN ? encodeBase64(value) : encryptData(value)
  localStorage.setItem(key, item)
}

/**
 * Removes an item from local storage.
 * @param {string} key - The key of the item to remove.
 */
const removeItem = key => localStorage.removeItem(key)

const PersistentStore = () => ({
  /**
   * Retrieves the theme mode from local storage.
   * @returns {string} - The stored theme mode.
   */
  getTheme: () => getItem(keyList.MODETHEME),

  /**
   * Stores the theme mode in local storage.
   * @param {string} theme - The theme mode to store.
   */
  setTheme: theme => setItem(keyList.MODETHEME, theme),

  getProducts: () => getItem(keyList.PRODUCTS),

  /**
   * Stores the products in local storage.
   * @param {Object[]} products - The products to store.
   */
  setProducts: products => setItem(keyList.PRODUCTS, products),

  /**
   * Retrieves the user data from local storage.
   * @returns {Object} - The stored user data.
   */
  getUser: () => getItem(keyList.USER),

  /**
   * Stores the user data in local storage.
   * @param {Object} user - The user data to store.
   */
  setUser: user => setItem(keyList.USER, user),

  /**
   * Removes the user data from local storage.
   */
  deleteUser: () => removeItem(keyList.USER),

  /**
   * Clears authentication-related data from local storage.
   */
  deleteAuth: () => {
    localStorage.clear()
  },

  /**
   * Retrieves the token from local storage.
   * @returns {string} - The stored token.
   */
  getToken: () => getItem(keyList.TOKEN),

  /**
   * Stores the token in local storage.
   * @param {string} token - The token to store.
   */
  setToken: token => setItem(keyList.TOKEN, token),

  /**
   * Removes the token from local storage.
   */
  deleteToken: () => removeItem(keyList.TOKEN),

  getBrands: () => getItem(keyList.BRAND),

  setBrands: brands => setItem(keyList.BRAND, brands),

  /**
   * Removes the brands names list data from local storage.
   */
  deleteBrands: () => removeItem(keyList.BRAND),
  /**
   * Checks if brands names data is stored in local storage.
   * @returns {boolean} - True if brands data is stored, false otherwise.
   */
  isBrands: () => !isEmpty(PersistentStore().getBrands()),

  getAllBrands: () => getItem(keyList.BRANDALL),

  setAllBrands: brands => setItem(keyList.BRANDALL, brands),

  deleteAllBrands: () => removeItem(keyList.BRANDALL),

  /**
   * Checks if brands data is stored in local storage.
   * @returns {boolean} - True if brands data is stored, false otherwise.
   */
  isAllBrands: () => !isEmpty(PersistentStore().getAllBrands()),

  getCategory: () => getItem(keyList.CATEGORY),

  setCategory: category => setItem(keyList.CATEGORY, category),

  /**
   * Removes the category data from local storage.
   */
  deleteCategory: () => removeItem(keyList.CATEGORY),

  /**
   * Checks if category data is stored in local storage.
   * @returns {boolean} - True if category data is stored, false otherwise.
   */
  isCategory: () => !isEmpty(PersistentStore().getCategory()),

  /**
   * Stores the sellers' names in local storage.
   * @param {string[]} sellerList - The sellers' names to store.
   */
  getSellersName: () => getItem(keyList.SELLERONLYNAME),

  setSellersName: sellerList =>
    setItem(keyList.SELLERONLYNAME, sellerList),

  deleteSellersName: () => removeItem(keyList.SELLERONLYNAME),
  /**
   * Checks if sellers data is stored in local storage.
   * @returns {boolean} - True if category data is stored, false otherwise.
   */
  isEmptySellersName: () => isEmpty(PersistentStore().getSellersName()),

  /**
   * Stores the sellers' slug in local storage.
   * @param {string[]} sellerList - The sellers' slug to store.
   */
  getSellersSlug: () => getItem(keyList.SELLERONLYSLUG),

  setSellersSlug: sellerList =>
    setItem(keyList.SELLERONLYSLUG, sellerList),

  deleteSellersSlug: () => removeItem(keyList.SELLERONLYSLUG),
  /**
   * Checks if sellers data is stored in local storage.
   * @returns {boolean} - True if category data is stored, false otherwise.
   */
  isEmptySellersSlug: () => isEmpty(PersistentStore().getSellersSlug()),
})

export default PersistentStore()
