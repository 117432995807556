import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import trash from '../../../assets/icon/trash.svg'
import Tooltip from '../Tooltip'
import { Link } from '@material-ui/core'

const Row = styled('div')`
  display: flex;
  flex-direction: row;
`

const StyleButtonFile = styled(Button)`
  box-sizing: border-box;
  color: black;
  justify-content: flex-start;
  height: 35px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  text-decoration: underline;
  min-width: 25px;
  .MuiButtonBase-root {
    padding-left: 0;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
`
export const StyleButtonFileClean = styled(Button)``
const StyleButtonLink = styled(Link)`
  color: black;
  text-decoration: underline;
`
const ButtonDeleteFile = ({ title, name, url, ...props }) => {
  return (
    <Row>
      <Tooltip title="Eliminar" name={name}>
        <StyleButtonFile
          variant="text"
          name={name}
          alt="delete"
          startIcon={<img src={trash} alt="delete" name={name} />}
          {...props}
        ></StyleButtonFile>
      </Tooltip>
      <Tooltip title="Descargar">
        <StyleButtonFile variant="text" {...props}>
          <StyleButtonLink href={url} target="blank">
            {title}
          </StyleButtonLink>
        </StyleButtonFile>
      </Tooltip>
    </Row>
  )
}
ButtonDeleteFile.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
}

export default ButtonDeleteFile
