import { createSelector } from '@reduxjs/toolkit'
import { orderUpdateStatusAdapter } from './orderUpdateStatusSlice'

const getStateOrderUpdateStatus = state => state.orderUpdateStatus

export const getOrderUpdateStatus = createSelector(
  [getStateOrderUpdateStatus],
  orderUpdateStatus => orderUpdateStatus
)

const orderStatusSelector = orderUpdateStatusAdapter.getSelectors(
  getStateOrderUpdateStatus
)

export const getUpdatedOrder = orderStatusSelector.selectAll
