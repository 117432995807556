import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import subOrderService from '../../../data/services/subOrderService'

const fetchDownloadDocument = createAsyncThunk(
  'orders/download/document',
  async ({ orderId }, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const { data } = await subOrderService.documentDownload({ orderId })
    const newBlob = new Blob([new Uint8Array(data.data)])

    const blobUrl = window.URL.createObjectURL(newBlob)

    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', `${orderId}.pdf`)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(newBlob)
  }
)
export default fetchDownloadDocument
