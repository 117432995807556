import React from 'react'
import styled from 'styled-components'
import FieldInputController from '../../molecules/FieldInputController'

const Row = styled('div')`
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 50%;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
  flex-direction: column;
`
const RedMessage = styled('div')`
    padding-top: 6px;
    font-size: 11px;
    line-height: 12.89px;
    margin-left: 0px;
    color: #e72020;
}`

const DeliveryCard = ({ control }) => {
  return (
    <Row>
      <div>
        <FieldInputController
          required
          name="idSellerEnviame"
          label="ID de Seller en Enviame"
          placeholder="ID Enviame"
          control={control}
          tooltip="Código identificador del seller proporcionado por Envíame"
        />
      </div>
      <div>
        <FieldInputController
          name="apiKey"
          label="Api Key Seller Center"
          placeholder="api-key"
          control={control}
          disabled={true}
          tooltip="API Key de servicios de Seller Center proporcionada por Kitchen Center."
        />
        <RedMessage>
          Tu api-key no está creada, solicitala a tu Product Manager.
        </RedMessage>
      </div>
    </Row>
  )
}

export default DeliveryCard
