import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { resetRangeOfDays } from '../../../../domain/features/ui/rangeOfDaysSlice'

import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import UserIconOffice from '@material-ui/icons/PersonOutlineOutlined'
import LogoutOutlined from '@material-ui/icons/ExitToApp'
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import SystemUpdateOutlinedIcon from '@material-ui/icons/SystemUpdateOutlined'
import IconButton from '@material-ui/core/IconButton'

import Bold from '../../atomics/Typography/Bold'
import Dropdown from '../../atomics/Dropdown/Dropdown'
import Text from '../../atomics/Typography/Text'

import Tooltip from '../../atomics/Tooltip/Tooltip'
import persistentStore from '../../../../data/persistentStore'
import { auth } from '../../pages/auth/firebaseConfig'
import ThemeModeButton from '../ThemeModeButton'
import useInstallPWA from '../../../hooks/useInstallPWA'
import { useWorker } from '../../../hooks/useWorker'
import { useNavigate } from 'react-router-dom'

const UserBackOficce = styled(UserIconOffice)`
  color: ${({ theme }) => theme.palette.info.main};
  cursor: pointer;
`
const IconContainer = styled('div')`
  margin-right: 0.5rem;
`

const UserContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 53px;
  transition: background-color 0.4s;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${({ theme }) =>
      theme.palette.type !== 'dark' ? '#202223' : '#313234'};
  }
`

const AvatarUser = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.info.main};
`

const UserDropdown = styled(Dropdown)`
  margin-top: 2px;
  color: ${({ theme }) => theme.palette.info.main};
`

const UserMenuItem = styled(MenuItem)`
  height: 35px;
  width: 210px;
`

const TextUser = styled(Bold)`
  color: ${({ theme }) => theme.palette.info.dark};
  @media (max-width: 700px) {
    margin: 0px;
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.type !== 'dark' ? '#202223' : '#313234'};
  }
`

const TextRole = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.info.light};
  @media (max-width: 700px) {
    margin: 0px;
  }
`

const TextUserHeader = styled(TextUser)`
  color: ${({ theme }) => theme.palette.info.main};
`

const TextRoleHeader = styled(TextRole)`
  color: ${({ theme }) => theme.palette.info.main};
`

const UserMenu = () => {
  const dispatch = useDispatch()

  const resetDate = React.useCallback(
    () => dispatch(resetRangeOfDays()),
    [dispatch]
  )

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = React.useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [])
  const navigate = useNavigate()
  const logout = React.useCallback(async () => {
    try {
      persistentStore.deleteAuth()
      await auth.signOut()
    } catch (error) {
      navigate('/ingresar', { replace: true, state: null })
    } finally {
      navigate('/ingresar', { replace: true, state: null })
    }
  }, [navigate])

  const user = persistentStore.getUser()

  const isMobile = useMediaQuery('(max-width:700px)')

  const { onInstallApp } = useInstallPWA()
  const { isUpdateWorker, reloadPage } = useWorker()

  return (
    <>
      <UserContainer onClick={handleClick}>
        {user?.picture ? (
          <AvatarUser src={user?.picture} alt={user?.displayName} />
        ) : (
          <Tooltip title="Abrir menú usuario">
            <IconButton
              color="inherit"
              aria-label="user"
              style={{ padding: '0px' }}
            >
              <UserBackOficce fontSize="large" />
            </IconButton>
          </Tooltip>
        )}
        {!isMobile && (
          <Box ml="10px" mr="10px">
            <TextUserHeader>{user?.displayName}</TextUserHeader>
            <TextRoleHeader>{user?.role?.toLowerCase()}</TextRoleHeader>
          </Box>
        )}
      </UserContainer>
      <UserDropdown
        id="logout"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isMobile && (
          <UserMenuItem disabled>
            <Box>
              <TextUser>{user?.displayName}</TextUser>
              <TextRole>{user?.role?.toLowerCase()}</TextRole>
            </Box>
          </UserMenuItem>
        )}
        <ThemeModeButton> Alternar color B/N</ThemeModeButton>{' '}
        {!isUpdateWorker && (
          <UserMenuItem onClick={onInstallApp}>
            <IconContainer>
              {isMobile ? (
                <SystemUpdateOutlinedIcon fontSize="small" />
              ) : (
                <GetAppOutlinedIcon fontSize="small" />
              )}
            </IconContainer>
            Descargar App
          </UserMenuItem>
        )}
        {isUpdateWorker && (
          <UserMenuItem onClick={reloadPage}>
            <IconContainer>
              <UpdateOutlinedIcon fontSize="small" />
            </IconContainer>
            Actualizar App
          </UserMenuItem>
        )}
        <UserMenuItem
          onClick={() => {
            resetDate()
            logout()
            handleClose()
          }}
        >
          <IconContainer>
            <LogoutOutlined fontSize="small" />
          </IconContainer>
          Cerrar Sesión
        </UserMenuItem>
      </UserDropdown>
    </>
  )
}

export default UserMenu
