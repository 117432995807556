import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { isOpenDrawer } from '../../../domain/features/ui/drawerSlice'
import { drawerWidth } from '../../components/templates/Drawer/Drawer'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const MarginAnimation = styled(animated('div'))`
  margin-right: 40px;
  @media (max-width: 1100px) {
    margin-right: 10px;
    margin-left: 10px;
  }
`
MarginAnimation.displayName = 'MarginAnimation'

const Margin = ({ children }) => {
  const open = useSelector(isOpenDrawer)
  const isMobile = useMediaQuery('(max-width:1100px)')
  const animation = useSpring({
    to: {
      marginLeft: !open ? '90px' : `${drawerWidth + 15}px`,
    },
    native: true,
  })

  return (
    <MarginAnimation style={isMobile ? null : animation}>
      {children}
    </MarginAnimation>
  )
}

export default Margin
