import React from 'react'
import { useNavigate } from 'react-router-dom'
import Login from './Login'
import { useAuth } from './useAuth'

const AuthWrapper = ({ children }) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (currentUser === null) {
      navigate('/ingresar')
    }
  }, [currentUser, navigate])

  if (currentUser === null) {
    return <Login />
  }

  return <>{children}</>
}

export default AuthWrapper
