import React from 'react'
import styled from 'styled-components'
import InputBaseMui from '@material-ui/core/TextField'

const InputBase = styled(props => <InputBaseMui {...props} />)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 3;
  max-width: 300px;
  width: 240px;
  min-width: 6.1rem;
  box-sizing: border-box;
  font-weight: 500;
  border-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#b7b7b7'};
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#202223'};
  .MuiOutlinedInput-input {
    padding-top: 9.5px;
    padding-right: 14px;
    padding-bottom: 9.5px;
    border-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#FFFFFF' : '#b7b7b7'};
  }

  @media (max-width: 1100px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`

const InputSearch = props => {
  return <InputBase variant="outlined" min={0} max={9999999} {...props} />
}

export default InputSearch
