import sellerServices from '../../../../data/services/sellerServices'

const fetchBrandUpdate = async data => {
  try {
    const updated = await sellerServices.brandsUpdate(data)
    return updated
  } catch (e) {
    return null
  }
}
export default fetchBrandUpdate
