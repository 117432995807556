import { createSelector } from '@reduxjs/toolkit'
import { campaignsAdapter } from './campaignsSlice'

const getStateCampaigns = state => state.campaigns.list

export const getCampaigns = createSelector(
  [getStateCampaigns],
  campaigns => campaigns
)

const campaignsSelectors = campaignsAdapter.getSelectors(getStateCampaigns)
export const allCampaigns = campaignsSelectors.selectAll
