import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchOrderResumeDetail from './fetchOrderResumeDetail'

export const orderResumeDetailAdapter = createEntityAdapter()

export const orderResumeDetailSlice = createSlice({
  name: 'update/suborder/status',
  initialState: orderResumeDetailAdapter.getInitialState({
    status: 'idle',
  }),
  extraReducers: builder => {
    builder.addCase(fetchOrderResumeDetail.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrderResumeDetail.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(
      fetchOrderResumeDetail.fulfilled,
      (state, { payload }) => {
        state.status = 'finished'
        state.resume = payload
      }
    )
  },
})

const reducer = orderResumeDetailSlice.reducer
export default reducer
