import React from 'react'
import icon from '../../../assets/icon/download_template.svg'

const IconDownloadTemplate = props => {
  return (
    <img
      src={icon}
      alt="icon-download"
      width="18px"
      height="18px"
      {...props}
    />
  )
}

export default IconDownloadTemplate
