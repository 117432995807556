import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchSellerDetail from './fetchSellerDetail'

export const sellerDetailAdapter = createEntityAdapter({
  selectId: seller => seller.slugSeller,
})

export const sellerDetailSlice = createSlice({
  name: 'sellers/detail',
  initialState: sellerDetailAdapter.getInitialState({ status: 'idle' }),
  reducers: {
    updateSellers: sellerDetailAdapter.setAll,
  },
  extraReducers: builder => {
    builder.addCase(fetchSellerDetail.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchSellerDetail.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchSellerDetail.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      sellerDetailAdapter.setAll(state, [payload])
    })
  },
})

const reducer = sellerDetailSlice.reducer
export default reducer
