import React from 'react'
import AccordionMUi from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'

const AccordionStyled = styled(AccordionMUi)`
  &.MuiAccordion-root:before {
    background-color: transparent;
  }
  .MuiAccordionSummary-root {
    padding: 0;
    width: 100%;
    background-color: transparent;
    min-height: auto;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiIconButton-edgeEnd {
    position: absolute;
    left: 10rem;
  }
  .MuiAccordionDetails-root {
    padding-top: 0px;
  }

  .MuiAccordionDetails-root {
    padding: 0px;
  }
`

export default function Accordion({ title, children, defaultExpanded }) {
  return (
    <AccordionStyled
      elevation={0}
      defaultExpanded={defaultExpanded || false}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title && title()}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionStyled>
  )
}
