import React from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  addRangeOfDays,
  getCurrentDate,
} from '../../../../domain/features/ui/rangeOfDaysSlice'
import InputRangeDate from '../../atomics/Input/InputRangeDate'

const RangeOfDays = () => {
  const date = useSelector(getCurrentDate)
  const dispatch = useDispatch()

  return (
    <InputRangeDate
      prefixName="Fecha"
      value={date}
      onChange={value => {
        dispatch(
          addRangeOfDays([
            dayjs(value[0]).startOf('day').toDate(),
            dayjs(value[1]).endOf('day').toDate(),
          ])
        )
      }}
    />
  )
}

export default RangeOfDays
