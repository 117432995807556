import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import ButtonDownloadOptions from '../../molecules/ButtonDownloadOptions/ButtonDownloadOptions'
import { useAuth } from '../../pages/auth/useAuth'

const DownloadContainer = styled('div')`
  display: flex;

  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;

  @media (max-width: 700px) {
    margin-left: 0rem;
  }
`

const LayoutFilter = styled(Box)`
  @media (max-width: 700px) {
    margin-left: 10px;
    width: 100%;
  }
`

const FilterLiquidationDowload = ({
  children,
  downloadOptions,
  enabledDownload,
  disabled,
}) => {
  const auth = useAuth()
  const isValid =
    String(auth?.currentUser?.role)?.toLowerCase() !== 'viewer' &&
    enabledDownload
  return (
    <LayoutFilter
      pt="25px"
      ml="20px"
      mr="20px"
      flexDirection="row"
      display="flex"
      alignItems="center"
    >
      <Box
        flexDirection="row"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
      >
        {children}
      </Box>
      <DownloadContainer>
        <ButtonDownloadOptions
          disabled={!isValid || disabled}
          type="select"
          options={downloadOptions}
        />
      </DownloadContainer>
    </LayoutFilter>
  )
}

export default FilterLiquidationDowload
