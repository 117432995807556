import { useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'

import ToolTip from '../../atomics/Tooltip'
import trash from '../../../assets/icon/trash.svg'
import FieldInputController from '../../molecules/FieldInputController'
import FieldCategory from '../../molecules/FieldCategory/FieldCategory'
import Label from '../../atomics/Label'
import Text from '../../atomics/Typography/Text'
import Title from '../../atomics/Typography/TitleProduct'
import { IconButton } from '@material-ui/core'

const StyleButtonFile = styled(IconButton)`
  box-sizing: border-box;
  height: 35px;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-decoration: underline;
  margin: 0px;
  .MuiButtonBase-root {
    padding-left: 0;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
`

const StyledField = styled(FieldInputController)`
  width: 100%;
  .MuiOutlinedInput-root {
    margin-top: 0;
  }
`

const StyledFieldGeneral = styled(StyledField)`
  width: 49%;
`
const InputContainer = styled('div')`
  display: grid;
  grid-template-columns: 97% 2%;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  margin-top: 10px;
`

const LabelContainerRight = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 3%;
`

const LabelContainerLeft = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 3%;
`

const StyledFieldCategory = styled(FieldCategory)`
  width: 100%;
  .MuiOutlinedInput-root {
    width: 100%;
  }
`

const CheckboxContainer = styled('div')`
  display: flex;
  margin-top: 3%;
  align-items: center;
  margin-left: -1%;
`

const AddButton = styled(Button)`
  color: #2469ce;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  margin-top: 10px;
`

const StyledCheckbox = styled(Checkbox)``

const CheckboxText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
`
const CategoryFieldsContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 30px;
`
const CategoryContainer = styled('div')`
  width: 100%;
`

const StyledTitle = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-bottom: 0px;
`

const Parragraph = styled('p')`
  margin-top: 5px;
  margin-bottom: 30px;
`

export function CommissionsCard({
  control,
  setValue,
  clearErrors,
  initialChecked,
  ...props
}) {
  const [checked, setChecked] = useState(initialChecked || false)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'commissionCategories',
  })

  const handleCheck = state => {
    setChecked(state)
    if (state) {
      append({ category: '', commission: '' })
    } else {
      setValue('commissionCategories', [])
      clearErrors('commissionCategories')
    }
  }

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      append({
        nameProductType: '',
        commissionProductType: '',
      })
    }
  }

  return (
    <>
      <StyledTitle>Comisión</StyledTitle>
      <Parragraph>
        Si se activa la comisión por categoría, esta reemplazará a la
        comisión global.
      </Parragraph>
      <Label required>Comisión global</Label>
      <StyledFieldGeneral
        defaultValue={''}
        name="commissionSeller"
        placeholder="Ej: 20"
        helperText="Ingresa una comisión global para el seller."
        control={control}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <CheckboxContainer>
        <StyledCheckbox
          checked={checked}
          onChange={() => handleCheck(!checked)}
        />
        <CheckboxText>Comisiones por categoría</CheckboxText>
      </CheckboxContainer>
      {checked ? (
        <InputContainer>
          <CategoryFieldsContainer>
            <LabelContainerRight>
              <Label required>Categoría</Label>
            </LabelContainerRight>
            <LabelContainerLeft>
              <Label required>Comisión</Label>
            </LabelContainerLeft>
          </CategoryFieldsContainer>
        </InputContainer>
      ) : null}
      {checked &&
        fields.map((field, index) => {
          return (
            <InputContainer key={field.id}>
              <CategoryFieldsContainer>
                <CategoryContainer>
                  <StyledFieldCategory
                    control={control}
                    defaultValue=""
                    name={`commissionCategories[${index}].nameProductType`}
                    labelRequired={false}
                    helperText=" "
                  />
                </CategoryContainer>
                <CategoryContainer>
                  <StyledField
                    defaultValue={field.name}
                    name={`commissionCategories[${index}].commissionProductType`}
                    control={control}
                    placeholder="Ej: 20"
                    onKeyUp={onKeyUp}
                    helperText="Ingresa una comisión para la categoría seleccionada."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </CategoryContainer>
              </CategoryFieldsContainer>
              {fields.length > 1 ? (
                <ToolTip title="Eliminar">
                  <StyleButtonFile
                    variant="text"
                    alt="delete"
                    onClick={() => remove(index)}
                    {...props}
                  >
                    <img src={trash} alt="delete" />
                  </StyleButtonFile>
                </ToolTip>
              ) : null}
            </InputContainer>
          )
        })}
      {checked ? (
        <AddButton
          
          onClick={() => {
            append({ nameProductType: '', commissionProductType: '' })
          }}
        >
          + Agregar categoría
        </AddButton>
      ) : null}
    </>
  )
}

CommissionsCard.propTypes = {
  control: PropTypes.any,
  clearError: PropTypes.any,
  setValue: PropTypes.any,
}

export default CommissionsCard
