import { createSelector } from '@reduxjs/toolkit'
import { orderDetailAdapter } from './orderDetailSlice'

const getStateOrder = state => state.oderDetail

export const getOrderDetail = createSelector(
  [getStateOrder],
  order => order
)

const orderSelectors = orderDetailAdapter.getSelectors(getStateOrder)
export const allOrderDetail = orderSelectors.selectAll
export const currentOrderDetail = parentId => state =>
  allOrderDetail(state).filter(({ id }) => String(id) === String(parentId))
export const afterSaleOrderList = parentId => state =>
  allOrderDetail(state).filter(({ id }) => String(id) !== String(parentId))
