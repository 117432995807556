import { Skeleton } from '@material-ui/lab'
import IconPencil from '../../../atomics/Icon/IconPencil'
import CardWithTitleAndButton from '../../../molecules/CardWithTitleAndButton/CardWithTitleAndButton'
import Spoiler from '../../../molecules/Spoiler/Spoiler'
import styled from 'styled-components'
import UserStatus from '../../../atomics/UserStatus/UserStatus'

const List = styled('ul')`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  width: 100%;
  display: inline-block;
`
const ListItem = styled('li')`
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 15px;
`
const BodyItem = styled('label')`
  font-weight: bold;
`
const TitleItem = styled('label')``

const ListUser = styled(List)``
const Space = styled('div')`
  margin-bottom: 40px;
`

const SellerUsers = ({ seller, statusSeller }) => {
  return (
    <CardWithTitleAndButton
      title={'Usuarios'}
      buttonTitle={<IconPencil />}
      buttonTooltip={'Editar usuarios'}
    >
      {statusSeller !== 'finished' && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      <Spoiler
        initialHeight={'330px'}
        showButton={(seller?.users || []).length > 2}
      >
        {(seller?.users || []).map((user, index) => {
          return (
            <ListUser key={user.uid}>
              <ListItem>
                <TitleItem>Nombre</TitleItem>:{' '}
                <BodyItem>{user.name}</BodyItem>
              </ListItem>
              <ListItem>
                <TitleItem>Teléfono</TitleItem>:{' '}
                <BodyItem>{user.phoneNumber || '-'}</BodyItem>
              </ListItem>
              <ListItem>
                <TitleItem>Email</TitleItem>:{' '}
                <BodyItem>{user.email}</BodyItem>
              </ListItem>
              <ListItem>
                <TitleItem>Cargo</TitleItem>:{' '}
                <BodyItem>{user.position || '-'}</BodyItem>
              </ListItem>
              <ListItem>
                <TitleItem>Estado</TitleItem>:{' '}
                <BodyItem>
                  <UserStatus
                    isActive={user.isActive}
                    firstLogin={user.firstLogin}
                  />
                </BodyItem>
              </ListItem>
              {seller?.users.length > index + 1 && <Space />}
            </ListUser>
          )
        })}
      </Spoiler>
    </CardWithTitleAndButton>
  )
}

export default SellerUsers
