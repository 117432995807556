import { createSelector } from '@reduxjs/toolkit'
import { brandsAdapter } from './brandsSlice'

const getStateBrands = state => state.brands

export const getBrands = createSelector([getStateBrands], brands => brands)

const brandsSelector = brandsAdapter.getSelectors(getStateBrands)
export const resultsBrands = brandsSelector.selectAll

export const getSellerByBrand = brandName => state =>
  getBrands(state)?.allBrands.find(brand => brandName === brand?.brandName)
