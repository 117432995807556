import { combineReducers } from '@reduxjs/toolkit'
import payoutReducer from '../features/payouts/payoutSlice'
import sellersReducer from '../features/sellers/sellerSlice'
import CampaignsReducer from '../features/campaigns/campaignsSlice'
import sellersCreateReducer from '../features/sellers/sellerCreate/sellerCreateSlice'
import sellersUpdateReducer from '../features/sellers/sellerUpdate/sellerUpdateSlice'
import sellersDetailReducer from '../features/sellerDetail/sellerDetailSlice'
import currentDateReducer from '../features/ui/rangeOfDaysSlice'
import currentDatePayoutReducer from '../features/ui/rangeOfDaysPayoutSlice'
import orderReducer from '../features/orders/orderSlice'
import orderDownloadReducer from '../features/orderListDownload/orderListDownloadSlice'
import orderResumeDetailReducer from '../features/orderResumeDetail/orderResumeDetailSlice'
import orderBillingReducer from '../features/orderBilling/orderBillingSlice'
import drawerSlice from '../features/ui/drawerSlice'
import dispatchedReducer from '../features/ui/dispatchedSlice'
import accountingStatementReducer from '../features/ui/accountingStatementSlice'
import liquidateReducer from '../features/liquidate/liquidateSlice'
import liquidationsDownloadReducer from '../features/liquidationListDownload/liquidationsDownloadSlice'
import payoutsDownloadReducer from '../features/payoutListDownload/payoutsDownloadSlice'
import liquidationsReducer from '../features/liquidations/liquidationsSlice'
import afterSaleReducer from '../features/afterSale/afterSaleSlice'
import orderDetailReducer from '../features/orderDetail/orderDetailSlice'
import typeReducer from '../features/ui/typeSlice'
import ProductListReducer from '../features/productSearchApi/productSlice'
import BrandReducer from '../features/brandsSlugSeller/brandsSlice'
import CategoryReducer from '../features/category/categorySlice'
import ProductDetailReducer from '../features/productDetail/productDetailSlice'
import ProductUpdateStatusReducer from '../features/productStatusUpdate/productUpdateStatusSlice'
import ProductUpdateReducer from '../features/productUpdate/productUpdateSlice'
import ProductCreateReducer from '../features/productCreate/productCreateSlice'
import ProductSkuReducer from '../features/productListBySku/productListBySkuSlice'
import ProductListDownloadSlicer from '../features/productListDownload/productListDownloadSlice'
import ProductExportAllByIdAdapter from '../features/productExportAllById/productExportAllByIdSlice'
import ProductChangePositionAdapter from '../features/productChangeImagePosition/productChangeImagePositionSlice'
import OrderUpdateStatusReducer from '../features/orderUpdateStatus/orderUpdateStatusSlice'
import reasonReducer from '../features/ui/reasonSlice'
import documentReducer from '../features/ui/documentSlice'
import chipReducer from '../features/ui/chipsSlice'

const reducers = combineReducers({
  ui: combineReducers({
    rangeOfDayPayout: currentDatePayoutReducer,
    rangeOfDay: currentDateReducer,
    drawer: drawerSlice,
    dispatched: dispatchedReducer,
    accountingStatement: accountingStatementReducer,
    type: typeReducer,
    document: documentReducer,
    reason: reasonReducer,
    chips: chipReducer,
  }),
  afterSale: afterSaleReducer,
  orderUpdateStatus: OrderUpdateStatusReducer,
  oderDetail: orderDetailReducer,
  orderBilling: orderBillingReducer,
  orderDownload: orderDownloadReducer,
  orderResumeDetail: orderResumeDetailReducer,
  liquidate: liquidateReducer,
  liquidationDownload: liquidationsDownloadReducer,
  payoutsDownload: payoutsDownloadReducer,
  tables: combineReducers({
    payouts: payoutReducer,
    orders: orderReducer,
    liquidations: liquidationsReducer,
  }),
  sellers: combineReducers({
    list: sellersReducer,
    create: sellersCreateReducer,
    update: sellersUpdateReducer,
    detail: sellersDetailReducer,
  }),
  products: combineReducers({
    list: ProductListReducer,
    create: ProductCreateReducer,
    detail: ProductDetailReducer,
    detailStatus: ProductUpdateStatusReducer,
    update: ProductUpdateReducer,
    sku: ProductSkuReducer,
    download: ProductListDownloadSlicer,
    exportAllById: ProductExportAllByIdAdapter,
    changePosition: ProductChangePositionAdapter,
  }),
  campaigns: combineReducers({
    list: CampaignsReducer,
  }),
  brands: BrandReducer,
  category: CategoryReducer,
})

export default reducers
