import { Skeleton } from '@material-ui/lab'
import CardWithTitleAndButton from '../../../molecules/CardWithTitleAndButton/CardWithTitleAndButton'
import Spoiler from '../../../molecules/Spoiler/Spoiler'
import styled from 'styled-components'
import { CardButton } from '../seller.components'
import { useCallback, useState } from 'react'
import Modal from '../../../atomics/Modal/Modal'
import CardWithTitle from '../../../molecules/CardWithTitle/CardWithTitle'
import StaticAlert from '../../../atomics/StaticAlert/StaticAlert'
import CancelButton from '../../../atomics/Button/CancelButton'
import SubmitButton from '../../../atomics/Button/SubmitButton/SubmitButton'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { getSellerUpdate } from '../../../../../domain/features/sellers/sellerUpdate/getSellersUpdate'
import FieldCheckboxController from '../../../molecules/FieldCheckboxController/FieldCheckboxController'
import BrandsEditCard from '../../BrandsEditCard/BrandsEditCard'
import fetchBrandUpdate from '../../../../../domain/features/sellers/brandUpdate/fetchBrandUpdate'
import fetchBrandCheck from '../../../../../domain/features/sellers/brandCheck/fetchBrandCheck'
import { yupResolver } from '@hookform/resolvers/yup'
import { schemaBrands } from '../../../../../domain/schemas/sellerUpdateSchema'
import ButtonCopy from '../../../atomics/ButtonCopy/ButtonCopy'

const List = styled('ul')`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  width: 100%;
  display: inline-block;
`
const ListItem = styled('li')`
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 15px;
`
const BodyItem = styled('label')`
  font-weight: bold;
`
const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  margin-top: 4px;
  margin-right: 4%;
`

const ButtonCancel = styled(CancelButton)`
  background: #ffffff;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
  color: #6b6b6b;
  box-shadow: none;
`

const ErrorText = styled('p')`
  color: #e72020;
`

const SellerBrands = ({ seller, statusSeller, role, onUpdate }) => {
  const [productsEditAlert, setProductsEditAlert] = useState(0)
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaBrands),
  })

  const [visibleModal, setVisibleModal] = useState(false)
  const openModal = () => {
    setValue('brands', [
      ...(seller?.brands || []).map(brand => ({
        name: brand.brandName,
        initial: brand.brandName,
      })),
    ])
    reset({
      brands: [
        ...(seller?.brands || []).map(brand => ({
          name: brand.brandName,
          initial: brand.brandName,
        })),
      ],
    })
    setVisibleModal(true)
  }
  const closeModal = () => {
    onUpdate()
    setVisibleModal(false)
  }
  const onSubmit = handleSubmit(
    useCallback(
      async data => {
        const allNewBrands = []
        ;(data.brands || []).forEach(brand => {
          if (brand.initial && brand.initial !== brand.name) {
            allNewBrands.push(brand.initial)
          }
        })
        let totalProducts = { total: 0 }
        if (allNewBrands.length > 0) {
          totalProducts = await fetchBrandCheck({
            sellerName: seller?.nameSeller,
            brands: allNewBrands,
          })
        }

        if (totalProducts?.total > 0 && productsEditAlert === 0) {
          setProductsEditAlert(totalProducts?.total)
        } else if (productsEditAlert > 0 && !data.accept) {
          setError('accept', {
            type: 'manual',
            message:
              'Para continuar, debes indicar que haz entendido el mensaje. ',
          })
        } else {
          setProductsEditAlert(0)
          for (let i = 0; i < (data.brands || []).length; i++) {
            const brand = data.brands[i]
            if (brand.initial) {
              if (
                brand.initial !== brand.name &&
                brand.name.trim() !== ''
              ) {
                await fetchBrandUpdate({
                  sellerName: seller?.nameSeller,
                  brandName: brand.initial,
                  newBrandName: brand.name,
                })
              }
            } else {
              if (brand.name && brand.name.trim() !== '') {
                await fetchBrandUpdate({
                  sellerName: seller.nameSeller,
                  brandName: brand.name,
                  newBrandName: brand.name,
                })
              }
            }
          }
          onUpdate()
          setVisibleModal(false)
        }
      },
      [productsEditAlert, seller?.nameSeller, setError, onUpdate]
    )
  )
  const { status } = useSelector(getSellerUpdate)

  const getCopyText = () => {
    return `
      Nombre empresa (seller): ${seller?.nameSeller || ''}
      Rut empresa: ${seller?.rut || ''}
      Marcas: ${(seller?.brands || [])
        .map(brand => brand.brandName)
        .join(', ')}
    `
  }

  return (
    <>
      <Modal open={visibleModal} onClose={closeModal}>
        <CardWithTitle title="Editar marcas" style={{ width: '800px' }}>
          <BrandsEditCard
            control={control}
            sellerName={seller?.nameSeller}
            setError={setError}
            errors={errors}
            productsEditAlert={productsEditAlert}
          />

          {errors.brands?.message && (
            <ErrorText>{errors.brands?.message}</ErrorText>
          )}

          {productsEditAlert > 0 && (
            <>
              <StaticAlert
                color="#C58F42"
                style={{ width: '70%' }}
                title={`Estás editando 1 o más marcas asociadas a ${
                  productsEditAlert === 21 ? '+20' : productsEditAlert
                } productos. Esta(s) será(n) reemplazada(s) por la nueva marca que estás ingresando.`}
              />
              <FieldCheckboxController
                control={control}
                defaultValue={false}
                name="accept"
                label="Entendido, editar de todas formas."
                showIconError={true}
              />
            </>
          )}

          <ButtonContainer>
            <ButtonCancel onClick={closeModal}>Cancelar</ButtonCancel>
            <SubmitButton
              color={'#65B8D6'}
              onClick={onSubmit}
              disabled={role === 'viewer'}
              isLoading={isSubmitting || status === 'loading'}
            >
              Guardar cambios
            </SubmitButton>
          </ButtonContainer>
        </CardWithTitle>
      </Modal>

      <CardWithTitleAndButton
        title={'Marcas'}
        render={
          statusSeller === 'finished' &&
          seller &&
          ['admin', 'operations'].includes(role)
            ? () => (
                <>
                  <ButtonCopy
                    title={`Copiar marcas de ${seller?.nameSeller}`}
                    getText={getCopyText}
                    message={`${seller?.nameSeller} marcas copiadas!`}
                  />
                  <CardButton title="Editar marcas" onClick={openModal} />
                </>
              )
            : null
        }
      >
        {statusSeller !== 'finished' && (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
        <Spoiler
          initialHeight={'270px'}
          showButton={(seller?.brands || []).length > 9}
        >
          <List>
            {(seller?.brands || []).map(br => {
              return (
                <ListItem key={br._id}>
                  <BodyItem>{br.brandName}</BodyItem>
                </ListItem>
              )
            })}
          </List>
        </Spoiler>
      </CardWithTitleAndButton>
    </>
  )
}

export default SellerBrands
