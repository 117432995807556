import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import deburr from 'lodash/deburr'
import isArray from 'lodash/isArray'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useSpring, animated } from 'react-spring'
import ErrorIcon from '@material-ui/icons/Error'

import {
  allSellers,
  getSellers,
} from '../../../domain/features/sellers/getSellers'
import { getCurrentDate } from '../../../domain/features/ui/rangeOfDaysSlice'
import {
  allOrders,
  getOrder,
} from '../../../domain/features/orders/getOrder'
import { allStatus } from '../../../domain/features/ui/dispatchedSlice'
import { allAccountingStatement } from '../../../domain/features/ui/accountingStatementSlice'
import dateOperations from '../../../domain/adpaters/days/dateOperations'
import fetchOrdersAftersale from '../../../domain/features/orders/fetchOrdersAftersale'
import fetchOrderListAftersaleDownload from '../../../domain/features/orderListDownload/fetchOrderListAftersaleDownload'
import fetchOrderResumeDetail from '../../../domain/features/orderResumeDetail/fetchOrderResumeDetail'
import { getOrderResumeDetail } from '../../../domain/features/orderResumeDetail/getOrderResumeDetail'
import { getOrderListDownload } from '../../../domain/features/orderListDownload/getOrderListDownload'
import { allTypes } from '../../../domain/features/ui/typeSlice'
import { allReason } from '../../../domain/features/ui/reasonSlice'

import useQueryParams from '../../hooks/useQueryParams'

import DataTable from '../templates/DataTable'
import SelectWithChipsSellers from '../organims/SelectWithChipsSellers'
import RangeOfDays from '../organims/RangeOfDays'
import Search from '../organims/Search'
import Scroll from '../atomics/Scroll'
import Title from '../atomics/Typography/Title'
import SelectType from '../organims/SelectType'
import ButtonDownload from '../molecules/ButtonDownload/ButtonDownload'
import Alert, { typeAlert } from '../atomics/Alert/Alert'
import ChipManager from '../organims/ChipManager/ChipManager'
import SelectReason from '../organims/SelectReason'
import BarGraphic from '../molecules/BarGraphic'
import FilterParent from '../atomics/FilterParent/FilterParent'
import Row from '../atomics/Styles/Row'
import Text from '../atomics/Typography/Text'
import CellHeaderBase from '../atomics/Cell/CellHeader'
import Tooltip from '../atomics/Tooltip'
import { useAuth } from '../pages/auth/useAuth'

const CellHeader = styled(CellHeaderBase)`
  white-space: normal;
  padding: 0;
  margin: 0;
  border-bottom: 0;
  width: auto;
`

const LineFilterContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  margin-left: 20px;
  @media (max-width: 700px) {
    margin-left: 10px;
  }
`

const SearchOrderId = styled(Search)`
  max-width: 622.62px;
  width: 100%;
  @media (max-width: 700px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const ContainerSearch = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-right: 25px;
  padding-left: 25px;
  @media (max-width: 700px) {
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
`

const Card = styled('div')`
  border: 1.00343px solid #6b6b6b;
  border-radius: 12px 12.0412px 12.0412px 12.0412px;
`

const CardMain = styled(Card)`
  box-sizing: border-box;
  margin: 10px;
  margin-top: 25px;
  padding-top: 35px;

  @media (max-width: 700px) {
    padding: 10px;
    margin: 10px;
  }
`

const CardSeller = styled(Card)`
  box-sizing: content-box;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 35px;
  margin-right: 10px;
  margin-left: 10px;
  max-width: 502.13px;
  width: 100%;
  @media (max-width: 700px) {
    padding: 5px;
    margin-right: 5px;
    margin-left: 5px;
    padding-top: 10px;
    max-width: 100%;
  }
`

const CardGrahp = styled(CardSeller)`
  max-width: 502.13px;
`

const TitleSeller = styled(Text)`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
`

const TextBrand = styled(Text)`
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 10px;
`

const TextBrandMain = styled(TextBrand)`
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 0px;
`

const TextGraph = styled(Text)`
  margin-bottom: 10px;
`

const ScrollTable = styled(Scroll)`
  padding-left: 20px;
  @media (max-width: 700px) {
    padding-left: 0px;
  }
`

const ButtonDownloadStyled = styled(ButtonDownload)`
  display: flex;
`

const ResumeContainer = styled(animated('div'))`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const TextDate = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
`

const TextAfterSale = styled(Text)`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7c7c7c;
`

const RowSeller = styled(Row)`
  align-items: center;
  margin-bottom: 10px;
`

const ErrorAlert = styled(ErrorIcon)`
  fill: #7c7c7c;
  margin-left: 7px;
  margin-right: 7px;
  width: 15px;
  height: 15px;
`

const RowAfterSale = styled(Row)`
  margin-top: 7%;
`

const stateDownload = () =>
  new Map([
    ['finished', 'Descarga exitosa'],
    [
      'loading',
      'Descargando subpedidos, esto podria demorar varios minutos.',
    ],
    ['error', 'Error al descargar subpedidos'],
  ])

const Operations = () => {
  const dispatch = useDispatch()

  const { currentUser: user } = useAuth()
  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )
  const isViewer = role === 'viewer'

  const columns = useMemo(
    () => [
      {
        Header: 'Pedido',
        accessor: 'idOrder',
      },
      {
        Header: 'Seller',
        accessor: 'sellerName',
      },
      {
        Header: () => <CellHeader>Tipo de postventa</CellHeader>,
        accessor: 'type',
      },
      {
        Header: () => <CellHeader>Estado postventa</CellHeader>,
        accessor: 'isDispatched',
      },
      {
        Header: () => <CellHeader>Estado contable</CellHeader>,
        accessor: 'accountingStatement',
      },
      {
        Header: 'Fecha venta',
        accessor: 'date',
      },
      {
        Header: () => <CellHeader>Fecha creación postventa</CellHeader>,
        accessor: 'afterSaleCreatedAt',
      },
      {
        Header: () => (
          <Tooltip title="Última fecha de cambio del estado de la postventa.">
            <CellHeader>
              <i>Última fecha actualización</i>
            </CellHeader>
          </Tooltip>
        ),
        accessor: 'afterSaleUpdatedAt',
      },
      {
        Header: () => (
          <Tooltip title="Días que transcurren desde la fecha de creación de postventa y su resolución.">
            <CellHeader>
              <i>Tiempo de resolución</i>
            </CellHeader>
          </Tooltip>
        ),
        accessor: 'kpi',
      },
      {
        Header: () => (
          <Tooltip title="Motivo de la postventa varía según el tipo de la postventa (cancelación, reenvío y devolución).">
            <CellHeader>
              <i>Motivo postventa</i>
            </CellHeader>
          </Tooltip>
        ),
        accessor: 'reason',
      },
    ],
    []
  )

  const date = useSelector(getCurrentDate)
  const { status: resumeDetailStatus, resume } = useSelector(
    getOrderResumeDetail
  )
  const typeList = useSelector(allTypes)
  const statusList = useSelector(allStatus)
  const accountingStatementList = useSelector(allAccountingStatement)
  const sellerList = useSelector(allSellers)
  const reasonList = useSelector(allReason)
  const allSellersState = useSelector(getSellers)
  const [query, setQuery] = React.useState(undefined)

  const queryParams = useQueryParams()
  const payoutId = useMemo(
    () => queryParams.get('payoutId'),
    [queryParams]
  )
  const sellerName = useMemo(
    () => queryParams.get('seller'),
    [queryParams]
  )

  const [pageCurrentIndex, setPage] = React.useState(0)
  const [perPage, setPerPage] = React.useState(25)

  const [barGraphicData, setBarGraphicData] = React.useState([])
  const [totalSales, setTotalSales] = React.useState([])
  const transformToGraph = data => {
    const keys = Object.keys(data)
    const afterSale = keys
      ?.filter(key => key !== 'Venta')
      .reduce((acc, key) => {
        acc = acc + data[key]
        return acc
      }, 0)
    const sales = keys
      ?.filter(key => key === 'Venta')
      .reduce((acc, key) => {
        acc = acc + data[key]
        return acc
      }, 0)

    setTotalSales(sales)

    const text = new Map([
      ['Devolución', 'Devoluciones'],
      ['Cancelación', 'Cancelaciones'],
      ['Reenvío', 'Reenvíos'],
    ])

    const graph = keys?.map(key => {
      return {
        name: `${data[key]} ${data[key] > 1 ? text.get(key) : key}`,
        value: data[key],
        type: key,
        percent: Math.round((data[key] * 100) / afterSale),
      }
    })

    return [
      ...graph,
      {
        name: `${afterSale} total de post ventas`,
        value: afterSale,
        type: 'postventa',
        percent: Math.round((afterSale * 100) / sales),
      },
    ]
  }

  const parseResumeData = data => {
    const parsedData = data?.reduce((acc, sellerResume) => {
      sellerResume?.detail?.map(detail => {
        acc[detail.type] = (acc[detail.type] || 0) + detail.count
        return undefined
      })
      return acc
    }, {})

    return transformToGraph(parsedData)
  }
  useEffect(() => {
    if (resumeDetailStatus === 'finished' && resume?.length > 0) {
      const data = parseResumeData(resume)

      setBarGraphicData(data)
    }
    // eslint-disable-next-line
  }, [resumeDetailStatus, resume])

  useEffect(() => {
    if (payoutId && deburr(sellerList?.join(',')) === deburr(sellerName)) {
      return
    }
    if (isArray(sellerList) && sellerList.length > 0) {
      dispatch(
        fetchOrderResumeDetail({
          startDate: date[0],
          endDate: date[1],
          sellerList,
          shopifyId: query,
          typeList,
          reasonList,
        })
      )
    }

    const promise = dispatch(
      fetchOrdersAftersale({
        startDate: date[0],
        endDate: date[1],
        sellerList,
        shopifyId: query,
        skip: pageCurrentIndex || 0,
        limit: perPage,
        typeList,
        reasonList,
      })
    )

    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    date,
    sellerList,
    query,
    sellerName,
    payoutId,
    typeList,
    pageCurrentIndex,
    perPage,
    reasonList,
  ])

  const data = useSelector(allOrders)
  const { pagination, status } = useSelector(getOrder)

  const rows = React.useMemo(
    () =>
      status === 'loading'
        ? Array(perPage > 0 ? perPage : 25).fill({})
        : data,
    [status, data, perPage]
  )

  const handleClickOptionOne = useCallback(() => {
    dispatch(
      fetchOrderListAftersaleDownload({
        startDate: dayjs(date[0]).isValid() ? date[0] : null,
        endDate: dayjs(date[1]).isValid() ? date[1] : null,
        sellerList,
        shopifyId: query,
        typeList,
      })
    )
  }, [date, dispatch, query, sellerList, typeList])

  const handleClickOptionTwo = useCallback(() => {
    dispatch(
      fetchOrderListAftersaleDownload({
        startDate: null,
        endDate: null,
        status: [],
        accountingStatement: [],
        sellerList: [],
        shopifyId: null,
        typeList: [],
      })
    )
  }, [dispatch])

  const { status: statusDownload } = useSelector(getOrderListDownload)
  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(
    () => (statusDownload !== 'idle' ? setOpenAlert(true) : null),
    [statusDownload]
  )

  const findSellerInfo = sellerName => {
    const finded = allSellersState.sellers.find(
      seller => seller.nameSeller === sellerName
    )
    return finded
  }

  const fade = useSpring({
    to: { opacity: isEmpty(sellerList) ? 0 : 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
    native: true,
    reset: true,
  })

  return (
    <>
      <Box
        mt="58px"
        mb="35px"
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Title>Analítica Post Ventas</Title>
        <TextDate>{dateOperations(new Date())}</TextDate>
      </Box>

      <Paper elevation={1}>
        <FilterParent>
          <LineFilterContainer>
            <RangeOfDays />
            <SelectWithChipsSellers />
            <SelectType
              isallfilter={true}
              label="Tipo de postventa"
              list={['Devolución', 'Cancelación', 'Reenvío']}
            />
            <SelectReason isallfilter={false} />
          </LineFilterContainer>
        </FilterParent>
        <ChipManager
          date={date}
          type={typeList}
          status={statusList}
          accountingStatement={accountingStatementList}
          seller={sellerList}
          reason={reasonList}
        />
        <ResumeContainer style={fade}>
          <Collapse
            in={
              resume?.length === 1 &&
              sellerList.length === 1 &&
              resumeDetailStatus !== 'loading'
            }
          >
            <Row>
              <CardSeller>
                <div>
                  {resume?.map(res => {
                    const sellerDetail = findSellerInfo(res.seller)
                    return (
                      <div key={res.updatedAt}>
                        <TitleSeller>{res.seller}</TitleSeller>

                        <TextBrand>
                          Fecha Integración:{' '}
                          {sellerDetail.createdAt
                            ? dateOperations(sellerDetail.createdAt)
                            : dateOperations(sellerDetail.updatedAt)}
                        </TextBrand>

                        <TextBrand>
                          Marcas:{' '}
                          {sellerDetail?.brands
                            ?.map(s => s.brandName)
                            .join(', ')}
                        </TextBrand>
                      </div>
                    )
                  })}
                </div>
              </CardSeller>
              <CardGrahp>
                <div>
                  <TextGraph>
                    Dentro del período seleccionado, este seller cuenta con
                    <b> {totalSales} pedidos vendidos</b>, de las cuales
                    las siguientes son postventas:
                  </TextGraph>
                  <BarGraphic
                    data={barGraphicData.filter(
                      graphicData => graphicData.type !== 'Venta'
                    )}
                  />
                </div>
              </CardGrahp>
            </Row>
          </Collapse>

          <Collapse
            in={sellerList.length > 1 && resumeDetailStatus !== 'loading'}
          >
            <Row>
              <CardSeller>
                <div>
                  <TitleSeller>
                    Sellers seleccionados ({sellerList.length}):{' '}
                  </TitleSeller>
                  {sellerList?.map(sellerName => {
                    const sellerDetail = findSellerInfo(sellerName)

                    return (
                      <RowSeller key={sellerName}>
                        <TextBrandMain>
                          <b>{sellerName}</b>:
                          {` ${sellerDetail.brands.length} marcas`}
                        </TextBrandMain>{' '}
                        {!resume?.find(
                          seller => seller?.seller === sellerName
                        )?.isAfterSale && (
                          <>
                            <ErrorAlert />
                            <TextAfterSale>
                              Marca sin postventa
                            </TextAfterSale>
                          </>
                        )}
                      </RowSeller>
                    )
                  })}
                </div>
              </CardSeller>
              <CardGrahp>
                <div>
                  <TextGraph>
                    Dentro del período seleccionado, estos sellers cuentan
                    con <b> {totalSales} pedidos vendidos</b>, de las
                    cuales las siguientes son postventas:
                  </TextGraph>
                  <BarGraphic
                    data={barGraphicData.filter(
                      graphicData => graphicData.type !== 'Venta'
                    )}
                  />
                  {!resume?.find(seller => !seller?.isAfterSale)
                    ?.isAfterSale && (
                    <RowAfterSale>
                      <ErrorAlert />
                      <TextAfterSale>
                        Algunas de las marcas seleccionadas no presentan
                        postventa.
                      </TextAfterSale>
                    </RowAfterSale>
                  )}
                </div>
              </CardGrahp>
            </Row>
          </Collapse>
        </ResumeContainer>
        <CardMain>
          <ContainerSearch>
            <SearchOrderId
              type="number"
              value={query}
              onChange={e => {
                setQuery(e.target.value.replace('#', ''))
              }}
            />
            <ButtonDownloadStyled
              type="select"
              isViewer={isViewer}
              handleClickOptionOne={handleClickOptionOne}
              handleClickOptionTwo={handleClickOptionTwo}
            />
          </ContainerSearch>

          <ScrollTable>
            <DataTable
              isLoading={status === 'loading' || isEmpty(data)}
              isError={status === 'error'}
              columns={columns}
              data={rows?.map(({ status, ...row }) => ({
                ...row,
                status,
                to: {
                  pathname: row?.to?.pathname,
                  state: { ...row?.to?.state, isOperation: true },
                },
              }))}
              testId="operation"
              expand={false}
              withPage={true}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              pageCurrentIndex={pageCurrentIndex || 0}
              totalPage={pagination?.paginationTotalCount || 0}
            />
          </ScrollTable>
        </CardMain>
      </Paper>
      <Alert
        key="product-create"
        open={openAlert}
        onClose={handleCloseAlert}
        title={stateDownload().get(statusDownload)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        isLoading={statusDownload === 'loading'}
        severity={typeAlert.get(statusDownload)}
        isError={statusDownload === 'error'}
      />
    </>
  )
}
export default Operations
