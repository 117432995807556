import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { auth } from './firebaseConfig'
import { GoogleAuthProvider } from 'firebase/auth'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/messaging'

import Title from '../../atomics/Typography/Title'
import Text from '../../atomics/Typography/Text'
import ThemeModeButton from '../../molecules/ThemeModeButton'

const Right = styled('div')`
  margin-top: 2%;
  margin-right: 2%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.third.dark};
`

const Layout = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 90%;

  @media (max-width: 700px) {
    width: 90%;
    height: 100%;
    margin-top: 15px;
  }
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const PaperContainer = styled(Paper)`
  padding: 3rem;
  max-width: 640px;
  @media (max-width: 700px) {
    max-width: 100%;
    padding: 1rem;
    margin-bottom: 2rem;
  }
`

const Divider = styled('div')`
  height: 1rem;
`

const TitleApp = styled(Title)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 24px;
  font-style: light;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 8%;
`

const TitleLogin = styled(Title)`
  color: ${({ iserror, theme }) =>
    iserror === 'true'
      ? theme.palette.error.main
      : theme.palette.text.primary};
  text-align: left;
  line-height: 24px;
  font-size: 20px;
`

const TextError = styled(Text)`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: bold;
`

const TextInfo = styled(Text)`
  line-height: 24px;
  font-size: 18px;
  a {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.secondary.main};
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.dark};
    }
  }
`

const Center = styled('div')`
  flex: 1;
  justify-content: center;
  align-items: baseline;
  display: flex;
  @media (max-width: 700px) {
    margin-top: 3rem;
  }
`

const ToMail = styled('a')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

const LayoutLogin = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.third.dark};
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const Logo = styled('img')`
  height: 180px;
  width: 180px;
  border: 1px solid #c1c1c1;
  @media (max-width: 700px) {
    height: 80px;
    width: 80px;
  }
  @media (max-width: 500px) {
    height: 40px;
    width: 40px;
  }
`

const TitleLogo = styled(Title)`
  font-size: 24px;
  line-height: 26px;
  font-style: light;
  font-weight: 200;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 10%;

  color: ${({ theme }) => theme.palette.third.main};
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

const SubTitleLogo = styled(TitleLogo)`
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
  color: #b7b7b7;
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

const SubLayoutLogin = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const SubLayoutLoginRight = styled(SubLayoutLogin)`
  background-color: ${({ theme }) => theme.palette.third.light};
`

const TitleContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const idle = {
  title: 'Iniciar sesión',
  subtitle:
    'Para registrarse a la aplicación BackOffice de Kitchen Center y/o habilitar permisos debe contactar:  ',
}

const error = {
  title: 'Error de Acceso',
  subtitle:
    'Su usuario no se encuentra habilitado, contacte con el administrador indicando el problema:  ',
}

const Message = ({ title, subtitle, iserror }) => {
  const ccEmails = [
    'avega@kitchencenter.cl',
    'jhernandez@kitchencenter.cl',
  ]
  const emails = [
    'soportesap@kitchencenter.cl',
    'fbeneventi@kitchencenter.cl',
  ]
  const subject = '[SEGURIDAD] Error acceso BackOffice'

  const toEmailsString = emails.join(',')
  const ccEmailsString = ccEmails.join(',')
  const mailtoLink = `mailto:${toEmailsString}?cc=${ccEmailsString}&subject=${encodeURIComponent(
    subject
  )}`

  return (
    <>
      <TitleLogin iserror={String(iserror)} variant="h2">
        {title}
      </TitleLogin>
      <Divider />
      <Divider />

      <TextInfo>
        {subtitle}
        <ToMail href={mailtoLink}>fbeneventi@kitchencenter.cl</ToMail>
      </TextInfo>
    </>
  )
}

const Login = () => {
  const [ui, setUi] = useState(null)
  const [errorText, setErrorText] = React.useState('')

  useEffect(() => {
    const uiConfig = {
      signInSuccessUrl: '/pedidos',
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          authMethod: 'https://accounts.google.com',
          customParameters: {
            // Limitar a un dominio específico
            hd: 'kitchencenter.cl',
          },
        },
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
      callbacks: {
        signInSuccessWithAuthResult: (_authResult, _redirectUrl) => {
          setErrorText('')

          return true
        },
        signInFailure: _error => {
          setErrorText(
            'Hubo un error durante la autenticación. Por favor, inténtelo de nuevo.'
          )
        },
        uiShown: () => {
          // La UI de inicio de sesión está lista para ser mostrada
        },
      },
    }

    // Reutilizar la instancia existente de AuthUI si ya existe
    if (!ui) {
      const newUi =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(auth)
      newUi.start('#firebaseui-auth-container', uiConfig)
      setUi(newUi)
    } else {
      ui.start('#firebaseui-auth-container', uiConfig)
    }
  }, [ui])

  return (
    <LayoutLogin>
      <SubLayoutLogin>
        <TitleContainer>
          <TitleLogo variant="h1">BackOffice</TitleLogo>
          <Logo
            alt="logo"
            src="https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927"
          />
        </TitleContainer>
        <SubTitleLogo variant="h2">Marketplace</SubTitleLogo>
      </SubLayoutLogin>
      <SubLayoutLoginRight>
        <Layout>
          <Container>
            <Right>
              <ThemeModeButton />
            </Right>

            <PaperContainer elevation={3}>
              <TitleApp variant="h3">Iniciar sesión</TitleApp>
              <Message
                iserror={!isEmpty(errorText)}
                title={!isEmpty(errorText) ? error.title : ''}
                subtitle={
                  !isEmpty(errorText) ? error.subtitle : idle.subtitle
                }
                abstract={
                  !isEmpty(errorText) ? error.abstract : idle.abstract
                }
              />
              <Divider />
              <Divider />
              <Center>
                <div id="firebaseui-auth-container"></div>
              </Center>
              <Divider />
              <Divider />
              <TextError> {errorText} </TextError>
            </PaperContainer>
          </Container>
        </Layout>
      </SubLayoutLoginRight>
    </LayoutLogin>
  )
}

export default Login
