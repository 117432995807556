import React from 'react'

import ListItemText from '@material-ui/core/ListItemText'
import MuiSelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'

import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'

import styled from 'styled-components'
import TextSecondary from '../Typography/TextSecondary'
import Text from '../Typography/Text'

const ContainerSelect = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  .MuiSelect-selectMenu {
    min-height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`

const MenuItemDelete = styled(MenuItem)`
  opacity: 1 !important;
`

export const SelectStyled = styled(MuiSelect)`
  height: 36px;

  border-top-right-radius: ${({ isallfilter }) =>
    isallfilter === 'true' ? '0px' : '5px'};
  border-bottom-right-radius: ${({ isallfilter }) =>
    isallfilter === 'true' ? '0px' : '5px'};
  border-top-left-radius: ${({ isfilterleft }) =>
    isfilterleft === 'true' ? '0px' : '5px'};
  border-bottom-left-radius: ${({ isfilterleft }) =>
    isfilterleft === 'true' ? '0px' : '5px'};

  box-sizing: border-box;

  @media (max-width: 700px) {
    max-width: 100%;
    width: 100%;
  }
  .Mui-disabled {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  }
`

const CheckboxStyled = styled(Checkbox)`
  width: 18px;
  height: 18px;
  padding-right: 20px;
  padding-top: 11px;
`
const TextSelect = styled(Text)`
  font-style: normal;
  font-weight: 500;
`

const Select = ({
  label,
  list,
  value,
  isCheckbox,
  handleDelete,
  handleDeleteAll,
  isallfilter = true,
  isfilterleft = true,
  ...props
}) => {
  return (
    <ContainerSelect variant="outlined">
      <SelectStyled
        {...props}
        isallfilter={String(isallfilter)}
        isfilterleft={String(isfilterleft)}
        labelId="simple-select"
        id="simple-select"
        value={value}
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
        renderValue={() => {
          return <TextSelect>{label}</TextSelect>
        }}
      >
        {list?.map((name, index) => {
          if (name !== 'separator') {
            return (
              <MenuItem
                key={`${name}-${index}-menu`}
                value={name}
                onClick={() => handleDelete(name)}
              >
                {isCheckbox ? (
                  <CheckboxStyled
                    key={`${name}-${index}-checkbox`}
                    checked={value?.includes(name)}
                  />
                ) : null}
                <ListItemText
                  key={`${name}-${index}-item`}
                  primary={name}
                />
              </MenuItem>
            )
          } else {
            return <hr />
          }
        })}
        {isCheckbox ? (
          <MenuItemDelete
            key="borrar"
            onClick={() => handleDeleteAll(value)}
            disabled={value?.length === 0}
          >
            <TextSecondary>Borrar selección</TextSecondary>
          </MenuItemDelete>
        ) : null}
      </SelectStyled>
    </ContainerSelect>
  )
}

export default Select
