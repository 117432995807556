import { createAsyncThunk } from '@reduxjs/toolkit'
import productApiService from '../../../data/services/productApiService'

const fetchProductChangeImagePosition = createAsyncThunk(
  'product/update/change-image-position',
  async ({ productId, imageId, position }) => {
    try {
      await productApiService.updateProductImagePosition({
        productId,
        imageId,
        position,
      })
      return true
    } catch (error) {
      return false
    }
  }
)
export default fetchProductChangeImagePosition
