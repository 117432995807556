import styled from 'styled-components'
import { bool, string, object, func, any } from 'prop-types'
import Select from '@material-ui/core/Select'

const StyledSelect = styled(Select)`
  height: 36px;
  width: 100%;
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #d1d0d0;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const Label = styled('p')`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.20000000298023224px;
  text-align: justify;
  color: #fc8e8e;
  margin-top: 3px;
  padding-top: 6px;
`

const Container = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const ErrorPlaceholder = styled('em')`
  color: #fc8e8e;
`

const InputSelect = ({
  errors,
  name,
  onChange,
  onBlur,
  value = '',
  ...props
}) => {
  const { label } = props

  return (
    <Container>
      <StyledSelect
        variant="outlined"
        value={value}
        error={errors[name] ? true : false}
        displayEmpty
        renderValue={() => {
          if (errors && errors[name]) {
            return <ErrorPlaceholder>{props.placeholder}</ErrorPlaceholder>
          }
          if (!value) {
            return <em>{props.placeholder}</em>
          }

          return value
        }}
        inputProps={{ 'aria-label': `${label}` }}
        {...props}
        onChange={onChange}
        onBlur={onBlur}
      />
      {errors && errors[name] ? (
        <Label>{errors[name].message}</Label>
      ) : null}
    </Container>
  )
}

InputSelect.propTypes = {
  label: string,
  required: bool,
  error: bool,
  placeholder: string,
  errors: object,
  name: string,
  onChange: func,
  onBlur: func,
  value: any,
}

export default InputSelect
