import styled from 'styled-components'
import Tooltip from '../../atomics/Tooltip/Tooltip'
import HelpIcon from '@material-ui/icons/Help'

const ContainerIcon = styled('div')`
  height: fit-content;
  line-height: 15px;
`

const TooltipHelp = ({ text, ...props }) => {
  return (
    <Tooltip title={text}>
      <ContainerIcon {...props}>
        <HelpIcon style={{ color: '#6B6B6B', fontSize: '15px' }} />
      </ContainerIcon>
    </Tooltip>
  )
}

export default TooltipHelp
