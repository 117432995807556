const IconDownloadSvg = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3 19h18v2H3v-2Zm10-5.828L19.071 7.1l1.414 1.415L12 17L3.515 8.515L4.929 7.1L11 13.172V2h2v11.172Z"
    ></path>
  </svg>
)

export default IconDownloadSvg
