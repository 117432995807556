/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Title from '../atomics/Typography/Title'
import Row from '../atomics/Styles/Row'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import fetchCampaigns from '../../../domain/features/campaigns/fetchCampaigns'
import { getCampaigns } from '../../../domain/features/campaigns/getCampaigns'
import { useAuth } from '../pages/auth/useAuth'

const Head = styled(Row)`
  justify-content: space-between;
`

const CampaignsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 8%;
`

const AddCampaingButton = styled(Button)`
  width: 180px;
  height: 35px;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 58px;
  .MuiButton-startIcon {
    font-size: 15px;
  }
  text-decoration: none;
  margin-right: 20%;
`

const StyledLink = styled(Link)`
  margin-bottom: 3%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #2469ce;
`

const Campaigns = () => {
  const { currentUser: user } = useAuth()
  const dispatch = useDispatch()
  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )
  useEffect(() => {
    const promise = dispatch(fetchCampaigns())
    return () => {
      promise.abort()
    }
  }, [dispatch])

  const isViewer = role === 'viewer'
  const { allCampaigns, status } = useSelector(getCampaigns)

  return (
    <>
      <>
        <Head>
          <Box mt="6%" mb="35px" ml="8%">
            <Title>Campañas</Title>
          </Box>
          <AddCampaingButton
            variant="contained"
            onClick={() => console.log('crear campañas')}
            disabled={isViewer}
            color="primary"
            startIcon={<AddIcon />}
          >
            Agregar campaña
          </AddCampaingButton>
        </Head>
        <CampaignsContainer>
          {allCampaigns && allCampaigns.length
            ? allCampaigns.map(campaign => (
                <StyledLink>{`${campaign.nameCampaign} - ${
                  campaign.fromDate.replaceAll('-', '/').split('T')[0]
                } - ${
                  campaign.toDate.replaceAll('-', '/').split('T')[0]
                }`}</StyledLink>
              ))
            : null}
        </CampaignsContainer>
      </>
    </>
  )
}

export default Campaigns
