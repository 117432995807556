import React from 'react'
import { Outlet } from 'react-router-dom'
import Drawer from '../../components/templates/Drawer/Drawer'

import Header from '../../components/templates/Header'
import ScrollToTop from '../../routers/ScrollToTop'

import Margin from './Margin'
import TopPadding from './TopPadding'
import ButtonTop from '../../components/atomics/ButtonTop/ButtonTop'
import useInitDataLoad from '../../hooks/useInitDataLoad'

const Layout = () => {
  useInitDataLoad()
  return (
    <>
      <ScrollToTop />
      <Header />
      <Drawer />
      <Margin>
        <TopPadding />
        <Outlet />
      </Margin>
      <ButtonTop />
    </>
  )
}

export default Layout
