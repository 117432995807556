import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import styled, { css } from 'styled-components'
import errorImg from '../../../assets/error.png'

const ErrorText = styled('div')`
  font-size: 11px;
  margin: 0;
  color: #e72020;
  display: flex;
  align-items: center;
  gap: 8px;
  img {
    width: 13px;
    display: block;
  }
`

const CustomCheckbox = styled(({ error, ...rest }) => (
  <Checkbox {...rest} />
))`
  color: inherit;
  ${props =>
    props.checked &&
    css`
      color: inherit;
    `}
  ${props =>
    props.error &&
    css`
      color: #e72020;
    `}
`

const FieldCheckboxController = ({
  control,
  name,
  defaultValue,
  label,
  showIconError,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        label={label}
        render={({ field: props, fieldState: { error } }) => {
          return (
            <>
              <FormControlLabel
                {...props}
                key={name}
                label={label}
                control={
                  <CustomCheckbox
                    {...{ name, ...props }}
                    checked={props.value}
                    error={error?.message ? true : false}
                    onChange={e => props.onChange(e.target.checked)}
                  />
                }
              />
              {error && (
                <ErrorText>
                  {showIconError && <img src={errorImg} alt="Error" />}
                  {error?.message}
                </ErrorText>
              )}
            </>
          )
        }}
      />
    </>
  )
}

FieldCheckboxController.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
}
FieldCheckboxController.defaultProps = {
  required: false,
}

export default FieldCheckboxController
