import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Back = styled(Button)`
  border: 1px solid #c1c1c1;
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  height: 40px;
  max-width: 20px;
  background-color: #ffffff;
  color: #313234;

  @media (max-width: 1100px) {
    margin-right: 0.5rem;
  }
  @media (max-width: 700px) {
    min-width: 35px;
    height: 35px;
    width: 35px;
  }
  &:hover {
    background-color: #e9f4fd;
  }
`

const ButtonBack = props => {
  const navigate = useNavigate()
  return (
    <Back onClick={() => navigate(-1)} data-testid="ButtonBack" {...props}>
      <ArrowBack />
    </Back>
  )
}

export default ButtonBack
