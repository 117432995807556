import Paper from '@material-ui/core/Paper'
import React from 'react'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components'

import Navigate from '../../atomics/Navigate/Navigate'
import Text from '../../atomics/Typography/Text'
import Title from '../../atomics/Typography/Title'

const PaperContainer = styled(Paper)`
  padding: 1rem;
  margin-top: 10%;
`

const Center = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
Center.displayName = 'Center'

const Divider = styled('div')`
  margin-top: 1rem;
`
Divider.displayName = 'Divider'

const Error404 = () => {
  const { pathname } = useLocation()

  return (
    <PaperContainer data-testid="Container__error404" elevation={3}>
      <Center>
        <Title aria-label="No tiene permisos">Error 404</Title>
        <Divider />
        <Text>
          La ruta actual <b>{pathname}</b> no se existe en la aplicación
        </Text>

        <Divider />
        <Navigate data-testid="Link_error404--back" to="/">
          Volver al Inicio
        </Navigate>
      </Center>
    </PaperContainer>
  )
}

export default Error404
