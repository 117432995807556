import CellBase from './CellBase'
import styled from 'styled-components'

const Cell = styled(CellBase)`
  font-weight: normal;
  color: ${({ theme }) =>
    theme?.palette?.type === 'dark' ? '#F6F6F7' : '#5c5f62'};
`
Cell.displayName = 'Cell'
export default Cell
