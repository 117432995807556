import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

const Input = styled(props => <TextField variant="outlined" {...props} />)`
  height: 36px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 9.5px;
  padding-right: 14px;
  padding-bottom: 9.5px;
  box-sizing: border-box;
  font-weight: 500;
  border-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#b7b7b7'};
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#202223'};

  .MuiOutlinedInput-input {
    padding-top: 9.5px;
    padding-right: 14px;
    padding-bottom: 9.5px;
    border-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#FFFFFF' : '#b7b7b7'};
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 2px) scale(0.75);
  }

  @media (max-width: 1100px) {
    width: auto;
  }
`
Input.displayName = 'Input'
export default Input
