import styled from 'styled-components'

const FilterParent = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 20px;
  padding-left: 0px;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    width: 100%;
  }
`

export default FilterParent
