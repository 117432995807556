import styled from 'styled-components'
import Navigate from '../../atomics/Navigate'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Button } from '@material-ui/core'
import Title from '../../atomics/Typography/Title'

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledHeader = styled(Header)`
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 80%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`
const ContainerMainData = styled('div')`
  margin-left: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1100px) {
    margin-left: 5px;
  }
`
const ButtonBack = styled(Button)`
  border: 1px solid #c1c1c1;
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 1rem;
  min-width: 15px;
  @media (max-width: 1100px) {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
`
const TitleSeller = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 0.2px;
  @media (max-width: 1100px) {
    margin-left: 0rem;
  }
`
const HeaderTitle = ({ path, title }) => {
  return (
    <StyledHeader>
      <ContainerMainData>
        <Navigate to={path}>
          <ButtonBack color="inherit">
            <ArrowBackIcon />
          </ButtonBack>
        </Navigate>
        <div>
          <TitleSeller>{title}</TitleSeller>
        </div>
      </ContainerMainData>
    </StyledHeader>
  )
}

export default HeaderTitle
