import React from 'react'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import { useDarkMode } from '../../../styleSheet/DarkMode'

const IconContainer = styled('div')`
  margin-right: 0.5rem;
`

const MenuItemDark = styled(MenuItem)`
  height: 35px;
  width: 210px;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: #cccccc !important;
  }
`
const ThemeModeButton = ({ children, ...props }) => {
  const [modeTheme, setModeTheme] = useDarkMode()

  return (
    <MenuItemDark
      {...props}
      onClick={() => setModeTheme(modeTheme === 'dark' ? 'light' : 'dark')}
    >
      {' '}
      <IconContainer>
        {modeTheme === 'dark' ? (
          <Brightness2OutlinedIcon fontSize="small" />
        ) : (
          <WbSunnyOutlinedIcon fontSize="small" />
        )}
      </IconContainer>
      {children}
    </MenuItemDark>
  )
}

export default ThemeModeButton
