import { Chip as Pill, withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
const StyledChip = withStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
    boxsizing: 'border-box',
    padding: '5px',
    marginBottom: '10px',
  },
  deleteIcon: {
    position: 'absolute',
    left: '0.8rem',
    display: 'flex',
    height: '16px',
    width: '16px',
    color: '#212121',
  },
  label: {
    'padding-left': '1.5rem',
  },
})(Pill)
const Chip = ({ ...props }) => {
  return <StyledChip deleteIcon={<Close />} {...props} />
}

export default Chip
