import pipe from 'lodash/fp/pipe'
import filter from 'lodash/fp/filter'
import { createSelector } from '@reduxjs/toolkit'
import { payoutAdapter, payoutSlice } from './payoutSlice'

export const { updatePayouts, resetAverages } = payoutSlice.actions

const getStatePayout = state => state.tables.payouts

export const getPayouts = createSelector(
  [getStatePayout],
  payouts => payouts
)

export const getAveragePayouts = createSelector(
  [getStatePayout],
  payouts => payouts?.listOfAverages
)

export const payoutSelectors = payoutAdapter.getSelectors(getStatePayout)
export const totalPayout = payoutSelectors.selectTotal
export const allPayouts = payoutSelectors.selectAll

const filterSellerName =
  (selectList = []) =>
  ({ sellerName }) =>
    selectList?.some(
      seller =>
        String(seller).toLowerCase() === String(sellerName).toLowerCase()
    )
const defaultValues = values => values
export const allPayoutsBySellers = selectList => state => {
  return pipe(
    allPayouts,
    selectList ? filter(filterSellerName(selectList)) : defaultValues
  )(state)
}
export const allPayoutsOnlySellers = state =>
  allPayouts(state).map(({ sellerName }) => sellerName)
