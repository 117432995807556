import persistentStore from '../persistentStore'

const categoryServices = (baseUrl, endpoint) => ({
  getCategories: async () => {
    const response = fetch(`${baseUrl}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })

    return (await response).json()
  },
})

export default categoryServices(
  process.env.REACT_APP_API_PRODUCT_PATH,
  'product-types'
)
