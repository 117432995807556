import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import { Popover, Tooltip } from '@material-ui/core'
import Text from '../../atomics/Typography/Text'
import IconDownloadSvg from '../../atomics/Icon/IconDownloadSvg'

const ButtonDownloaStyled = styled(IconButton)`
  border: solid 1px #d1d0d0;
  border-radius: 5px;
  padding: 10px;
  color: #212121;
  font-size: 20px;
  height: 40px;
  width: 40px;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: #cccccc !important;
  }
`

const ButtonOptionDropdown = styled(Button)`
  padding-left: 10%;
`
const Container = styled('div')`
  margin-left: 10px;
`

const DropdownContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 180px;
  padding-top: 7px;
`

const StyledText = styled(Text)`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: none;
  padding-bottom: 7px;
`
const ButtonDownloadOptions = ({
  options,
  handleClickOptionOne,
  handleClickOptionTwo,
  disabled,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      {options.length > 0 ? (
        <Container>
          <Tooltip
            title={
              !disabled
                ? 'Descargar planilla con filtros aplicados'
                : 'Seleccione un filtro'
            }
          >
            <ButtonDownloaStyled
              aria-describedby={id}
              variant="text"
              disabled={disabled}
              onClick={handleClick}
            >
              <IconDownloadSvg />
            </ButtonDownloaStyled>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DropdownContainer>
              {options.map(option => {
                return (
                  <ButtonOptionDropdown
                    variant="text"
                    size="large"
                    key={option.label}
                    onClick={option.onClick}
                  >
                    <StyledText>{option.label}</StyledText>
                  </ButtonOptionDropdown>
                )
              })}
            </DropdownContainer>
          </Popover>
        </Container>
      ) : (
        <ButtonDownloaStyled variant="text" disabled={disabled} {...props}>
          <IconDownloadSvg />
        </ButtonDownloaStyled>
      )}
    </>
  )
}

ButtonDownloadOptions.propTypes = {
  isViewer: PropTypes.bool,
  options: PropTypes.arrayOf({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
}

export default ButtonDownloadOptions
