import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import dump from '../../../assets/icon/delete.svg'

const IconDelete = styled('img')``

const ButtonDump = props => {
  return (
    <IconButton aria-label="delete-item" {...props}>
      <IconDelete alt="delete" src={dump} />
    </IconButton>
  )
}

export default ButtonDump
