import styled from 'styled-components'
import PropTypes from 'prop-types'
import InputController from '../../molecules/InputController'

import InputAreaController from '../../molecules/InputAreaController'
import VariantCheckboxImage from '../VariantCheckboxImage/VariantCheckboxImage'
import TitleProduct from '../../atomics/Typography/TitleProduct'
import CardProduct from '../../atomics/CardProduct/CardProduct'
import Label from '../../atomics/Label'
import FieldCategory from '../../molecules/FieldCategory/FieldCategory'
import FieldBrand from '../../molecules/FieldBrand/FieldBrand'
import FieldSeller from '../../molecules/FieldSeller'

const SubContainer = styled('div')`
  display: flex;
  width: 100%;
`
const SubContainerWrap = styled(SubContainer)`
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`

const SubContainerColumn = styled(SubContainer)`
  flex-direction: column;
  margin-bottom: 25px;
`
const SubContainerSeller = styled(SubContainerColumn)`
  width: 48%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`
const SubContainerSelectColumn = styled(SubContainerColumn)`
  width: 90%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const MarginBottom = styled('div')`
  margin-bottom: 25px;
`

const Divider = styled('div')`
  width: 10%;
`

const ProductInfoForm = ({
  sellerName,
  control,
  errors,
  mediaList,
  setMediaList,
  setValue,
  clearErrors,
  setError,
  isDetailsView,
  productId,
}) => {
  return (
    <CardProduct>
      <TitleProduct>Información General</TitleProduct>
      <SubContainerSeller>
        <FieldSeller
          required
          label="Seller"
          control={control}
          name="seller"
          placeholder="Selecciona un seller"
          defaultValue={sellerName}
        />
      </SubContainerSeller>
      <SubContainerColumn>
        <Label required>Nombre producto</Label>
        <InputController
          control={control}
          name="productName"
          placeholder="Nombre producto"
          helperText={
            'El nombre del producto no debe llevar la marca y debe ser en mayúscula la primera letra y el resto en minúscula.'
          }
        />
      </SubContainerColumn>
      <SubContainerWrap>
        <SubContainerSelectColumn>
          <FieldCategory control={control} defaultValue="" />
        </SubContainerSelectColumn>
        <Divider />
        <SubContainerSelectColumn>
          <FieldBrand
            control={control}
            defaultValue=""
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </SubContainerSelectColumn>
      </SubContainerWrap>
      <SubContainerColumn>
        <Label required>Descripción</Label>
        <InputAreaController
          control={control}
          name="detailedDescription"
          placeholder="Descripción del producto..."
          helperText='Se debe invitar al cliente a comprar el producto pero con mayor detalle, sin hacer referencia a características específicas. Máximo de 850 caracteres.'
          multiline
          rows={5}
        />
        <MarginBottom />
      </SubContainerColumn>
      <SubContainerColumn>
        <Label>Imágenes del producto </Label>
        <VariantCheckboxImage
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          mediaList={mediaList}
          control={control}
          setMediaList={setMediaList}
          isDetailsView={isDetailsView}
          productId={productId}
        />
      </SubContainerColumn>
    </CardProduct>
  )
}

ProductInfoForm.propTypes = {
  productId: PropTypes.string,
  control: PropTypes.any,
  errors: PropTypes.object,
  categoryList: PropTypes.array,
  brandList: PropTypes.array,
  mediaList: PropTypes.array,
  setMediaList: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  isDetailsView: PropTypes.bool,
}

export default ProductInfoForm
