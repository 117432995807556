const getActiveCampaign = campaigns => {
  const active = campaigns.find(
    campaign =>
      campaign.status === 'active' &&
      new Date(campaign.fromDate) <= new Date() &&
      new Date(campaign.toDate) >= new Date()
  )
  return active
}

const normalizeSeller = ({
  _id,
  brands,
  campaigns,
  commissionCategories,
  commissionSeller,
  email,
  nameSeller,
  phoneNumber,
  rut,
  slugSeller,
  tagSeller,
  updatedAt,
  users,
  giro,
  bussinesName,
  idSellerEnviame,
  apiKey,
}) => ({
  _id,
  brands,
  campaigns,
  commissionCategories,
  commissionSeller,
  email,
  nameSeller,
  phoneNumber: phoneNumber || '-',
  giro: giro || '-',
  bussinesName: bussinesName || '-',
  rut,
  slugSeller,
  tagSeller,
  updatedAt,
  users,
  idSellerEnviame,
  apiKey,
  activeCampaign: getActiveCampaign(campaigns),
})

export default normalizeSeller
