import { createSelector } from '@reduxjs/toolkit'
import { sellerDetailAdapter } from './sellerDetailSlice'

const getStateSeller = state => state.sellers.detail

export const getSellerDetail = createSelector(
  [getStateSeller],
  seller => seller
)

const sellerSelectors = sellerDetailAdapter.getSelectors(getStateSeller)
export const allSellerDetail = sellerSelectors.selectAll
export const currentSellerDetail = slugIn => state =>
  allSellerDetail(state).filter(
    ({ slugSeller }) => String(slugSeller) === String(slugIn)
  )
