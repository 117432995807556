import { createAsyncThunk } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import persistentStore from '../../../data/persistentStore'
import productAdminServices from '../../../data/services/productAdminServices'
import { composeMeasurement } from '../../adpaters/measurementProduct/composeMeasurement'
import { createFilesArray } from '../../adpaters/product/createFiles'
import normalizeProduct from '../../adpaters/product/normalizeProduct'
import { createVariant } from '../../adpaters/variants/createVariant'
import fetchProductDetail from '../productDetail/fetchProductDetail'

const filterMedia = media => mediaIds =>
  media?.filter(({ preview }) =>
    mediaIds?.some(mediaId => mediaId === preview?.image?.url)
  )

const transformMedia = media => mediaIds =>
  filterMedia(media)(mediaIds).map(media => ({
    id: media.id,
    url: media.preview.image.url,
  }))

const fetchProductUpdate = createAsyncThunk(
  'product/update',
  async (
    { data, status, allFiles, passableEdited },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const {
        manual,
        certificate,
        efficiency,
        dataSheet,
        inpage,
        descriptionImage,
        media = null,
        seller,
      } = data
      const form = new FormData()
      const files = createFilesArray(
        manual,
        certificate,
        efficiency,
        dataSheet,
        inpage,
        descriptionImage
      )

      const filesIds = {}
      if (!isEmpty(files)) {
        files?.forEach(file => {
          form.append(file.metafield, file.file)
        })
        const response = await productAdminServices.uploadFiles(form)

        response?.forEach(file => {
          if (file.image) {
            filesIds[file.alt] = file.image.url
          } else {
            filesIds[file.alt] = file.url
          }
        })
      }

      const mediaIds = []
      let formMedia = null
      let mediaResponse = null
      if (!isEmpty(media)) {
        formMedia = new FormData()

        media?.forEach(image => {
          if (image.file) {
            const { name, file } = image
            formMedia.append(name, file)
          }
        })
        mediaResponse = await productAdminServices.uploadFiles(formMedia)

        if (!isEmpty(mediaResponse)) {
          mediaResponse?.forEach(file => {
            if (file.image) {
              mediaIds.push({ name: file.alt, url: file.image.url })
            } else {
              mediaIds.push({ name: file.alt, url: file.url })
            }
          })
        }
      }

      const variantList = data?.variants?.map(variant => {
        return createVariant({
          variantId: variant?.variantId,
          own_sku:
            isNil(variant?.ownSku) || variant?.ownSku === 'undefined'
              ? ''
              : variant?.ownSku,
          sku:
            isNil(variant?.sku) || variant?.sku === 'undefined'
              ? ''
              : variant?.sku,
          price: variant?.price || variant.compareAtPrice,
          compareAtPrice: variant?.compareAtPrice,
          color: variant?.color,
          weight: variant?.weight,
          availableQuantity: variant?.inventoryQuantity,
          imageName: variant?.mediaIds
            ? transformMedia(media)(variant?.mediaIds)
            : null,
          mediaForVariant:
            variant?.mediaIds?.length > 0
              ? filterMedia(media)(variant?.mediaIds)
              : null,
          currentMedia: variant?.mediaIds
            ? transformMedia(media)(variant?.mediaIds)[0]
            : null,
          inventoryLevelId: variant?.inventoryItem?.inventoryLevel?.id,
          currentAvailable:
            variant?.inventoryItem?.inventoryLevel?.available || 0,
          inventoryItemId: variant?.inventoryItem?.id,
          locationId: variant?.locationId,
          availableDelta:
            variant?.inventoryQuantity - variant?.currentAvailable,
        })
      })

      const user = persistentStore.getUser()

      const customStatus = {
        status:
          user?.role === 'admin'
            ? status
            : user?.role === 'operations' && passableEdited
            ? 'DRAFT'
            : undefined,
      }

      const currentFiles = allFiles
        ? Object.keys(allFiles).reduce((acc, key) => {
            if (allFiles[key].value) {
              acc[key] = allFiles[key]?.value
            } else {
              acc[key] = filesIds[key]
            }
            return acc
          }, {})
        : {}
      const mediaIdsState = getState().products.detail.mediaIds || []

      const { productUpdate } = await productAdminServices.updateProduct({
        id: data.id,
        title: data.productName,
        ...customStatus,
        productType: data.category,
        vendor: data.brand,
        tags: data.tags,
        descriptionHtml: data.detailedDescription,
        availableQuantity: data?.availableQuantity,
        warranty: data?.productWarranty,
        materials: data?.productMaterial,
        features: data?.specialFeatures,
        fabricado_en: data?.manufacturedCountry,
        tension: data?.productTension,
        installation: data?.productInstalation,
        accesories: data?.productAccesories,
        considerations: data?.productConsiderations,
        plug: data?.plug,
        knob: data?.commands,
        power: data?.power,
        rating_energy: data?.energyClass,
        cp_useful_total: data?.totalUsableCapacity,
        measurements_enchase: data?.embeddedMeasurements,
        measurements_product: composeMeasurement(
          data.productHeight,
          data.productWidth,
          data.productDepth
        ),
        panel: data?.panel,
        type_power: data?.energyType,
        speed: data?.speedsNumber,
        light: data?.light,
        armed: data?.built,
        consumption_kwh: data?.consume,
        temperature: data?.temperature,
        level_heat_burner: data?.burnersHeatLevels,
        type_surface: data?.countertopType,
        total_burners: data?.burnersNumber,
        type_oven: data?.ovenType,
        total_programs: data?.ovenProgramsNumber,
        max_suction: data?.maxSuction,
        type_campaign: data?.bellType,
        max_noise: data?.maxNoise,
        type_grill: data?.grillType,
        diameter_tube: data?.tubeDiameter,
        technology_cooling: data?.coldTechnology,
        type_freezer: data?.freezerType,
        type_refrigerator: data?.refrigeratorType,
        cp_useful_freezer: data?.refrigeratorUsableCapacity,
        cp_useful_refrigerator: data?.freezerUsableCapacity,
        gas_refrigerant: data?.refrigerantGas,
        total_programs_washing: data?.washingProgramsNumber,
        total_programs_drying: data?.dryProgramsNumber,
        type_load: data?.loadType,
        video: data.video,
        ...filesIds,
        ...currentFiles,
        mediaPosition: data.mediaPosition || [],
        media: mediaIdsState,
        variants: variantList,
        mediaToDelete: data.mediaToDelete,
        intensity: data.intensity,
        security: data.security,
        cable_length: data.cableLength,
        type_tap: data.tapType,
        cp_useful_washing_machine: data.washingMachineUsableCapacity,
        cp_useful_washer_dryer: data.dryerUsableCapacity,
        type_function: data.functionType,
        bottles_quantity: data.bottlesQuantity,
        seller,
      })

      if (isEmpty(productUpdate?.product) || !productUpdate) {
        return rejectWithValue('Error actualizar producto')
      }
      const product = normalizeProduct(productUpdate?.product)
      dispatch(fetchProductDetail({ id: product?.id }))
      return product
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchProductUpdate
