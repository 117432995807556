import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminService from '../../../data/services/productAdminServices'

const fetchDeleteProduct = createAsyncThunk(
  'products/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await productAdminService.deleteProductById({
        id,
      })

      const data = response['productDelete']['deletedProductId']

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchDeleteProduct
