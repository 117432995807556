import React from 'react'
import { bool, node } from 'prop-types'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const SubmitContainer = styled('div')`
  margin-top: 1rem;
  margin-bottom: 1rem;
  .MuiButtonBase-root {
    ${props =>
      props.color
        ? css`
            background: ${props.color};
            color: white;
          `
        : css``};
  }

  .MuiButtonBase-root:disabled {
    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'not-allowed')};
    pointer-events: auto;
    opacity: 0.6;
  }
`

const Spinner = styled(CircularProgress)`
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#202223'};
`

const SubmitButton = ({ isLoading, children, color, ...props }) => {
  return (
    <SubmitContainer isLoading={isLoading} color={color}>
      <Button
        type="submit"
        color={color || 'primary'}
        variant="contained"
        data-testid="SubmitButton__button--submit"
        endIcon={isLoading ? <Spinner size="1.2rem" /> : null}
        size="large"
        {...props}
      >
        <Typography variant="button">{children || 'Enviar'}</Typography>
      </Button>
    </SubmitContainer>
  )
}

SubmitButton.propTypes = {
  isLoading: bool,
  children: node,
}
SubmitButton.defaultProps = {
  isLoading: false,
  children: undefined,
}

export default SubmitButton
