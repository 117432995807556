import { createAsyncThunk } from '@reduxjs/toolkit'
import categoryServices from '../../../data/services/categoryServices'

const fetchCategory = createAsyncThunk(
  'category',
  async (_, { rejectWithValue }) => {
    try {
      const categories = await categoryServices.getCategories()

      return categories
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchCategory
