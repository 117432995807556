import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import { getMeta } from '../../adpaters/graphql/getKey'
import normalizeProducts from '../productSearchApi/normalizeProducts'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'

const getColorAndImage = product => {
  const color = get(
    find(product?.selectedOptions, option => option?.name === 'Color'),
    'value',
    null
  )

  const getFirstImageByColor = (product, color) => {
    const mediaImage = find(product?.media, edge =>
      includes(edge?.alt, `#${color}`)
    )
    return {
      url: get(mediaImage, 'preview.image.url', null),
      alt: get(mediaImage, 'preview.image.altText', null),
    }
  }

  const mediaImage = getFirstImageByColor(product, color)

  return {
    color,
    mediaImage,
  }
}

const fetchProductListBySku = createAsyncThunk(
  'product/by-sku-list',
  async ({ listSku }, { rejectWithValue, signal }) => {
    try {
      const response = await productAdminServices.getProductBySku({
        listSku,
        signal,
      })
      if (!response.ok) {
        return rejectWithValue('Error del servidor')
      }

      const data = await response.json()
      const productsBase = normalizeProducts(data.productVariants)
      if (isEmpty(productsBase)) {
        return rejectWithValue("Sin sku's")
      }

      const products = productsBase.map(({ ...p }) => {
        const { color, mediaImage } = getColorAndImage(p)

        const getMetafields = getMeta(p?.metafields)

        return {
          ...p,
          onlineStorePreviewUrl: p?.product.onlineStorePreviewUrl,
          onlineStoreUrl: p?.product.onlineStoreUrl,
          productId: p?.product?.id,
          image: mediaImage?.url || p?.product?.featuredImage?.url,
          alt: mediaImage?.alt || p?.product?.featuredImage?.altText,
          ownSku: getMetafields('sku_propio')?.value,
          color,
          to: {
            pathname: `/productos/${p?.product?.id?.split('Product/')[1]}`,
            state: {
              ...p?.product,
              productId: p?.product?.id,
              onlineStorePreviewUrl: p?.product?.onlineStorePreviewUrl,
              onlineStoreUrl: p?.product?.onlineStoreUrl,
              color,
            },
            replace: true,
          },
        }
      })

      return { products, listSku }
    } catch (error) {
      return rejectWithValue(JSON.stringify(error))
    }
  }
)
export default fetchProductListBySku
