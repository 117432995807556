import * as yup from 'yup'
import { validate } from 'rut.js'
import { isEmpty } from 'lodash'

export const schema = yup.object({
  nameSeller: yup
    .string()
    .ensure()
    .max(100, 'Nombre empresa debe contener entre 1 y 100 caracteres.')
    .required('Nombre empresa debe contener entre 1 y 100 caracteres.'),
  email: yup
    .string()
    .ensure()
    .email('Ingresa un email válido')
    .required('Email contacto debe contener entre 1 y 100 caracteres.'),
  rut: yup
    .string()
    .test('rut', 'Ingresa un RUT válido', value => {
      return value && validate(value)
    })
    .required('RUT es un campo requerido'),
  bussinesName: yup
    .string()
    .max(
      100,
      'Razón social debe ser alfanumérico y contener entre 1 y 100 caracteres.'
    )
    .matches(
      /^[a-zA-Z0-9\sáéíóúÁÉÍÓÚ]+$/,
      'Razón social debe ser alfanumérico y contener entre 1 y 100 caracteres.'
    )
    .required(
      'Razón social debe ser alfanumérico y contener entre 1 y 100 caracteres.'
    ),
  enviameID: yup
    .string()
    .max(6, 'Ingresa un código numérico de menos de 6 dígitos inclusive.')
    .matches(
      /^\d+$/,
      'Ingresa un código numérico de menos de 6 dígitos inclusive.'
    ),
  // .required('Ingresa un código numérico de menos de 6 dígitos inclusive.')
  giro: yup
    .string()
    .max(
      100,
      'Giro debe ser alfanumérico y contener entre 1 y 100 caracteres.'
    )
    .matches(
      /^[a-zA-Z0-9\sáéíóúÁÉÍÓÚ]+$/,
      'Giro debe ser alfanumérico y contener entre 1 y 100 caracteres.'
    )
    .required(
      'Giro debe ser alfanumérico y contener entre 1 y 100 caracteres.'
    ),
  shippingManagment: yup.string().max(100, 'Debes seleccionar una opción'),
  idSellerEnviame: yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, {
      message: 'Ingresa solo números.',
      excludeEmptyString: true,
    })
    .test(
      'max',
      'Ingresa un código numérico de menos de 6 dígitos inclusive',
      val => val <= 1000000 || isEmpty(val)
    ),
  phoneNumber: yup
    .string()
    .notRequired()
    .matches(/^\d{9}$/, {
      message: 'Colocar un valor numérico de 9 dígitos.',
      excludeEmptyString: true,
    }),
  brands: yup.array().of(
    yup
      .string()
      .test(
        'len',
        'Nombre marca debe contener entre 1 y 100 caracteres',
        val => {
          return val ? val.length <= 100 : true
        }
      )
  ),
  commissionSeller: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Ingresa un número entero entre 1 y 100 inclusive.')
    .test(
      'val',
      'comisión global debe ser un valor entre 1 y 100',
      val => {
        return val || val === 0 ? val <= 100 && val >= 1 : true
      }
    ),
  commissionCategories: yup
    .array()
    .of(
      yup.object().shape({
        nameProductType: yup
          .string()
          .ensure()
          .required('Debes seleccionar una categoría'),
        commissionProductType: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : value))
          .required('Ingresa un número entero entre 1 y 100 inclusive')
          .test(
            'len',
            'comisión marca debe ser un valor entre 1 y 100',
            val => {
              return val || val === 0 ? val <= 100 && val > 0 : true
            }
          ),
      })
    )
    .test(
      'unique-names',
      'No se permiten categorías de productos duplicados.',
      function (value) {
        const typeNames = (value || []).map(type => type?.nameProductType)
        return new Set(typeNames).size === typeNames.length
      }
    ),
})
