import React from 'react'
import { Outlet } from 'react-router-dom'

import ScrollToTop from '../../routers/ScrollToTop'
import CenterView from './CenterView'

const LayoutCenter = () => {
  return (
    <>
      <ScrollToTop />
      <CenterView>
        <Outlet />
      </CenterView>
    </>
  )
}

export default LayoutCenter
