import sellerServices from '../../../../data/services/sellerServices'
import { createAsyncThunk } from '@reduxjs/toolkit'
import pipe from 'lodash/fp/pipe'

const fetchSellersCreate = createAsyncThunk(
  'sellers/create',
  async (data, { rejectWithValue }) => {
    try {
      const sellers = await sellerServices.sellerCreate(
        pipe(withOutSpace, transformCommissions, transformBrands)(data)
      )
      return sellers
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
const transformBrands = data => {
  const newBrands = data.brands
    .filter(brand => brand && brand !== '')
    .map(brand => {
      return { brandName: brand }
    })
  data.brands = newBrands
  return data
}
const transformCommissions = data => {
  data.commissionSeller = data.commissionSeller / 100
  data.commissionCategories = data.commissionCategories.map(
    commission => ({
      ...commission,
      commissionProductType: commission.commissionProductType / 100,
    })
  )
  return data
}
const trimStartAndEnd = string => String(string).trimStart().trimEnd()
const withOutSpace = data => ({
  ...data,
  nameSeller: trimStartAndEnd(data?.nameSeller),
  bussinesName: trimStartAndEnd(data?.bussinesName),
  giro: trimStartAndEnd(data?.giro),
})

export default fetchSellersCreate
