import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import normalizeProduct from '../../adpaters/product/normalizeProduct'

const fetchProductDetail = createAsyncThunk(
  'product/detail',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await productAdminServices.getProductDetailById({
        id,
      })
      if (!response.ok) {
        return rejectWithValue('Error del servidor')
      }

      const data = await response.json()

      return {
        product: normalizeProduct(data?.product),
        data,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchProductDetail
