import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
`

export default Title
