import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const Text = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
`

export default Text
