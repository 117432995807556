import styled from 'styled-components'
import Scroll from '../Scroll'

const ScrollTable = styled(Scroll)`
  margin-top: 20px;
  @media (max-width: 700px) {
    margin-top: 10px;
  }
`

export default ScrollTable
