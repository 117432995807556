import React from 'react'
import styled from 'styled-components'
import FieldInputController from '../../molecules/FieldInputController'

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 700px;
  width: 100%;
  gap: 2rem;
`

const Row = styled('div')`
  display: block;
  width: 50%;
`
const RedMessage = styled('div')`
    padding-top: 6px;
    font-size: 11px;
    line-height: 12.89px;
    margin-left: 0px;
    color: #e72020;
}`

const SellerGeneralInformationForm = ({ control }) => {
  return (
    <Content>
      <Row>
        <div>
          <FieldInputController
            name="idSellerEnviame"
            label="ID de seller en Enviame"
            placeholder=""
            control={control}
            tooltip="Código identificador del seller proporcionado por Envíame"
          />
        </div>
      </Row>
      <Row>
        <div>
          <FieldInputController
            disabled
            name="apiKey"
            control={control}
            label="API Key de Seller Center"
            placeholder=""
            tooltip="API Key de servicios de Seller Center proporcionada por Kitchen Center."
          />
          <RedMessage>
            Por el momento, la API key no es editable desde este
            formulario.
          </RedMessage>
        </div>
      </Row>
      <Row>
        <p></p>
      </Row>
    </Content>
  )
}

export default SellerGeneralInformationForm
