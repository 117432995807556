import { createSelector } from '@reduxjs/toolkit'
import { sellerUpdateAdpater } from './sellerUpdateSlice'

const getStateSellerUpdate = state => state.sellers.update

export const getSellerUpdate = createSelector(
  [getStateSellerUpdate],
  seller => seller
)

const sellerUpdateSelector = sellerUpdateAdpater.getSelectors(
  getStateSellerUpdate
)
export const resultsSellerUpdate = sellerUpdateSelector.selectAll
