import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchSellerCreate from './fetchSellersCreate'

export const sellerCreateAdpater = createEntityAdapter()

export const sellerCreateSlice = createSlice({
  name: 'seller/create',
  initialState: sellerCreateAdpater.getInitialState({
    status: 'idle',
    seller: undefined,
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSellerCreate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchSellerCreate.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchSellerCreate.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.seller = payload
    })
  },
})

const reducer = sellerCreateSlice.reducer
export default reducer
