import isArray from 'lodash/isArray'
import deburr from 'lodash/deburr'
import round from '../../utils/round'

import dateDetailOrder from '../../adpaters/days/dateDetailOrder'
import tableDateFormat from '../../adpaters/days/tableDateFormat'
import capitalizeName from '../../utils/capitalizeName'

import normalizeOrderBilling from '../orderBilling/normalizeOrderBilling'
import normalizeOrderDelivery from '../orderBilling/normalizeOrderDelivery'

const formatDate = date => (date ? tableDateFormat(date) : 'No Aplica')
const formatPayoutDate = (payoutDate, accountingStatement) =>
  payoutDate && accountingStatement !== 'No Liquidado'
    ? tableDateFormat(payoutDate)
    : 'No Aplica'
const calculateOtherDiscount = (otherDiscount, transport, penalty) =>
  otherDiscount === 0
    ? (transport || 0) + (penalty || 0)
    : round(otherDiscount)
const getBrandName = brandDetail =>
  isArray(brandDetail) ? brandDetail[0]?.brand : ''

const getBillingInfo = (
  billing,
  companyName,
  companyGiro,
  companyRut,
  customer
) => ({
  billing: {
    billing_address1: capitalizeName(billing?.billingAddress1),
    billing_address2: capitalizeName(billing?.billingAddress2),
    billing_county: capitalizeName(billing?.billingCounty),
    billing_region: capitalizeName(billing?.billingRegion),
  },
  company_name: capitalizeName(companyName),
  company_giro: capitalizeName(companyGiro),
  company_rut: companyRut,
  customer: {
    customer_email: customer?.customerEmail,
  },
})

const getDeliveryInfo = (destination, customer) => ({
  destination: {
    destination_county: capitalizeName(destination?.destinationCounty),
    destination_region: capitalizeName(destination?.destinationRegion),
    destination_address1: capitalizeName(destination?.destinationAddress1),
    destination_address2: capitalizeName(destination?.destinationAddress2),
  },
  customer: {
    customer_name: capitalizeName(customer?.customerName),
    customer_rut: customer?.customerRut,
  },
})

// Función para formatear detalles del estado
const formatStateDetails = ({
  id,
  type,
  status,
  parentId,
  transport,
  penalty,
  order,
  items,
  createdAt,
  shopifyDate,
  totalSale,
  otherDiscount,
  dispatchedSale,
  payout,
  payoutDate,
  commission,
  accountingStatement,
  brandDetail,
  updatedAt,
  reason,
  documentType,
  companyName,
  billing,
  destination,
  customer,
  companyGiro,
  companyRut,
  delivery,
  ...rest
}) => ({
  ...rest,
  id,
  type,
  status,
  parentId,
  transport,
  penalty,
  order,
  itemList: items,
  createdAt: dateDetailOrder(shopifyDate),
  date: tableDateFormat(createdAt),
  idShopify: order?.name,
  idOrder: id,
  sellerName: order?.seller,
  isDispatched: status,
  totalSale: round(totalSale),
  otherDiscount: calculateOtherDiscount(otherDiscount, transport, penalty),
  dispatchedSale: round(dispatchedSale),
  payout: round(payout),
  payoutDate: dateDetailOrder(payoutDate),
  commission: round(commission),
  accountingStatement,
  brandName: getBrandName(brandDetail),
  afterSaleUpdatedAt: tableDateFormat(updatedAt),
  afterSaleCreatedAt: tableDateFormat(createdAt),
  reason,
  documentType,
  companyName,
  billing:
    normalizeOrderBilling(
      getBillingInfo(
        billing,
        companyName,
        companyGiro,
        companyRut,
        customer
      )
    ) || billing,

  delivery:
    normalizeOrderDelivery(getDeliveryInfo(destination, customer)) ||
    delivery,
  destination,
  customer,
})

// Función principal para normalizar la orden
const normalizeOrder = ({
  commission,
  payout,
  payoutDate,
  seller,
  order,
  totalSale,
  dispatchedSale,
  accountingStatement,
  id,
  shopifyId,
  shopifyDate,
  brandDetail,
  status,
  type,
  otherDiscount,
  parentId,
  transport,
  penalty,
  items,
  reason,
  updatedAt,
  createdAt,
  kpi,
  documentType,
  companyName,
  billing,
  destination,
  customer,
  documentName,
  companyGiro,
  companyRut,
  delivery,
}) => {
  const rows = {
    id,
    order,
    createdAt: shopifyDate,
    afterSaleCreatedAt: formatDate(createdAt),
    afterSaleUpdatedAt: formatDate(updatedAt),
    kpi,
    transport,
    penalty,
    date: createdAt
      ? tableDateFormat(createdAt)
      : tableDateFormat(shopifyDate),
    idShopify: shopifyId || order?.name,
    idOrder: id,
    sellerName: seller,
    isDispatched: status,
    status,
    payoutDate: formatPayoutDate(payoutDate, accountingStatement),
    type,
    otherDiscount: calculateOtherDiscount(
      otherDiscount,
      transport,
      penalty
    ),
    totalSale: round(totalSale),
    dispatchedSale: round(dispatchedSale),
    payout: round(payout),
    commission: round(commission),
    accountingStatement,
    detail: seller,
    brandName: getBrandName(brandDetail),
    parentId,
    itemList: items,
    reason,
    documentType,
    companyName: capitalizeName(companyName),
    billing:
      normalizeOrderBilling(
        getBillingInfo(
          billing,
          companyName,
          companyGiro,
          companyRut,
          customer
        )
      ) || billing,

    delivery:
      normalizeOrderDelivery(getDeliveryInfo(destination, customer)) ||
      delivery,
    destination,
    customer,
    documentName,
  }

  const searchParams = new URLSearchParams({
    id: id,
    type: deburr(type).toLowerCase(),
    status: deburr(status).toLowerCase(),
  }).toString()

  return {
    ...rows,
    to: {
      pathname: `/pedidos/${shopifyId === parentId ? id : parentId}`,
      search: `${shopifyId === parentId ? '' : `${searchParams}`}`,
      state: formatStateDetails({
        title: id,
        brand: `Tipo: ${type}`,
        status,
        featuredImage: { url: items[0]?.image },
        ...rows,
        id,
        type,
        parentId,
        transport,
        penalty,
        order,
        items,
        createdAt,
        shopifyDate,
        totalSale,
        otherDiscount,
        dispatchedSale,
        payout,
        payoutDate,
        commission,
        accountingStatement,
        brandDetail,
        updatedAt,
        reason,
        documentType,
        companyName,
        billing,
        destination,
        customer,
      }),
    },
  }
}

export default normalizeOrder
