import React, { useContext, createContext } from 'react'
import { node } from 'prop-types'

const inistialState = {
  isUpdateWorker: false,
  isSuccessWorker: false,
  reloadPage: () => undefined,
}

const ServiceWorkerContext = createContext(inistialState)

export const ProvideWorker = ({ children, ...rest }) => {
  return (
    <ServiceWorkerContext.Provider {...rest}>
      {children}
    </ServiceWorkerContext.Provider>
  )
}

ProvideWorker.propTypes = { children: node }
ProvideWorker.defaultProps = { children: undefined }

export const useWorker = () => useContext(ServiceWorkerContext)
