import styled from 'styled-components'
import Field from '../Field'
import InputAdornment from '@material-ui/core/InputAdornment'
import { bool, string, object } from 'prop-types'

const ContainerInputQuantity = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 13px;
`
const StyleInputAdornment = styled(InputAdornment)`
  .MuiTypography-root {
    color: ${({ error }) => (!error ? '#7c7c7c' : '#e72020')};
    font-size: 15px;
    line-height: 32px;
    font-weight: 400, normal;
    padding: 0px 0px 0px 4px;
  }
`

const FieldQuantity = ({ error, name, helperText, ...props }) => {
  return (
    <ContainerInputQuantity>
      <Field
        type="number"
        id={name}
        name={name}
        error={error}
        helperText={helperText}
        {...props}
        InputProps={{
          endAdornment: (
            <StyleInputAdornment error={!!error} position="end">
              unidades
            </StyleInputAdornment>
          ),
        }}
      />
    </ContainerInputQuantity>
  )
}

FieldQuantity.propTypes = {
  label: string,
  required: bool,
  placeholder: string,
  helperText: string,
  error: object,
  name: string,
}

export default FieldQuantity
