import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'

export const statusAdpater = createEntityAdapter({
  selectId: status => status,
  sortComparer: (a, b) => String(a).localeCompare(String(b)),
})

const dispatchedSlice = createSlice({
  name: 'dispatched',
  initialState: statusAdpater.getInitialState(),
  reducers: {
    addOneStatus(state, { payload }) {
      if (payload?.includes(undefined)) {
        return
      }
      return statusAdpater.addMany(state, payload)
    },
    addAllStatus: statusAdpater.setAll,
    removeAllStatus: statusAdpater.removeAll,
    removeOneStatus: statusAdpater.removeOne,
  },
})

export const {
  addOneStatus,
  addAllStatus,
  removeAllStatus,
  removeOneStatus,
} = dispatchedSlice.actions

const getIsDispatchedStatus = state => state.ui.dispatched

export const isDispatched = createSelector(
  [getIsDispatchedStatus],
  state => state.isDispatched
)
const statusSelectors = statusAdpater.getSelectors(getIsDispatchedStatus)
export const allStatus = statusSelectors.selectAll
const reducer = dispatchedSlice.reducer
export default reducer
