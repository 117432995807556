import axios from 'axios'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import { createAsyncThunk } from '@reduxjs/toolkit'

import subOrderService from '../../../data/services/subOrderService'
import normalizeOrder from '../orders/normalizeOrder'

const fechOrderDetail = createAsyncThunk(
  'order/detail',
  async ({ parentId }, { signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const data = await subOrderService.orderDetailFetch({
      parentId,
      cancelToken: source.token,
    })

    const orderDetailWithAfterSale = pipe(map(normalizeOrder))(data)

    return orderDetailWithAfterSale
  }
)
export default fechOrderDetail
