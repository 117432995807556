import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import Popper from '@material-ui/core/Popper'
import styled from 'styled-components'
import countries, { getFlag } from './countries'
import Field from '../../atomics/Field'

const TextFieldKC = styled(props => <Field {...props} />)`
  margin-top: 5px;
  .MuiOutlinedInput-root {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    padding-left: 14px;
    width: 90%;
  }
  @media (max-width: 1100px) {
    .MuiOutlinedInput-root {
      width: 100%;
    }
  }
`
const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 'fit-content' }}
      placement="bottom-start"
    />
  )
}
const FieldCountryAutoComplete = ({
  control,
  name,
  defaultValue,
  placeholder,
  loading,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, ref, value, ...fields },
        fieldState: { error },
      }) => (
        <Autocomplete
          PopperComponent={PopperMy}
          {...props}
          options={countries.map(({ label }) => String(label))}
          fullWidth
          autoComplete
          disablePortal
          value={value}
          defaultValue={defaultValue}
          onChange={(_, data) => onChange(data)}
          noOptionsText="Ingresé una opción valida"
          renderInput={params => (
            <TextFieldKC
              loading={String(loading)}
              disabled={String(loading) === 'true'}
              {...{ name, ...params }}
              value={value}
              defaultValue={defaultValue}
              error={error}
              inputRef={ref}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: value && (
                  <InputAdornment position="start">
                    {getFlag(value)}
                  </InputAdornment>
                ),
              }}
              {...fields}
            />
          )}
        />
      )}
    />
  )
}

FieldCountryAutoComplete.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.array,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  loading: PropTypes.any,
}

export default FieldCountryAutoComplete
