import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Field from '../../atomics/Field/Field'
import Label from '../../atomics/Label'
import styled from 'styled-components'
import TooltipHelp from '../TooltipHelp/TooltipHelp'

const FieldContainer = styled('div')`
  display: grid;
  grid-template-columns: 95% auto;
  align-items: top;
  gap: 10px;
  width: 100%;
`

const FieldInputController = ({
  control,
  name,
  defaultValue,
  label,
  required,
  tooltip,
  ...props
}) => {
  return (
    <>
      {label && <Label required={required ? true : false}>{label}</Label>}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur, ref, ...fields },
          fieldState: { error },
        }) => (
          <FieldContainer>
            <Field
              {...{ name, ...props }}
              error={error}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              {...fields}
            />

            {tooltip && (
              <TooltipHelp text={tooltip} style={{ marginTop: '13px' }} />
            )}
          </FieldContainer>
        )}
      />
    </>
  )
}

FieldInputController.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
}
FieldInputController.defaultProps = {
  required: false,
}

export default FieldInputController
