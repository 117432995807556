import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import HeaderTitle from '../molecules/HeaderTitle/HeaderTitle'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import fechSellerDetail from '../../../domain/features/sellerDetail/fetchSellerDetail'
import {
  currentSellerDetail,
  getSellerDetail,
} from '../../../domain/features/sellerDetail/getSellerDetail'
import SkeletonText from '../atomics/SkeletonText'
import SellerBasic from '../organims/SellerProfile/SellerBasic/SellerBasic'
import SellerComission from '../organims/SellerProfile/SellerComission/SellerComission'
import SellerBrands from '../organims/SellerProfile/SellerBrands/SellerBrands'
import SellerUsers from '../organims/SellerProfile/SellerUsers/SellerUsers'
import Alert, { typeAlert } from '../atomics/Alert/Alert'
import { getSellerUpdate } from '../../../domain/features/sellers/sellerUpdate/getSellersUpdate'
import { useNavigate } from 'react-router-dom'
import fetchMultipleBrands from '../../../domain/features/brandsSlugSeller/fetchMultipleBrands'
import { useAuth } from '../pages/auth/useAuth'
import SellerCard from '../organims/SellerProfile/SellerCard/SellerCard'

const Center = styled('div')`
  width: 90%;
  margin: auto;
  padding-top: 70px;

  @media (max-width: 1100px) {
    width: 100%;
    padding-top: 40px;
  }
`
const Container = styled('div')`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 16px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`

const DeliveryCard = styled(SellerCard)``
const stateUpdate = x =>
  new Map([
    ['finished', `Datos actualizados con éxito`],
    ['loading', `Actualizando datos del seller `],
    ['error', `Error al actualizar los datos del seller`],
  ])

const SellerDetails = () => {
  const navigate = useNavigate()
  const [openAlert, setOpenAlert] = useState(false)
  const params = useParams()
  const dispatch = useDispatch()

  const { currentUser: user } = useAuth()
  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )

  useEffect(() => {
    const promise = dispatch(
      fechSellerDetail({ slug: params?.slugSeller })
    )
    return () => {
      promise.abort()
    }
  }, [dispatch, params?.slugSeller])

  const { status } = useSelector(getSellerDetail)
  const { status: statusUpdate } = useSelector(getSellerUpdate)
  const sellerDetail = useSelector(currentSellerDetail(params?.slugSeller))
  const detail = useMemo(
    () => (status === 'finished' ? sellerDetail?.pop() : undefined),
    [sellerDetail, status]
  )

  const onUpdate = (newSlug = undefined) => {
    dispatch(fetchMultipleBrands({ refetch: true }))
    if (newSlug) {
      navigate(`/sellers/${newSlug}`)
    } else {
      dispatch(fechSellerDetail({ slug: params?.slugSeller }))
    }
  }

  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(() => {
    if (statusUpdate && statusUpdate !== 'idle') {
      setOpenAlert(true)
    }
  }, [statusUpdate])

  return (
    <Center>
      <HeaderTitle
        path={'/sellers'}
        title={<SkeletonText text={detail?.nameSeller} />}
      />

      <Container>
        <div>
          <SellerBasic
            seller={detail}
            slugSeller={params.slugSeller}
            onUpdate={onUpdate}
            statusSeller={status}
            role={role}
          />
          <SellerUsers
            statusSeller={status}
            seller={detail}
            slugSeller={params.slugSeller}
            role={role}
          />
          <DeliveryCard
            seller={detail}
            slugSeller={params.slugSeller}
            onUpdate={onUpdate}
            statusSeller={status}
            role={role}
          />
        </div>
        <div>
          <SellerBrands
            seller={detail}
            slugSeller={params.slugSeller}
            statusSeller={status}
            role={role}
            onUpdate={onUpdate}
          />
          <SellerComission
            seller={detail}
            slugSeller={params.slugSeller}
            onUpdate={onUpdate}
            statusSeller={status}
            role={role}
          />
        </div>
      </Container>
      <Alert
        open={openAlert}
        onClose={handleCloseAlert}
        title={stateUpdate('a').get(statusUpdate)}
        isLoading={statusUpdate === 'loading'}
        severity={typeAlert.get(statusUpdate)}
        isError={statusUpdate === 'error'}
      />
    </Center>
  )
}

export default SellerDetails
